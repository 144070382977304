import React from 'react'

let ref = null

export function showNotification(notification) {
  if (ref) {
    ref.setState((prevState) => ({
      ...prevState,
      ...notification,
      open: true,
    }))
  } else {
    console.warn('Notification ref is null')
  }
}

/**
 * Display a notification when `showNotification` is called.
 *
 * @TODO: Update to use store/global state.
 * @TODO: Store notifications in a queue
 */
export class Notification extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false, message: null, type: 'error', length: 5000 }
  }

  componentDidUpdate() {
    if (this.state.message || this.state.children) {
      this.timeout = setTimeout(
        () =>
          this.timeout &&
          this.setState({ open: false, message: null, children: null }),
        this.state.length
      )
    }
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
    if (ref === this) {
      ref = null
    }
  }

  componentDidMount() {
    ref = this
  }

  render() {
    const { open, message, type, children } = this.state

    if (!message && !children) return null

    return (
      <div
        className={`notification-bar ${type} ${
          open ? 'bar-open' : 'bar-closed'
        }`}
      >
        {message && <span className='text'>{message}</span>}
        {children && children()}
        <div className='close-icon' />
      </div>
    )
  }
}
export default Notification
