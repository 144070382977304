/**
 * className formatter
 * 
 * Use when you are adding multiple classnames to an element, especially when
 * any of the classes are conditional.
 * @param {array} classes CSS class names to be assigned to an element.
 * @returns {string} Properly trimmed string of classes with empty values removed
 */
export default function classnames(classes) {
    return []
        .concat(...classes)
        .filter(Boolean)
        .join(" ")
        .trim();
}
