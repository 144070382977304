import { useForm } from 'react-hook-form'
import router from 'next/router'
import { useTranslation } from 'react-i18next'

import { Modal } from '.'
import { useStore } from '@/util/store'
import { showNotification } from '@/components/notification'
import { TextInput } from '@/components/text-input'
import { Button } from '@/components/button'
import { useProjects } from '@/util/hooks'

export function AddProjectModal() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const closeModal = useStore((state) => state.closeModal)
  const { t } = useStore((state) => state.modalState)
  const { createProject } = useProjects()

  const onSubmit = async (data) => {
    const onSuccess = (data) => {
      router.push(`/admin/project/${data.id}`)
      showNotification({
        message: `Success! ${data.name} has been created.`,
        type: 'success',
      })
      closeModal()
    }
    const onError = () => {
      showNotification({
        message: t('error.createProject'),
      })
    }
    createProject({ body: data, onSuccess, onError })
  }

  const onError = (errors) => {
    if (errors) {
      showNotification({
        message: 'Please correct the highlighted fields below.',
        type: 'error',
      })
    }
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className='modal-content'>
          <h1>{t('addProject')}</h1>
          <TextInput
            name='name'
            label={t('label.projectName')}
            placeholder='Choose a descriptive name (e.g., Developer Hire)'
            register={register}
            errors={errors}
            minLength={3}
            required
          />
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  )
}
