import { useQuery } from 'react-query'

import { get } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { useStore } from '@/util/store'

export function useTests() {
  const { token } = useStore((state) => state.user)
  const { data: tests } = useQuery('tests', () =>
    get(apiUrl('tests'), { token })
  )

  return { tests: tests && tests.results }
}
