import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Modal } from '.'
import { showNotification } from '@/components/notification'
import { Button } from '@/components/button'

import { useStore } from '@/util/store'
import { useProjects } from '@/util/hooks'

export function ArchiveBatchModal() {
  const router = useRouter()
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const { projectId, batchId } = router.query
  const { updateBatch } = useProjects()
  const closeModal = useStore((state) => state.closeModal)

  const onSubmit = async () => {
    const onSuccess = (returnedBatch) => {
      router.push(`/admin/project/${projectId}`)
      showNotification({
        message: `${returnedBatch.name} has been successfully archived`,
        type: 'success',
      })
      closeModal()
    }
    const onError = () => {
      showNotification({
        type: 'error',
        message: 'An error occurred while archiving the batch.',
      })
    }
    updateBatch({
      data: { batchId, body: { status: 'archived' } },
      onSuccess,
      onError,
    })
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='modal-content'>
          <h1>Are you sure?</h1>
          <p>Are you sure you want to archive this batch?</p>
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Archive
          </Button>
        </div>
      </form>
    </Modal>
  )
}
