import { useState } from 'react'

import { Logo } from '@/components/logo'
import { LoadingWithMessage } from '@/components/loading-with-message'
import { Copyright } from '@/components/basic/copyright'

import styles from './report-view-only-dashboard.module.scss'

const ResourceListing = ({ title, url }) => (
  <a
    href={url}
    target='_blank'
    rel='noreferrer'
    className={styles['aside-resource-listing']}
  >
    <p>{title}</p>
    <img
      alt='Document click icon'
      src='/images/icons/icon-document-cursor-dark.svg'
    />
  </a>
)

export const ReportViewOnlyAside = ({ resources }) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const toggleAside = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <aside className={`admin-aside ${isExpanded ? '' : 'is-hidden'}`}>
      <header className='aside-header'>
        <Logo size='75' />
        <div onClick={toggleAside} className='toggle' />
      </header>
      <div className='aside-content'>
        <div className='main-actions'>
          <h6 className='aside-heading'>
            <span className='text'>Resources</span>
          </h6>
          <div className={styles['aside-resources']}>
            {!resources ? (
              <LoadingWithMessage
                className='middle'
                containerClassName='mt-1'
                message='Loading resources'
              />
            ) : (
              resources.map((resource) => (
                <ResourceListing
                  key={resource.title}
                  title={resource.short || resource.title}
                  url={resource.url}
                />
              ))
            )}
          </div>
        </div>
      </div>
      <footer>
        <Copyright />
      </footer>
    </aside>
  )
}
