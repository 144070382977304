import { useSession } from '@/util/store'
import { useCallback } from 'react'
import i18next from '@/util/localization/i18n'

export function useLanguage() {
  const setPreferredLanguage = useSession((state) => state.setPreferredLanguage)
  const userLanguage = navigator.language || navigator.userLanguage
  const defaultEnglishVariant =
    userLanguage && ['en-US', 'en-CA'].includes(userLanguage)
      ? userLanguage
      : 'en-CA'

  const setLanguage = useCallback(
    (lng) => {
      if (lng === 'en') {
        lng = defaultEnglishVariant
      }

      i18next.changeLanguage(lng)
      setPreferredLanguage(lng)
    },
    [i18next, setPreferredLanguage]
  )

  return { defaultEnglishVariant, setLanguage }
}
