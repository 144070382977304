import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useProperties } from './useProperties'
import { showNotification } from '@/components/notification'

// invokes a callback (cb) function on the first keydown event
// ignores all subsequent keydown events until the same key is released
export function useKey(key, cb) {
  const { isAutoAdvancing } = useProperties()
  const { t } = useTranslation(['common'])
  const callbackRef = useRef(cb)
  const [isDown, setIsDown] = useState(false)
  const [lastHandledKeypress, setLastHandledKeypress] = useState(null)

  useEffect(() => {
    callbackRef.current = cb
  })

  useEffect(() => {
    if (!key) return
    function keyDownHandler(event) {
      const capsLock = event.getModifierState('CapsLock')
      if (capsLock) {
        showNotification({
          message: t('notifications.disableCaps'),
          type: 'error',
        })
      }
      if (isAutoAdvancing) {
        // debounce to prevent spamming
        const now = Date.now()
        const millisecondsSinceLastKeystroke = lastHandledKeypress
          ? now - lastHandledKeypress
          : Infinity

        if (millisecondsSinceLastKeystroke < 300) return
        setLastHandledKeypress(now)
      }

      if (event.key === key && !isDown) {
        callbackRef.current(event)
        setIsDown(true)
      }
    }

    function keyUpHandler(event) {
      if (event.key === key && isDown) {
        setIsDown(false)
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('keyup', keyUpHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('keyup', keyUpHandler)
    }
  }, [
    key,
    isDown,
    isAutoAdvancing,
    setLastHandledKeypress,
    lastHandledKeypress,
  ])
}
