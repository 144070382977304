import Head from 'next/head'

import { AdminHeader } from '@/components/admin-header'
import { LoadingWithMessage } from '@/components/loading-with-message'

import styles from './report-view-only-dashboard.module.scss'

import { resources } from './resourceFixtures'

const GuideLink = ({ title, url }) => {
  return (
    <a
      href={url}
      className={styles['guide-link']}
      target='_blank'
      rel='noreferrer noopener'
    >
      <h1 className={styles.name}>{title}</h1>
      <div className={styles['right-content']}>
        <h6 className={styles['view-pdf-text']}>View PDF</h6>
        <img
          className={styles.icon}
          alt='Document click icon'
          src='/images/icons/icon-document-cursor-dark.svg'
        />
      </div>
    </a>
  )
}

export const ReportViewOnlyDashboard = () => {
  return (
    <>
      <Head>
        <title>Dashboard</title>
      </Head>
      <AdminHeader>
        <div className='page-head'>
          <div className='title-row'>
            <h2>Dashboard</h2>
          </div>
        </div>
      </AdminHeader>
      <div className={styles['dashboard-content']}>
        <h1 className={styles.greeting}>Hello</h1>
        <h4 className={styles.subtext}>
          Welcome to SigmaAssessments.com. If you can&apos;t find something or
          are in need of assistance, please contact{' '}
          <a href='mailto:support@sigmaassessments.com'>
            <span className='underline'>support@sigmaassessments.com</span>
          </a>
          .
        </h4>
        <p className={styles.instructions}>
          To find candidates and view their results, use the search bar in the
          upper right. For information on how to interpret assessment results,
          use the helpful guides below.
        </p>
        {resources ? (
          resources.map((link) => (
            <GuideLink key={link.title} title={link.title} url={link.url} />
          ))
        ) : (
          <div className='center'>
            <LoadingWithMessage message='Loading guides' />
          </div>
        )}
      </div>
    </>
  )
}
