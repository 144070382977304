import { LayoutAdmin } from '@/components/layout-admin'
import { LayoutBase } from '@/components/layout-base'
import { LayoutRespondent } from '@/components/layout-respondent'
import { LayoutSimpleCard } from '@/components/layout-simple-card'
import { LayoutActiveTest } from '@/components/layout-active-test'
import { LayoutPrint } from '@/components/layout-print'

const LAYOUTS = {
  LayoutBase: LayoutBase,
  LayoutAdmin: LayoutAdmin,
  LayoutRespondent: LayoutRespondent,
  LayoutSimpleCard: LayoutSimpleCard,
  LayoutActiveTest: LayoutActiveTest,
  LayoutPrint: LayoutPrint,
}

export function buildLayout(layouts, Component, pageProps) {
  const Layout = LAYOUTS[layouts[0]]

  if (layouts.length > 0) {
    return (
      <Layout {...pageProps}>
        {buildLayout(layouts.slice(1), Component, pageProps)}
      </Layout>
    )
  }

  return <Component {...pageProps} />
}
