/**
 * Add consistent class naming for subtests to help with styling different question types
 * @param {*} subtestName
 */

export const getSubtestClassName = (subtestName) => {
  const lowercaseSubtestName = subtestName.toLowerCase()

  const classNames = {
    information: 'information',
    'information practice': 'information practice',
    comprehension: 'comprehension',
    'comprehension practice': 'comprehension practice',
    arithmetic: 'arithmetic',
    'arithmetic practice': 'arithmetic practice',
    similarities: 'similarities',
    'similarities practice': 'similarities practice',
    vocabulary: 'vocabulary',
    'vocabulary practice': 'vocabulary practice',
    'digit symbol': 'digit-symbol',
    'digit symbol practice': 'digit-symbol practice',
    'picture completion': 'pic-completion',
    'picture completion practice': 'pic-completion practice',
    spatial: 'spatial',
    'spatial practice': 'spatial practice',
    'picture arrangement': 'pic-arrangement',
    'picture arrangement practice': 'pic-arrangement practice',
    'object assembly': 'object-assembly',
    'object assembly practice': 'object-assembly practice',
  }

  return lowercaseSubtestName.toLowerCase() in classNames
    ? 'subtest-' + classNames[lowercaseSubtestName]
    : ''
}
