import { useForm } from 'react-hook-form'

import { showNotification } from '@/components/notification'
import { Button } from '@/components/button'
import { Modal } from '.'

import { useStore } from '@/util/store'
import { useOrganization } from '@/util/hooks'

export function DeleteTeamMemberModal() {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const { deleteOrgMember } = useOrganization()
  const closeModal = useStore((state) => state.closeModal)
  const { id, name } = useStore((state) => state.modalState)

  const onSubmit = () => {
    const onSuccess = () => {
      showNotification({
        message: `Successfully deleted ${name} from the organization`,
        type: 'success',
      })
      closeModal()
    }
    const onError = (error) => {
      if (error.code === 403) {
        showNotification({
          type: 'error',
          message: 'You do not have permission to delete a team member.',
        })
      } else {
        showNotification({
          type: 'error',
          message: 'An error occurred while deleting a team member.',
        })
      }
    }
    deleteOrgMember({ body: { id }, onSuccess, onError })
  }

  const onError = (errors) => {
    if (errors) {
      console.error('ERROR: ', errors)
    }
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className='modal-content'>
          <h1>Delete Team Member</h1>
          <p>Are you sure you want to delete {name} from the organization?</p>
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Delete
          </Button>
        </div>
      </form>
    </Modal>
  )
}
