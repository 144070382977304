import { Modal } from '..'

import { Button } from '@/components/button'

import { useStore } from '@/util/store'
import { useKey } from '@/util/hooks'
import styles from './view-full-image-modal.module.scss'
import classnames from '@/util/classnames'
import { useTranslation } from 'react-i18next'

export function ViewFullImageModal() {
  const { t } = useTranslation(['common'])
  const closeModal = useStore((s) => s.closeModal)
  const modalState = useStore((s) => s.modalState)

  useKey('Escape', closeModal)
  useKey('Enter', closeModal)

  return (
    <Modal variant='large'>
      <div className={classnames(['modal-content', styles['modal-content']])}>
        <img
          className={styles.image}
          src={modalState.imageSrc}
          alt='Full Size Image'
        />
      </div>
      <div className={styles.actionsWrapper}>
        <div className={classnames(['modal-actions', styles['modal-actions']])}>
          <Button onClick={closeModal} className='btn'>
            {t('close')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
