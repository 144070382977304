import { useRouter } from 'next/router'

import { Logo } from '@/components/logo'
import { Countdown } from '@/components/respondent/countdown'
import { useTranslation } from 'react-i18next'
import { useProperties } from '@/util/hooks/useProperties'
import parse from 'html-react-parser'

import { useSession } from '@/util/store'
import {
  isQuestionPage as questionPageCheck,
  isOverviewPage as overviewPageCheck,
} from '@/util/pathnameCheck'
import { useRespondentAssessment } from '@/util/hooks'

export const CardHeader = ({ hideProgress = false }) => {
  const { t } = useTranslation(['tests', 'common'])
  const router = useRouter()
  const { isTimedTestWide } = useProperties()
  const {
    renderProgressBarWithQuestionCount,
    renderProgressBar,
    isTestIndicatorPage,
  } = useRespondentAssessment()
  const isQuestionPage = questionPageCheck(router.pathname)
  const isOverviewPage = overviewPageCheck(router.pathname)
  const activeSubTest = useSession((s) => s.activeSubTest)
  const questionIdx = useSession((s) => s.questionIdx)
  const isPracticeTest = useSession((state) => state.isPracticeTest)
  const subtestTimeLimit = useSession(
    (state) => state.activeSubTest?.subtest?.timeLimit || 0
  )
  const subtestExists = activeSubTest.hasOwnProperty('subtest')

  return (
    <header className='card-header'>
      {isPracticeTest && (isQuestionPage || isOverviewPage) && !hideProgress && (
        <div className='status-bar is-practice'>
          <p className='hide-small-screen'>
            {parse(t('layout.practiceInformation'))}
          </p>
          <p className='small-screen'>
            {t('layout.practiceNotification')}{' '}
            {isQuestionPage && subtestExists && (
              <strong>
                {parse(
                  t('common:indexOf', {
                    index: questionIdx + 1,
                    of: activeSubTest.subtest.subtestQuestions.length,
                  })
                )}
              </strong>
            )}
          </p>
        </div>
      )}
      {renderProgressBarWithQuestionCount()}
      <div className='container'>
        <Logo size='84' variant='respondent' />
        {renderProgressBar()}
        {isTestIndicatorPage && (subtestTimeLimit > 0 || isTimedTestWide) ? (
          <Countdown />
        ) : null}
      </div>
    </header>
  )
}
