import { useEffect, useState } from 'react'

import {
  useNotifications,
  notificationTypes,
} from '@/util/hooks/useNotifications'
import { useStore } from '@/util/store'

export const useAdminMaintenanceCheck = () => {
  const MAINTENANCE_MODAL = 'AdminMaintenanceModal'
  const activeModal = useStore((state) => state.modal)
  const openModal = useStore((state) => state.openModal)
  const [isViewed, setIsViewed] = useState(false)
  const { isLoading, notification } = useNotifications(
    notificationTypes.ADMIN_MODAL
  )

  useEffect(() => {
    if (isLoading || isViewed) return

    if (notification && activeModal != MAINTENANCE_MODAL) {
      openModal(MAINTENANCE_MODAL, {
        notificationContent: notification,
      })
    }

    if (activeModal == MAINTENANCE_MODAL) {
      setIsViewed(true)
    }
  }, [isLoading, notification, openModal, activeModal, isViewed])
}
