import parse from 'html-react-parser'

import { Modal } from '.'
import { useStore, useSession } from '@/util/store'
import { useRouter } from 'next/router'
import { useProperties } from '@/util/hooks/useProperties'
import { testIdentifiers } from '@/util/consts'
import { useTranslation } from 'react-i18next'

export function PracticeEndModal() {
  const router = useRouter()
  const closeModal = useStore((state) => state.closeModal)
  const modalState = useStore((state) => state.modalState)
  const increaseSubTestIdx = useSession((state) => state.increaseSubTestIdx)
  const resetQuestionIdx = useSession((state) => state.resetQuestionIdx)
  const { testIdentifier } = useProperties()
  const isMAB3 = testIdentifier === testIdentifiers.MAB3
  const { t } = useTranslation(['tests', 'common'])
  const nextSubTest = () => {
    closeModal()
    increaseSubTestIdx()
    resetQuestionIdx()
    const redirect = isMAB3 ? '/tests/active' : '/tests/test/section/overview'
    router.replace(redirect)
  }

  return (
    <Modal variant='medium-large'>
      <div className='modal-header'>
        <h2 className='tc'>{t('modals.practiceEnd.title')}</h2>
      </div>
      <div className='modal-content'>
        <p className='tl'>
          {t('modals.practiceEnd.instructions', {
            section: `${
              modalState?.section ? `the ${modalState.section}` : 'this'
            }`,
          })}
        </p>
        <p className='tl'>{parse(t('modals.practiceEnd.note'))}</p>
      </div>
      <div className='modal-actions'>
        <button
          type='button btn-outline'
          onClick={() => {
            closeModal()
          }}
          className='btn btn-outline'
        >
          {t('modals.practiceEnd.backButton')}
        </button>
        <button type='button' onClick={nextSubTest} className='btn'>
          {t('common:next')}
        </button>
      </div>
    </Modal>
  )
}
