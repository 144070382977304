import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'

import { showNotification } from '@/components/notification'
import { WarningBox } from '@/components/basic/warning-box'
import { Button } from '@/components/button'
import { Icon } from '@/components/icon'
import { Modal } from '@/components/modals'
import { useStore } from '@/util/store'

export const DeleteRespondentModal = () => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()
  const router = useRouter()

  const closeModal = useStore((state) => state.closeModal)
  const { respondentId, deleteRespondentMutation } = useStore(
    (state) => state.modalState
  )

  const onSubmit = () => {
    const onSuccess = () => {
      showNotification({
        message: 'Respondent deleted successfully',
        type: 'success',
      })
      closeModal()
      router.push('/admin')
    }
    const onError = (error) => {
      console.log(error)
      showNotification({
        type: 'error',
        message:
          'There was an error deleting the respondent - please try again',
      })
    }
    return deleteRespondentMutation.mutate(respondentId, {
      onSuccess,
      onError,
    })
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='modal-content'>
          <h1>
            Are you sure you want to delete this respondent&apos;s test session?
          </h1>
          <WarningBox
            className='modal-warning mb-2'
            icon={<Icon name='circle-exclaim' />}
          >
            <strong>This action cannot be reversed</strong>
          </WarningBox>
          <p>
            When you delete this user&apos;s testing session all assessments
            administered within this session will be erased and cannot be
            undone.
          </p>
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Yes, Delete Respondent Session
          </Button>
        </div>
      </form>
    </Modal>
  )
}
