import create from 'zustand'
import produce from 'immer'
import { devtools } from 'zustand/middleware'
import { put } from '@/util/api'

// items in this store expire on hard refresh
export const useStore = create(
  devtools((set, get) => ({
    user: null,
    setUser: (user) =>
      set((state) => ({
        user: produce(state.user, (draft) => user),
      })),
    updateUser: (user) =>
      set((state) => ({
        user: produce(state.user, (draft) => {
          Object.assign(draft, user)
          put('/api/user', user) // Also update user in session
        }),
      })),

    // clear: () => set({}, true),

    stateRefresh: '',
    setStateRefresh: (stateRefresh) => set({ stateRefresh: Date.now() }),

    modal: '',
    modalState: null,
    prevModals: [],
    closeModal: (restorePrevious = false) => {
      if (restorePrevious && get().prevModals.length) {
        const prevModal = get().prevModals.at(-1)
        const remainingModals = get().prevModals.slice(0, -1)
        set((state) => ({ ...prevModal, prevModals: remainingModals }))
      } else {
        set((state) => ({ modalState: null, modal: '', prevModals: [] }))
      }
    },
    openModal: (modal, modalState) => {
      const prevModals = get().prevModals.concat([
        {
          modal: get().modal,
          modalState: get().modalState,
        },
      ])
      set((state) => ({ modal, modalState, prevModals }))
    },

    printState: [],
    setPrintState: (printState) => set({ printState }),

    dropdown: '',
    setDropdown: (dropdown) => set((state) => ({ dropdown })),

    adminAsideVisible: true,
    setAdminAsideVisible: (adminAsideVisible) =>
      set((state) => ({ adminAsideVisible })),

    adminAccordionActive: false,
    setAdminAccordionActive: (adminAccordionActive) =>
      set({ adminAccordionActive }),

    answerTypes: {
      singleSelectLines: 'ssl',
      horizontalImageSingleSelect: 'hss',
      singleSelectSquares: 'sss',
      multiSelectLines: 'msl',
      multiSelectSquares: 'mss',
      digits: 'd',
      digitsFromMemory: 'dfm',
      scale: 's',
    },
    // @@ DEV NOTE: Can reduce number for testing, revert back to 420 once finished testing
    subtestTimeLimit: 420,
    setSubtestTimeLimit: (subtestTimeLimit) => set({ subtestTimeLimit }),

    marketingPages: ['/'],

    isOnline: true,
    setIsOnline: (isOnline) => set({ isOnline }),

    acceptedCookies: null,
    setAcceptedCookies: (acceptedCookies) => set({ acceptedCookies }),

    // Org Flags
    canEditProjects: true,
    setCanEditProjects: (canEditProjects) => set({ canEditProjects }),
    isBillingEnabled: true,
    setIsBillingEnabled: (isBillingEnabled) => set({ isBillingEnabled }),

    newUserAccountInformation: {
      acceptedTerms: false,
      firstName: '',
      lastName: '',
    },
    setNewUserAccountInformation: (newUserAccountInformation) =>
      set({ newUserAccountInformation }),

    // Used as safeguard for subtest skipping bug
    lastCompletedSubTestIdx: null,
    setLastCompletedSubTestIdx: (lastCompletedSubTestIdx) =>
      set({ lastCompletedSubTestIdx }),
  }))
)
