import parse from 'html-react-parser'

import { useTranslation } from 'react-i18next'

export const ProgressBarWithQuestionCount = ({
  testName,
  subtestName,
  totalQuestions,
  question,
}) => {
  const { t } = useTranslation(['tests'])

  return (
    <div className='status-bar'>
      <p>
        {parse(
          t('layout.progressWithCount', {
            testName,
            subtestName,
            questionNumber: question,
            totalQuestions,
          })
        )}
      </p>
    </div>
  )
}
