import { useTranslation } from 'react-i18next'
export const ProgressBar = ({ percentage, minimal = false }) => {
  const { t } = useTranslation(['tests', 'common'])
  return (
    <div className={`progress-bar${minimal ? '__minimal' : '__container '}`}>
      {!minimal && <p className='progress-bar__text'>{t('common:progress')}</p>}
      <div
        className='progress-bar__bar'
        style={{
          backgroundImage: `linear-gradient(90deg, #438fff ${percentage}%, #eef7fe 0)`,
        }}
      />
    </div>
  )
}
