import { Icon } from '@/components/icon'

import classnames from '@/util/classnames'
import styles from './banner.module.scss'

export const Banner = ({ children, iconSrc, variant, onDismiss }) => {
  const CloseIcon = () => {
    if (variant === 'blue') {
      return (
        <img alt='Close icon' src='/images/icons/icon-close-light-navy.svg' />
      )
    }
    return <Icon name='close' />
  }
  return (
    <div className={classnames([styles.banner, styles[variant]])}>
      <div className={classnames([styles['content-wrap']])}>
        <div className={classnames([styles['icon']])}>
          {iconSrc ? (
            <img alt='Banner icon' src={iconSrc} />
          ) : (
            <Icon name='circle-cross' />
          )}
        </div>
        {children}
      </div>
      <button onClick={onDismiss} className={classnames([styles['close-btn']])}>
        <CloseIcon />
      </button>
    </div>
  )
}
