import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import parse from 'html-react-parser'

import { Modal } from '.'
import { Button } from '../button'
import { showNotification } from '@/components/notification'
import { AgreementForm } from '@/components/agreement-form'
import { get, post } from '@/util/api'
import { useStore, useSession } from '@/util/store'
import { apiUrl } from '@/util/urls'
import { useOrganizationAgreements } from '@/util/hooks/useOrganizationAgreements'

export function NewMemberConsentModal() {
  const { t } = useTranslation(['tests', 'common'])
  const closeModal = useStore((state) => state.closeModal)
  const modalState = useStore((state) => state.modalState)
  const {
    userId,
    privacyPolicy,
    termsOfUse,
    isRespondentAgreement,
    isReconsent,
    isNewUser,
    email,
    testCode,
  } = modalState
  const { organizationAgreements } = useOrganizationAgreements({
    testCode,
  })
  const [showAdditionalAgreement, setShowAdditionalAgreement] = useState(false)
  const [additionalAgreementHtml, setAdditionalAgreementHtml] = useState('')
  const [additionalAgreement, setAdditionalAgreement] = useState(null)
  const [privacyHtml, setPrivacyHtml] = useState('')
  const [termsHtml, setTermsHtml] = useState('')
  const [hasReadPrivacy, setHasReadPrivacy] = useState(false)
  const [hasReadTerms, setHasReadTerms] = useState(false)
  const [hasReadAdditionalAgreement, setHasReadAdditionalAgreement] = useState(
    false
  )
  const [flashPrivacy, setFlashPrivacy] = useState(0)
  const [flashTerms, setFlashTerms] = useState(0)
  const [flashAdditionalAgreement, setFlashAdditionalAgreement] = useState(0)
  const updateUser = useStore((state) => state.updateUser)
  const newUserAccountInformation = useStore(
    (state) => state.newUserAccountInformation
  )
  const setNewUserAccountInformation = useStore(
    (state) => state.setNewUserAccountInformation
  )
  const agreeTerms = useSession((state) => state.agreeTerms)
  const setAgreeTerms = useSession((state) => state.setAgreeTerms)
  const agreePrivacy = useSession((state) => state.agreePrivacy)
  const setAgreePrivacy = useSession((state) => state.setAgreePrivacy)
  const agreeAdditionalAgreement = useSession(
    (state) => state.agreeAdditionalAgreement
  )
  const setAgreeAdditionalAgreement = useSession(
    (state) => state.setAgreeAdditionalAgreement
  )
  const router = useRouter()
  const respondentHasAgreed = showAdditionalAgreement
    ? agreeAdditionalAgreement
    : agreeTerms && privacyPolicy
  const getAndSetRespondentPolicy = useCallback(async () => {
    try {
      const { privacyPolicy, termsAndUnderstanding } = await get(
        apiUrl('privacyAndTerms')
      )
      setAgreeTerms(false)
      setAgreePrivacy(false)
      setPrivacyHtml(privacyPolicy.text)
      setTermsHtml(termsAndUnderstanding.text)
      setAgreeAdditionalAgreement(false)
    } catch (error) {
      console.error(error)
    }
  }, [setAgreePrivacy, setAgreeTerms])

  // set the additionalAgreementHtml from the organization agreements
  useEffect(() => {
    if (organizationAgreements?.results.length > 0) {
      const agreement = organizationAgreements.results[0]
      setAdditionalAgreementHtml(agreement?.text)
      setAdditionalAgreement(agreement)
    }
  }, [organizationAgreements])

  useEffect(() => {
    isRespondentAgreement && getAndSetRespondentPolicy()
  }, [getAndSetRespondentPolicy, isRespondentAgreement])

  // If it's admin side reconsent, set the terms from the modal state
  useEffect(() => {
    if (isReconsent || isNewUser) {
      setPrivacyHtml(privacyPolicy)
      setTermsHtml(termsOfUse)
    }
  }, [isReconsent, privacyPolicy, termsOfUse, isNewUser])

  const termsIncludedText =
    privacyPolicy && termsOfUse
      ? t('common:terms')
      : termsOfUse
      ? t('common:termsOfUse')
      : t('common:privacyPolicy')
  const modalTitle = isRespondentAgreement ? (
    'Access Assessment'
  ) : isReconsent ? (
    t('modals.terms.termsUpdatedTitle')
  ) : isNewUser && email ? (
    <>{parse(t('modals.terms.welcomeMessage', { email }))}</>
  ) : (
    t('common:activateAccount')
  )
  const modalSubtitle = isRespondentAgreement
    ? t('modals.terms.consentRespondentAgreementTitle')
    : isReconsent
    ? t('modals.terms.reconsentSubtitle', { termsIncludedText })
    : t('modals.terms.consentGeneralTitle')

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  const onSubmit = async () => {
    try {
      if (isNewUser) {
        setNewUserAccountInformation({
          ...newUserAccountInformation,
          acceptedTerms: true,
        })
      }

      if (!isRespondentAgreement) {
        await post(`${apiUrl('users')}/${userId}/accept-tos-pp`)
        isReconsent && updateUserAgreementFlags()
      }

      if (showAdditionalAgreement && hasAgreed()) {
        closeModal()
        return
      }

      if (!additionalAgreement) {
        closeModal()
      } else {
        setShowAdditionalAgreement(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onError = (errors) => {
    if (errors) {
      showNotification({
        message: t('errors.correctHilighted'),
      })
    }
  }

  const hasAgreed = () => {
    if (showAdditionalAgreement) return agreeAdditionalAgreement
    if (
      (termsOfUse && privacyPolicy) ||
      (isRespondentAgreement && !respondentHasAgreed)
    ) {
      return agreeTerms && agreePrivacy
    }

    if (termsOfUse) return agreeTerms
    if (privacyPolicy) return agreePrivacy
  }

  const onDisagree = () => {
    setAgreePrivacy(false)
    setAgreeTerms(false)
    isRespondentAgreement ? router.replace('/tests') : router.push('/')
    closeModal()
  }

  function updateUserAgreementFlags() {
    const agreementUpdate = {
      ...(privacyPolicy && { agreedToPp: true }),
      ...(termsOfUse && { agreedToTou: true }),
    }

    updateUser(agreementUpdate)
  }

  if (!privacyHtml && !termsHtml) return null

  return (
    <Modal variant='large body-no-close' hideClose={true}>
      <div className='modal-scrollable-area-large'>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className='modal-content'>
            {!isRespondentAgreement && (
              <p className='new-member-consent-title'>{modalTitle}</p>
            )}
            <h3 className='new-member-consent-subtitle'>{modalSubtitle}</h3>
            {showAdditionalAgreement ? (
              <AgreementForm
                isLarge
                title={additionalAgreement?.title}
                html={additionalAgreementHtml}
                userAgrees={agreeAdditionalAgreement}
                setUserAgrees={setAgreeAdditionalAgreement}
                hasRead={hasReadAdditionalAgreement}
                setHasRead={setHasReadAdditionalAgreement}
                flashAgreement={flashAdditionalAgreement}
                setFlashAgreement={setFlashAdditionalAgreement}
                scrollCTA={t('modals.terms.scrollInstructionsWithTitle', {
                  termsTitle: additionalAgreement.title,
                })}
                checkboxLabel={t('modals.terms.acceptedTos', {
                  termsTitle: additionalAgreement.title,
                })}
              />
            ) : (
              <>
                {privacyHtml && (
                  <AgreementForm
                    title={t('common:privacyPolicy')}
                    html={privacyHtml}
                    userAgrees={agreePrivacy}
                    setUserAgrees={setAgreePrivacy}
                    hasRead={hasReadPrivacy}
                    setHasRead={setHasReadPrivacy}
                    flashAgreement={flashPrivacy}
                    setFlashAgreement={setFlashPrivacy}
                    scrollCTA={t('modals.terms.scrollInstructionsPrivacy')}
                    checkboxLabel={t('modals.terms.acceptedPrivacy')}
                  />
                )}
                {termsHtml && (
                  <AgreementForm
                    title={
                      isRespondentAgreement
                        ? t('pages.testTakerAgreement.title')
                        : t('common:termsOfUse')
                    }
                    html={termsHtml}
                    userAgrees={agreeTerms}
                    setUserAgrees={setAgreeTerms}
                    hasRead={hasReadTerms}
                    setHasRead={setHasReadTerms}
                    flashAgreement={flashTerms}
                    setFlashAgreement={setFlashTerms}
                    scrollCTA={
                      isRespondentAgreement
                        ? t('modals.terms.scrollInstructionsTestTakerAgreement')
                        : t('modals.terms.scrollInstructionsTermsAndConditions')
                    }
                    checkboxLabel={
                      isRespondentAgreement
                        ? t('modals.terms.acceptedTestTakerAgreement')
                        : t('modals.terms.acceptedTermsAndConditions')
                    }
                  />
                )}
              </>
            )}
          </div>
          <div className='modal-actions sticky-bottom'>
            <Button
              className='btn'
              disabled={!hasAgreed() || isSubmitting}
              isSubmitting={isSubmitting}
            >
              {t('common:accept')}
            </Button>

            <button
              type='button'
              onClick={() => onDisagree()}
              className='btn btn-outline'
            >
              {`${t('modals.terms.notAgree')} ${
                !isReconsent && !isNewUser
                  ? `- ${t('modals.terms.noAcount')}`
                  : ''
              }`}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
