import classnames from '@/util/classnames'
import styles from './copyright.module.scss'
import { useTranslation } from 'react-i18next'

export const Copyright = () => {
  const { t } = useTranslation('common')

  return (
    <p className={classnames([styles.copyright])}>
      © 2020-{new Date().getFullYear()}, SIGMA Assessment Systems, Inc.{' '}
      {t('allRightsReserved')}.
    </p>
  )
}
