import { showNotification } from '@/components/notification'
import { useSubmitTestData } from '@/util/hooks'
import { useSession, useStore } from '../store'
import { useTranslation } from 'react-i18next'

export function usePostOnReconnect() {
  const { t } = useTranslation(['tests'])
  const [submitTestData, submitError] = useSubmitTestData()
  const activeTest = useSession((state) => state.activeTest)
  const activeSubTest = useSession((state) => state.activeSubTest)
  const failedResponses = useSession((state) => state.failedResponses)
  const removeFailedResponse = useSession((state) => state.removeFailedResponse)
  const respondentToken = useSession((state) => state.respondentToken)
  const getTimeUsed = useSession((state) => state.getTimeUsed)
  const setIsOnline = useStore((state) => state.setIsOnline)

  const retryFailedResponses = async () => {
    const failedResponseArray = Object.keys(failedResponses)
    const failedResponsesSnapshot = { ...failedResponses }
    const shouldPost =
      ['MAB_II'].includes(activeTest.internalTestIdentifier) &&
      failedResponseArray?.length > 0
    if (!shouldPost) return

    try {
      await submitTestData({
        activeSubTest,
        respondentToken,
        timeUsed: getTimeUsed(),
        unpostedResponses: failedResponses,
        onComplete: () => {
          setIsOnline(true)
          showNotification({
            type: 'success',
            message: t('notifications.offlineSaved'),
          })
          failedResponseArray.map((response) => {
            removeFailedResponse(
              failedResponsesSnapshot[response].response.subtest_question_id,
              failedResponsesSnapshot[response].response.timestamp
            )
          })
        },
      })
    } catch (err) {
      showNotification({
        type: 'error',
        message: t('notifications.errorOfflineSaved'),
      })
    }
  }

  return { retryFailedResponses }
}
