import styles from './select-user-type.module.scss'
import classnames from '@/util/classnames'
import { TEAM_ROLES } from '@/util/consts'

export const SelectUserTypeRole = ({
  icon,
  role,
  label,
  caption,
  selectedRole,
  onRoleChange,
}) => {
  const handleChange = () => {
    onRoleChange(role)
  }
  return (
    <div
      onClick={handleChange}
      className={classnames([
        styles['role-select'],
        selectedRole === role && styles['selected'],
      ])}
    >
      <div className={styles.icon}>
        <img alt='Role Icon' src={icon} />
      </div>
      <input
        className={styles.radio}
        type='radio'
        id={role}
        name='role'
        value={role}
      />
      <div className={styles['text-wrapper']}>
        <label className={styles['role-name']} htmlFor={role}>
          {label}
        </label>
        <p className={styles['caption']}>{caption}</p>
      </div>
    </div>
  )
}

export const SelectUserTypeGroup = ({
  selectedRole,
  setSelectedRole,
  translationFn,
  roles,
}) => {
  return (
    <fieldset className={styles['role-group']}>
      {roles.length > 1 && (
        <legend className='label'>Select a user type</legend>
      )}

      {roles.includes(TEAM_ROLES.ORG_ADMIN) && (
        <SelectUserTypeRole
          role={TEAM_ROLES.ORG_ADMIN}
          selectedRole={selectedRole}
          onRoleChange={setSelectedRole}
          label='Organization Admin'
          icon={
            selectedRole === TEAM_ROLES.ORG_ADMIN
              ? '/images/icons/single-user-key-protection-light.svg'
              : '/images/icons/single-user-key-protection-dark.svg'
          }
          caption='Organization Admins have access to all functionality, including inviting respondents, managing the organization, and editing respondent details.'
        />
      )}

      {roles.includes(TEAM_ROLES.READ_ONLY) && (
        <SelectUserTypeRole
          role={TEAM_ROLES.READ_ONLY}
          selectedRole={selectedRole}
          onRoleChange={setSelectedRole}
          label={translationFn('readOnlyUserTitle', { count: 1 })}
          icon={
            selectedRole === TEAM_ROLES.READ_ONLY
              ? '/images/icons/single-user-checkmark-light.svg'
              : '/images/icons/single-user-checkmark-dark.svg'
          }
          caption={translationFn('readOnlyUserDesc')}
        />
      )}
    </fieldset>
  )
}
