import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { showNotification } from '@/components/notification'

import { post } from '@/util/api'
import { postSubtestData } from '@/util/functions'
import { apiUrl } from '@/util/urls'

export function useSubmitTestData() {
  const [postAttempts, setPostAttempts] = useState(0)
  const [error, setError] = useState()
  const { t } = useTranslation(['tests'])

  const submitTestData = async ({
    activeSubTest,
    respondentToken,
    timeUsed,
    unpostedResponses,
    isEndOfTest,
    respondentId,
    assessmentId,
    onComplete,
  }) => {
    try {
      setPostAttempts(postAttempts + 1)

      await postSubtestData(
        activeSubTest,
        respondentToken,
        timeUsed,
        unpostedResponses,
        onComplete
      )

      if (isEndOfTest) {
        await post(
          apiUrl('respondents') +
            `/${respondentId}/assessments/${assessmentId}/complete/`,
          { respondentToken }
        )
      }

      onComplete && onComplete()
    } catch (err) {
      console.log(err)

      if (postAttempts >= 3) {
        showNotification({
          type: 'error',
          message: t('errors.connectionDisrupted'),
        })

        setError(<p className='error'>{t('errors.submitRetryError')}</p>)
      } else {
        showNotification({
          type: 'error',
          message: t('errors.nonSpecificTryAgain'),
        })
      }

      throw err
    }
  }

  return [submitTestData, error]
}
