import { useMemo } from 'react'
import { useSession } from '@/util/store'
import { subtestTypes, testIdentifiers } from '@/util/consts'
import { checkIsSSPO } from '../functions'

/**
 *  Commonly used test/subtest properties accessed via accessors
 */
export const useProperties = () => {
  const getTestProperty = useSession((state) => state.getTestProperty)
  const getSubtestProperty = useSession((state) => state.getSubtestProperty)
  const activeSection = useSession((state) => state.activeSection)
  const subTestIdx = useSession((state) => state.subTestIdx)
  const activeSubTest = useSession((state) => state.activeSubTest)
  const questionIdx = useSession((state) => state.questionIdx)
  const legacyTests = [testIdentifiers.MAB2]

  const testId = getTestProperty((test) => test.id)

  // Test properties
  const isAutoAdvancing = useMemo(
    () => getTestProperty((test) => test.isAutoAdvancing),
    [testId]
  )

  const shouldPauseOnDisconnect = useMemo(
    () => getTestProperty((test) => test.shouldPauseOnDisconnect),
    [testId]
  )

  const isTimedTestWide = useMemo(() => {
    const testTimelimit = getTestProperty((test) => test.testWideTimeLimit)
    return !!testTimelimit && !!testTimelimit > 0
  }, [testId])

  const testWideTimeLimit = useMemo(
    () => getTestProperty((test) => test.testWideTimeLimit),

    [testId]
  )

  const isLastSubtest = useMemo(
    () =>
      getTestProperty((test) => {
        if (!test.id || !activeSection.id) {
          return false
        }

        if (legacyTests.includes(test.internalTestIdentifier)) {
          return subTestIdx === test.subtests.length - 1
        }

        return test.sections[activeSection.order]?.subtests?.length >= 1
          ? subTestIdx ===
              test.sections[activeSection.order].subtests.length - 1
          : null
      }),
    [subTestIdx, testId]
  )
  const isLastSection = getTestProperty((test) => {
    if (legacyTests.includes(test.internalTestIdentifier)) {
      return true
    }

    return activeSection?.order === test?.sections?.length - 1
  })
  const isQuestionRequired = useMemo(
    () => getTestProperty((test) => test.areQuestionsRequired),
    [testId]
  )
  const progressIndicator = useMemo(
    () => getTestProperty((test) => test.progressIndicator),
    [testId]
  )
  const testName = useMemo(() => getTestProperty((test) => test.name), [testId])
  const testIdentifier = useMemo(
    () => getTestProperty((test) => test.internalTestIdentifier),
    [testId]
  )
  const isSSPO = useMemo(() => testIdentifier && checkIsSSPO(testIdentifier), [
    testIdentifier,
  ])
  const canNavigateSubtests = useMemo(
    () => getTestProperty((test) => test.isSubtestNavigationAllowed),
    [testId]
  )

  const totalTestQuestions = useMemo(() => {
    return getTestProperty((test) => {
      if (!test.id || legacyTests.includes(test.internalTestIdentifier)) {
        return
      }

      return test.sections.reduce((total, section) => {
        return (
          total +
          section.subtests.reduce(
            (total, subtest) => total + subtest.subtestQuestions.length,
            0
          )
        )
      }, 0)
    })
  }, [testId])

  // Returns the current question idx for the whole test
  const currentTestQuestionIdx = useMemo(() => {
    return getTestProperty((test) => {
      if (
        !test.id ||
        !activeSection.id ||
        // Not needed for legacy tests
        legacyTests.includes(test.internalTestIdentifier)
      ) {
        return 0
      }

      const currentSectionOrder = activeSection.order
      const currentSubtestIndex = subTestIdx
      const currentQuestionIndex = questionIdx
      let overallQuestionNumber = 0

      for (const section of test.sections) {
        if (section.order < currentSectionOrder) {
          for (const subtest of section.subtests) {
            overallQuestionNumber += subtest.subtestQuestions.length
          }
        } else if (section.order === currentSectionOrder) {
          for (let i = 0; i <= currentSubtestIndex; i++) {
            if (currentSubtestIndex >= section.subtests.length) {
              break
            }
            if (i < currentSubtestIndex) {
              overallQuestionNumber +=
                section.subtests[i].subtestQuestions.length
            } else {
              overallQuestionNumber += currentQuestionIndex
            }
          }
          break
        }
      }

      return overallQuestionNumber
    })
  }, [activeSection.id, subTestIdx, questionIdx])

  // Subtest properties
  const activeSubtestId = useMemo(
    () => getSubtestProperty((subtest) => subtest.subtest?.id || null),
    [subTestIdx]
  )

  const currentSubtest = getSubtestProperty(
    (subtest) => subtest?.subtest || null
  )

  const currentQuestion = getSubtestProperty((subtest) => {
    return subtest.subtest?.subtestQuestions?.[questionIdx]?.question ?? 0
  })

  const firstSubtestQuestion = getSubtestProperty((subtest) => {
    return subtest.subtest?.subtestQuestions?.[0]?.question ?? null
  })
  const subtestQuestionType = firstSubtestQuestion && firstSubtestQuestion.type

  const firstSubtestQuestionImage =
    firstSubtestQuestion &&
    firstSubtestQuestion.questionBodyImages[0]?.imageData.image

  const subtestOverviewImage = useMemo(
    () =>
      getTestProperty((test) => {
        return (
          (isSSPO &&
            subtestQuestionType === 'scenario' &&
            firstSubtestQuestionImage) ||
          null
        )
      }),
    [testId]
  )

  const subtestQuestionId = getSubtestProperty(
    (subtest) => subtest.subtest?.subtestQuestions?.[questionIdx]?.id ?? null
  )

  const subtestTimeLimit = getSubtestProperty(
    (subtest) => subtest?.subtest?.timeLimit || 0
  )

  const totalSubtestQuestions = useMemo(
    () =>
      getSubtestProperty((subtest) => {
        return subtest.subtest?.subtestQuestions?.length || -1
      }),
    [activeSubTest]
  )

  const isPractice = useMemo(
    () => getSubtestProperty((subtest) => subtest.subtest?.isPractice || false),
    [subTestIdx]
  )
  const isSectionPractice = useMemo(
    () =>
      getSubtestProperty(
        (subtest) => subtest.subtest?.isSectionPractice || false
      ),
    [subTestIdx]
  )
  const subtestName = useMemo(
    () => getSubtestProperty((subtest) => subtest.subtest?.name || ''),
    [subTestIdx]
  )
  const isLastQuestion = getSubtestProperty(
    (subtest) => questionIdx === subtest.subtest?.subtestQuestions?.length - 1
  )
  const subtestInstructions = useMemo(
    () =>
      getSubtestProperty(
        (subtest) => subtest.subtest?.subtestInstructions || ''
      ),
    [subTestIdx]
  )

  const subtestDetails = useMemo(
    () => getSubtestProperty((subtest) => subtest.subtest?.details || ''),
    [subTestIdx]
  )
  const previousSubtest = useMemo(
    () =>
      getTestProperty((test) => {
        if (!test.id || test.subtests) {
          // NOTE: the test.subtests predicate is equivalent to checking for a legacy test (eg MAB-II)
          // return early since legacy tests don't use this property
          return
        }
        if (subTestIdx > 0 && test.sections[activeSection.order]) {
          return test.sections[activeSection.order].subtests[subTestIdx - 1]
        }
        if (activeSection.order > 0 && subTestIdx === 0) {
          const previousSection = test.sections[activeSection.order - 1]
          const previousSectionLastSubtestIdx =
            previousSection.subtests.length - 1
          return previousSection.subtests[previousSectionLastSubtestIdx]
        }
      }),
    [subTestIdx]
  )

  const canDeselectAnswers = useMemo(
    () =>
      getTestProperty((test) => {
        return isSSPO || null
      }),
    [testId]
  )

  const hasOneMinuteTimerWarning = useMemo(
    () =>
      getTestProperty((test) => {
        return isSSPO || null
      }),
    [testId]
  )

  const previousSubtestLastQuestionIdx = useMemo(() => {
    return previousSubtest?.subtestQuestions?.length - 1 || 0
  }, [previousSubtest])

  const testsWithPreviousDisabled = [
    subtestTypes.digit_symbol_coding,
    subtestTypes.letter_span_sequence,
    subtestTypes.number_letter_span_sequence,
    subtestTypes.digit_span_forward,
    subtestTypes.digit_span_backward,
    subtestTypes.digit_span_sequence,
    subtestTypes.symbol_search,
  ]
  if (!isPractice) {
    testsWithPreviousDisabled.push(subtestTypes.cancellation)
  }
  const canNavigatePrevious = !testsWithPreviousDisabled.includes(
    currentQuestion.type
  )

  const promptText = useMemo(() => {
    if (isSSPO) {
      // Information type Situation 8 questions do not need prompt
      if (
        currentQuestion.type === 'information' &&
        currentQuestion.title.toLowerCase().includes('situation')
      ) {
        return ''
      }
      return currentQuestion.title
    }
    return subtestInstructions
  }, [currentQuestion, subtestInstructions])

  return {
    currentQuestion,
    totalTestQuestions,
    currentTestQuestionIdx,
    totalSubtestQuestions,
    isPractice,
    subtestName,
    isLastQuestion,
    isAutoAdvancing,
    isLastSubtest,
    isLastSection,
    isQuestionRequired,
    activeSubtestId,
    progressIndicator,
    testName,
    testIdentifier,
    isSSPO,
    isTimedTestWide,
    testWideTimeLimit,
    promptText,
    canNavigatePrevious,
    canNavigateSubtests,
    shouldPauseOnDisconnect,
    subtestQuestionId,
    subtestTimeLimit,
    subtestDetails,
    subtestOverviewImage,
    previousSubtest,
    previousSubtestLastQuestionIdx,
    canDeselectAnswers,
    hasOneMinuteTimerWarning,
  }
}
