import { useState, useRef } from 'react'
import Link from 'next/link'
import Button from 'components/marketing/button'
import classnames from '@/util/classnames'
import { useDocumentClick } from '@/util/use-document-click'
import { sendGTMEvent } from 'util/gtm'
import { useTranslation } from 'react-i18next'

export default function Navbar(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef()
  const { t } = useTranslation(['tests', 'common'])
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  useDocumentClick(menuRef, (isOutsideRef) => {
    isOutsideRef && setMenuOpen(false)
  })

  return (
    <header className='marketing'>
      <nav ref={menuRef} className='navbar container'>
        <Link href='/' passHref>
          <a>
            <img
              className='header-logo'
              src='/images/marketing/sigma-logo.svg'
              alt='Sigma Logo'
            />
          </a>
        </Link>
        <ul className={classnames(['nav-menu', menuOpen ? 'active' : ''])}>
          <li className='nav-item'>
            <Button
              id='btn-assessment'
              className='btn-assessment'
              text={t('common:navigation.takeAssesmentButton')}
              iconSrc='/images/marketing/ic-arrow-filled.svg'
              type='text'
              href='/tests'
              target='_blank'
              onClick={() => sendGTMEvent('nav-testtaker-link')}
            />
          </li>
          <li className='nav-item'>
            <Button
              text={t('common:navigation.adminButton')}
              type='secondary'
              outlined={true}
              href='/login'
              target='_blank'
              onClick={() => sendGTMEvent('nav-admin-login-btn')}
            />
          </li>
          <li className='nav-item'>
            <Button
              text={t('common:navigation.bookDemoButton')}
              type='primary'
              href='/#book-demo'
              onClick={() => sendGTMEvent('nav-demo-btn')}
            />
          </li>
        </ul>

        <img
          className='hamburger'
          alt='hamburger menu'
          src={
            menuOpen
              ? '/images/marketing/close.svg'
              : '/images/marketing/hamburger.svg'
          }
          onClick={toggleMenu}
        />
      </nav>
    </header>
  )
}
