import { useState } from 'react'
import { useRouter } from 'next/router'
import { useStore, useSession } from '@/util/store'
import {
  isQuestionPage as questionPageCheck,
  isOverviewPage as overviewPageCheck,
} from '@/util/pathnameCheck'
import { useProperties } from './useProperties'
import { useTranslation } from 'react-i18next'
import { showNotification } from '@/components/notification'

/**
 *  Used on the new MAB-iii refactor
 */
export const useNavigation = () => {
  const router = useRouter()
  const pathname = router.pathname
  const [isNavigating, setIsNavigating] = useState(false)
  const modal = useStore((state) => state.modal)
  const closeModal = useStore((s) => s.closeModal)
  const openModal = useStore((state) => state.openModal)
  const activeTest = useSession((state) => state.activeTest)
  const activeSection = useSession((state) => state.activeSection)
  const questionIdx = useSession((state) => state.questionIdx)
  const hasResponse = useSession((state) => state.hasResponse)
  const resetSubTestIdx = useSession((state) => state.resetSubTestIdx)
  const increaseQuestionIdx = useSession((state) => state.increaseQuestionIdx)
  const decreaseQuestionIdx = useSession((state) => state.decreaseQuestionIdx)
  const increaseActiveSection = useSession(
    (state) => state.increaseActiveSection
  )
  const decreaseActiveSection = useSession(
    (state) => state.decreaseActiveSection
  )
  const resetQuestionIdx = useSession((state) => state.resetQuestionIdx)
  const increaseSubTestIdx = useSession((state) => state.increaseSubTestIdx)
  const setQuestionIdx = useSession((state) => state.setQuestionIdx)
  const setSubtestIdx = useSession((state) => state.setSubTestIdx)
  const {
    isPractice,
    totalSubtestQuestions,
    isLastQuestion,
    isLastSubtest,
    isLastSection,
    isQuestionRequired,
    canNavigateSubtests,
    canNavigatePrevious,
    previousSubtest,
    previousSubtestLastQuestionIdx,
    subtestQuestionId,
  } = useProperties()
  const { t } = useTranslation(['tests'])

  const canProgress = () => {
    const isAnswerSelected = hasResponse(subtestQuestionId)

    return !isQuestionRequired || isAnswerSelected
  }

  const nextQuestion = async (isNextDisabled = false) => {
    document.activeElement.blur()
    if (modal || isNextDisabled) return

    if (!canProgress()) {
      showNotification({
        type: 'warning',
        message: t('notifications.responseRequired'),
      })
      return
    }

    setIsNavigating(true)
    // End the test
    if (isLastSubtest && isLastQuestion && isLastSection) {
      openModal('TestEndModal', {
        title: t('modals.endOfTest.heading'),
        body: t('modals.endOfTest.instructions'),
        isTestEnd: true,
      })

      // End the section
    } else if (isLastQuestion && canNavigateSubtests) {
      await nextSubTest()
    } else if (!isPractice && isLastQuestion) {
      const editText = canNavigatePrevious
        ? ' To go back through your answers, select <strong>Continue Editing</strong> below.'
        : ''
      openModal('TestEndModal', {
        title: t('modals.endOfSection.heading'),
        body: t('modals.endOfSection.instructions'),
        isTestEnd: false,
      })
    } else if (isPractice && isLastQuestion) {
      openModal('PracticeEndModal')
    } else if (questionIdx + 1 === totalSubtestQuestions) {
      await nextSubTest()
    } else {
      increaseQuestionIdx()
    }

    setIsNavigating(false)
  }

  const prevQuestion = async () => {
    if (modal) return

    setIsNavigating(true)

    if (questionIdx > 0 && canNavigatePrevious) {
      decreaseQuestionIdx()
    } else if (questionIdx === 0 && canNavigateSubtests) {
      router.replace('/tests/active/subtest-overview')
    }
    setIsNavigating(false)
  }

  const nextSubTest = async () => {
    setIsNavigating(true)
    if (isLastSubtest) {
      await resetQuestionIdx()
      await resetSubTestIdx()
      await increaseActiveSection()
      await router.replace(`/tests/active/section-overview`)
    } else {
      await resetQuestionIdx()
      await increaseSubTestIdx()
      await router.replace(`/tests/active/subtest-overview`)
    }
    setIsNavigating(false)
  }

  const prevSubtest = async () => {}

  // Go to the last question of the previous section
  const prevSection = async () => {
    setIsNavigating(true)
    if (activeSection.order > 0) {
      await setQuestionIdx(previousSubtestLastQuestionIdx)
      await setSubtestIdx(previousSubtest.order)
      await decreaseActiveSection()
      await router.replace(`/tests/active/subtest`)
    }
    setIsNavigating(false)
  }

  const toSubtestOverview = () => {
    if (modal) return

    router.push('/tests/active/subtest-overview')
  }

  router.beforePopState((...props) => {
    const isOverviewOrQuestionPage =
      questionPageCheck(pathname) || overviewPageCheck(pathname)
    if (isOverviewOrQuestionPage) {
      const confirmation = window.confirm(t('warnings.backButtonWarning'))
      if (confirmation) {
        closeModal()
        router.push('/tests/reentry-code')
      } else {
        router.replace(pathname)
      }
    }
  })

  return {
    nextQuestion,
    prevQuestion,
    nextSubTest,
    prevSubtest,
    canProgress,
    prevSection,
    toSubtestOverview,
    isNavigating,
  }
}
