import { useRouter } from 'next/router'
import { CardHeader } from './card-header'
import { Copyright } from '@/components/basic/copyright'

export const LayoutSimpleCard = ({ children }) => {
  const router = useRouter()
  return (
    <>
      <div className='card-wrapper'>
        <div className='card'>
          <CardHeader
            hideProgress={router.asPath === '/tests/active/section-overview'}
          />
          <div className='content-wrap'>
            <div className='content'>{children}</div>
          </div>
          <Copyright />
        </div>
      </div>
    </>
  )
}
