import produce from 'immer'

export const sortObjectByStringProperty = ({
  order = 'ascending',
  items,
  propertyName,
}) => {
  const sortedObjects = produce(items, (draftItems) => {
    draftItems.sort((a, b) => {
      var propertyA = a[propertyName].toUpperCase()
      var propertyB = b[propertyName].toUpperCase()
      if (propertyA < propertyB) {
        return order === 'ascending' ? -1 : 1
      }
      if (propertyA > propertyB) {
        return order === 'ascending' ? 1 : -1
      }
      return 0
    })
  })

  return sortedObjects
}
