import { Modal } from '.'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/button'

import { useStore, useSession } from '@/util/store'
import { useKey, useSubmitTestData, useTestComplete } from '@/util/hooks'

import { SubmitTestModalError } from './submit-test-modal-error'

export function EndOfTestModal() {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [submitTestData, submitError] = useSubmitTestData()
  const { t } = useTranslation(['tests', 'common'])
  const closeModal = useStore((s) => s.closeModal)
  const isOnline = useStore((state) => state.isOnline)
  const { onTestComplete } = useTestComplete()

  const activeSubTest = useSession((s) => s.activeSubTest)
  const activeTest = useSession((s) => s.activeTest)
  const activeRespondent = useSession((s) => s.activeRespondent)
  const respondentToken = useSession((s) => s.respondentToken)
  const getTimeUsed = useSession((s) => s.getTimeUsed)
  const failedResponses = useSession((state) => state.failedResponses)

  useKey('Escape', closeModal)
  useKey('Enter', closeModal)

  async function finish() {
    document.activeElement.blur()
    if (isSubmitting) return
    setIsSubmitting(true)

    try {
      await submitTestData({
        activeSubTest,
        respondentToken,
        timeUsed: getTimeUsed,
        unpostedResponses: failedResponses,
        isEndOfTest: true,
        respondentId: activeRespondent.respondentId,
        assessmentId: activeTest.assessmentId,
        onComplete: () => {
          onTestComplete()
          closeModal()
        },
      })
    } catch (err) {
      setTimeout(() => setIsSubmitting(false), 2000)
    }
  }

  return (
    <Modal variant='medium'>
      <div className='modal-header'>
        <h2 className='tc'>{t('modals.endOfTest.heading')}</h2>
      </div>
      <div className='modal-content'>
        <p className='tl'>{t('modals.endOfTest.instructions')}</p>
        <SubmitTestModalError
          submitError={submitError}
          submitButtonLabel={t('common:submit').toUpperCase()}
        />
      </div>
      <div className='modal-actions'>
        <button onClick={closeModal} className='btn btn-outline'>
          {t('common:continueEditing')}
        </button>
        <Button
          onClick={finish}
          isSubmitting={isSubmitting}
          disabled={!isOnline || isSubmitting}
          className='btn'
        >
          {t('common:submit')}
        </Button>
      </div>
    </Modal>
  )
}
