import { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import produce from 'immer'

import { showNotification } from '@/components/notification'

import { get, patch, post, del } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { useStore } from '@/util/store'
import { useUser } from '@/util/hooks'

export function useProjects() {
  const { token } = useUser()
  const setUser = useStore((state) => state.setUser)
  const router = useRouter()
  const queryClient = useQueryClient()

  /**
   * DEV NOTE: Large limits have been added to ensure all results are returned in a single request.
   * Rework once pagination/sorting/search is moved off the front end
   */
  const { data: projects, isRefetching: isProjectsRefetching } = useQuery(
    'projects',
    () =>
      get(apiUrl('projects'), {
        token,
        params: { is_archived: false, limit: 9999 },
      })
  )
  const { data: archivedProjects } = useQuery('archivedProjects', () =>
    get(apiUrl('projects'), {
      token,
      params: { is_archived: true, limit: 9999 },
    })
  )
  const { data: archivedBatches } = useQuery('archivedBatches', () =>
    get(apiUrl('projects'), {
      token,
      params: { batches__status: 'archived', get_archived: true, limit: 9999 },
    })
  )

  const handleExpiredSession = useCallback(async () => {
    await fetch('/api/logout')
    setUser({})
    router.push('/login')
    showNotification({
      type: 'warning',
      message: 'Your session expired. Please login again.',
    })
  }, [setUser, router])

  useEffect(() => {
    if (typeof token === 'undefined') {
      handleExpiredSession()
      return
    }
  }, [handleExpiredSession, token])

  function setProjects(projects) {
    queryClient.setQueryData('projects', projects)
  }

  function updateProjectById(newProject) {
    const updatedProjects = produce(projects, (draft) => {
      let projIdx = draft.results.findIndex((proj) => proj.id === newProject.id)

      if (newProject.isArchived) {
        draft.results.splice(projIdx, 1)
      }
      if (!newProject.isArchived) {
        draft.results[projIdx] = newProject
      }
    })

    setProjects(updatedProjects)
  }

  function addProject(newProject) {
    const updatedProjects = produce(projects, (draft) => {
      draft.results.push(newProject)
    })
    setProjects(updatedProjects)
  }

  function addBatch(newBatch) {
    const updatedProjects = produce(projects, (draft) => {
      let projIdx = draft.results.findIndex(
        (proj) => proj.id === newBatch.project
      )
      draft.results[projIdx].batches.push(newBatch)
    })
    setProjects(updatedProjects)
  }

  function updateBatchById(newBatch) {
    const projIdx = projects.results.findIndex(
      (proj) => proj.id === newBatch.project
    )

    if (projIdx === -1) {
      restoreBatchInArchivedProject(newBatch)
      return
    }

    const batchIdx = projects.results[projIdx].batches.findIndex(
      (batch) => batch.id === newBatch.id
    )
    const updatedProjects = produce(projects, (draft) => {
      if (batchIdx === -1) {
        draft.results[projIdx].batches.push(newBatch)
      } else if (newBatch.status === 'archived') {
        draft.results[projIdx].batches.splice(batchIdx, 1)
      } else {
        draft.results[projIdx].batches[batchIdx] = newBatch
      }
    })
    setProjects(updatedProjects)
  }

  function restoreBatchInArchivedProject(newBatch) {
    const projIdx = archivedProjects.results.findIndex(
      (proj) => proj.id === newBatch.project
    )
    const updatedProjects = produce(archivedProjects, (draft) => {
      draft.results[projIdx].batches.push(newBatch)
    })
    setArchivedProjects(updatedProjects)
  }

  function addOrRemoveBatchTest({ batch, batchTest, testId }) {
    const updatedProjects = produce(projects, (draft) => {
      let projIdx = draft.results.findIndex((proj) => proj.id === batch.project)
      let batchIdx = draft.results[projIdx].batches.findIndex(
        (ob) => ob.id === batch.id
      )
      let testIdx = draft.results[projIdx].batches[
        batchIdx
      ].batchTests.findIndex((test) => test.id === testId)
      if (testIdx === -1) {
        draft.results[projIdx].batches[batchIdx].batchTests.push(batchTest)
      } else {
        draft.results[projIdx].batches[batchIdx].batchTests.splice(testIdx, 1)
      }
    })
    setProjects(updatedProjects)
  }

  const createProjectMutation = useMutation(
    (body) => {
      return post(apiUrl('projects'), body, { token })
    },
    {
      onSuccess: (data) => {
        addProject(data)
      },
    }
  )
  const createProject = ({ body, onSuccess, onError }) =>
    createProjectMutation.mutate(body, { onSuccess, onError })

  const updateProjectMutation = useMutation(
    ({ projectId, body }) => {
      return patch(apiUrl('projects') + `/${projectId}`, body, { token })
    },
    {
      onSuccess: (data) => {
        updateProjectById(data)
      },
    }
  )
  const updateProject = ({ projectId, body, onSuccess, onError }) =>
    updateProjectMutation.mutate({ projectId, body }, { onSuccess, onError })

  const createBatchMutation = useMutation(
    (body) => {
      return post(apiUrl('batches'), body, { token })
    },
    {
      onSuccess: (data) => {
        addBatch(data)
      },
    }
  )
  const createBatch = ({ body, onSuccess, onError }) =>
    createBatchMutation.mutate(body, { onSuccess, onError })

  const updateBatchMutation = useMutation(
    ({ batchId, body }) =>
      patch(apiUrl('batches') + `/${batchId}`, body, {
        token,
      }),
    {
      onSuccess: async (data) => {
        await queryClient.cancelQueries('projects')
        updateBatchById(data)
        if (data.status === 'archived') {
          queryClient.invalidateQueries('archivedBatches')
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('projects')
      },
    }
  )
  const updateBatch = ({ data, onSuccess, onError }) =>
    updateBatchMutation.mutate(data, { onSuccess, onError })

  const restoreArchivedBatchMutation = useMutation(
    ({ batchId }) =>
      patch(
        apiUrl('batches') + `/${batchId}`,
        { status: 'draft' },
        {
          token,
        }
      ),
    {
      onSuccess: (data) => {
        updateBatchById(data)
        queryClient.invalidateQueries('archivedBatches')
      },
    }
  )
  const restoreArchivedBatch = ({ data, onSuccess, onError }) =>
    restoreArchivedBatchMutation.mutate(data, { onSuccess, onError })

  const addBatchTestMutation = useMutation(
    ({ body }) => post(apiUrl('batchTests'), body, { token }),
    {
      onSuccess: (data, variables) => {
        addOrRemoveBatchTest({
          batch: variables.batch,
          batchTest: data,
          testId: data.id,
        })
      },
    }
  )
  const addBatchTest = ({ data, onError, onSuccess }) =>
    addBatchTestMutation.mutate(data, { onError, onSuccess })

  const removeBatchTestMutation = useMutation(
    ({ testId }) => del(apiUrl('batchTests') + '/' + testId, { token }),
    {
      onSuccess: (data, variables) => {
        addOrRemoveBatchTest({
          batch: variables.batch,
          testId: variables.testId,
        })
      },
    }
  )
  const removeBatchTest = ({ data, onSuccess, onError }) =>
    removeBatchTestMutation.mutate(data, { onSuccess, onError })

  /* *** Archived project operations *** */
  const unarchiveProjectMutation = useMutation(
    (projectId) => {
      return patch(
        apiUrl('projects') + '/' + projectId,
        { isArchived: false },
        { token }
      )
    },
    {
      onSuccess: async (data) => {
        updateArchivedProjectById(data)
        addProject(data)
      },
    }
  )

  function unarchiveProject({ projectId, onSuccess, onError }) {
    unarchiveProjectMutation.mutate(projectId, {
      onError,
      onSuccess,
    })
  }

  function setArchivedProjects(newProjects) {
    queryClient.setQueryData('archivedProjects', newProjects)
  }

  function updateArchivedProjectById(newProject) {
    const updatedProjects = produce(archivedProjects, (draft) => {
      let projIdx = draft.results.findIndex((proj) => proj.id === newProject.id)
      if (!newProject.isArchived) {
        draft.results.splice(projIdx, 1)
      }
      if (newProject.isArchived) {
        draft.results[projIdx] = newProject
      }
    })
    setArchivedProjects(updatedProjects)
  }

  function addArchivedProject(newProject) {
    const updatedProjects = produce(archivedProjects, (draft) => {
      draft.results.push(newProject)
    })
    setArchivedProjects(updatedProjects)
  }
  const archiveProjectMutation = useMutation(
    (projectId) => {
      return patch(
        apiUrl('projects') + '/' + projectId,
        { isArchived: true },
        { token }
      )
    },
    {
      onSuccess: (data) => {
        updateProjectById(data)
        addArchivedProject(data)
      },
    }
  )

  function archiveProject({ projectId, onSuccess, onError }) {
    archiveProjectMutation.mutate(projectId, {
      onError,
      onSuccess,
    })
  }

  return {
    projects: projects && projects.results,
    isProjectsRefetching,
    updateProjectById,
    setProjects,
    addProject,
    updateProject,
    createProject,
    createBatch,
    updateBatch,
    restoreArchivedBatch,
    addBatchTest,
    removeBatchTest,
    archiveProject,
    archivedProjects: archivedProjects && archivedProjects.results,
    updateArchivedProjectById,
    setArchivedProjects,
    unarchiveProject,
    archivedBatches: archivedBatches && archivedBatches.results,
  }
}
