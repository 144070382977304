import { useTranslation } from 'react-i18next'

const makeFriendlyLabel = (text) => {
  let result = text.replace(/([A-Z])/g, ' $1')
  let capitalized = result.charAt(0).toUpperCase() + result.slice(1)
  let stripped = capitalized.replace('-', ' ').replace('_', ' ')
  return stripped
}

export const FieldError = ({
  name,
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  errors,
}) => {
  const { t, i18n } = useTranslation(['common'])
  if (!errors || !errors[name]) return null
  const errorType = errors[name].type
  const fieldName = t(`common:errors.fields.${name}`, {
    defaultValue: makeFriendlyLabel(name),
  })
  const errorTranslationKey = `common:errors.${errorType}`

  const errorMessage = (name) => {
    switch (errorType) {
      case 'required':
        return t(errorTranslationKey, {
          field: fieldName,
        })
      case 'minLength':
        return t(errorTranslationKey, {
          minLength,
        })
      case 'maxLength':
        return t(errorTranslationKey, {
          maxLength,
        })
      case 'validate':
        return `${errors[name].message}`
      case 'custom':
        return `${errors[name].message}`
      default:
        if (i18n.exists(errorTranslationKey)) {
          return t(errorTranslationKey)
        } else {
          return t('common:errors.invalid', {
            field: fieldName,
          })
        }
    }
  }

  return errors && Object.keys(errors).length && name in errors ? (
    <span className='field-hint is-error'>{errorMessage(name)}</span>
  ) : null
}
