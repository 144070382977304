import { apiUrl } from '@/util/urls'
import { post } from '@/util/api'

export async function postSubtestData(
  activeSubTest,
  respondentToken,
  timeUsed,
  unpostedResponses
) {
  const responses = Object.values(unpostedResponses).map(
    ({ response }) => response
  )

  try {
    if (responses.length > 0) {
      await post(
        `${apiUrl('subTest')}${activeSubTest.subtest.id}/response-group`,
        { responses, duration: timeUsed },
        { respondentToken }
      )
    }
  } catch (error) {
    console.error(error)
    throw error
  }

  return true
}
