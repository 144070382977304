import parse from 'html-react-parser'

import { Modal } from '.'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/button'

import { useStore, useSession } from '@/util/store'
import { useSubmitTestData, useTestComplete } from '@/util/hooks'

import { SubmitTestModalError } from './submit-test-modal-error'

export function EndOfTestTimeLimitModal() {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [submitTestData, submitError] = useSubmitTestData()
  const { t } = useTranslation(['tests', 'common'])
  const closeModal = useStore((s) => s.closeModal)
  const isOnline = useStore((state) => state.isOnline)
  const subtestTimeLimit = useStore((s) => s.subtestTimeLimit)
  const { onTestComplete } = useTestComplete()

  const activeTest = useSession((state) => state.activeTest)
  const activeSubTest = useSession((s) => s.activeSubTest)
  const activeRespondent = useSession((s) => s.activeRespondent)
  const respondentToken = useSession((s) => s.respondentToken)
  const failedResponses = useSession((state) => state.failedResponses)

  async function submitTest() {
    document.activeElement.blur()
    if (isSubmitting) return
    setIsSubmitting(true)

    try {
      await submitTestData({
        activeSubTest,
        respondentToken,
        timeUsed: subtestTimeLimit,
        unpostedResponses: failedResponses,
        isEndOfTest: true,
        respondentId: activeRespondent.respondentId,
        assessmentId: activeTest.assessmentId,
        onComplete: () => {
          onTestComplete()
          closeModal()
        },
      })
    } catch (err) {
      setTimeout(() => setIsSubmitting(false), 2000)
    }
  }

  return (
    <Modal variant='medium body-no-close' hideClose>
      <div className='modal-header'>
        <h2 className='tc'>{parse(t('modals.endOfTestTimeLimit.heading'))}</h2>
      </div>
      <div className='modal-content'>
        <p className='tl'>
          {parse(t('modals.endOfTestTimeLimit.instructions'))}
        </p>
        <SubmitTestModalError
          submitError={submitError}
          submitButtonLabel={t('common:submit').toUpperCase()}
        />
      </div>
      <div className='modal-actions'>
        <Button
          type='button'
          onClick={submitTest}
          isSubmitting={isSubmitting}
          disabled={!isOnline || isSubmitting}
          className='btn'
        >
          {t('common:submit')}
        </Button>
      </div>
    </Modal>
  )
}
