import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { showNotification } from '@/components/notification'
import ErrorBoundary from '@/components/error-boundary'
import { useUser } from '@/util/hooks'
import { useConnectivityCheck } from '@/util/hooks/useConnectivityCheck'
import { buildLayout } from '@/util/layout'
import { InitializeGTM } from 'util/gtm'
import { useStore, useSession } from '@/util/store'
import Index from './login'
import AdminDashboard from './admin'
import { usePostOnReconnect } from '@/util/hooks/usePostOnReconnect'
import { ScreenSizeProvider } from 'contexts/ScreenSizeContext'

import '@/styles/index.scss'
import '@/styles/marketing/index.scss'

const queryClient = new QueryClient()

function App({ Component, pageProps, router }) {
  const user = useUser()
  const loadingUser = user === null
  const marketingPages = useStore((state) => state.marketingPages)
  const setIsOnline = useStore((state) => state.setIsOnline)
  const isOnline = useStore((state) => state.isOnline)
  const acceptedCookies = useStore((state) => state.acceptedCookies)
  const resetRespondentSession = useSession(
    (state) => state.resetRespondentSession
  )
  const isOnlineRef = useRef(isOnline)
  const { t } = useTranslation(['tests'])
  InitializeGTM()

  let ValidComponent = () => <></>
  const userExists = user && Object.keys(user).length > 0
  const adminPath = router.pathname.startsWith('/admin')
  const loginPath = router.pathname === '/login'
  const [cookiesAccepted, setCookiesAccepted] = useState(false)
  const { retryFailedResponses } = usePostOnReconnect()
  useConnectivityCheck()

  useEffect(() => {
    const acceptedCookies = localStorage.getItem('accepted_cookies')
    if (acceptedCookies) {
      setCookiesAccepted(true)
    }
  }, [])

  // If we're on a protected path without a valid user, to got index
  if (!loadingUser && adminPath && !userExists) {
    if (typeof window !== 'undefined') router.push('/login')
    ValidComponent = Index
  } else if (!loadingUser && loginPath && userExists) {
    if (typeof window !== 'undefined') router.push('/admin')
    ValidComponent = AdminDashboard
  }

  // If we're not on a protected path or user is admin, render as normal
  else if (!loadingUser && (!adminPath || userExists)) {
    ValidComponent = Component
  }

  function handleOffline() {
    showNotification({
      type: 'error',
      message: t('errors.connectionDropped'),
    })
    setIsOnline(false)
  }

  function handleOnline() {
    showNotification({
      type: 'success',
      message: t('notifications.connectionReestablised'),
    })
    setIsOnline(true)
    retryFailedResponses()
  }

  useEffect(() => {
    function handleUnload() {
      resetRespondentSession()
    }

    window.addEventListener('unload', handleUnload)

    return () => {
      window.removeEventListener('unload', handleUnload)
    }
  }, [])

  useEffect(() => {
    if (isOnlineRef.current !== isOnline) {
      if (!isOnline) {
        handleOffline()
      } else {
        handleOnline()
      }
    }

    isOnlineRef.current = isOnline
  }, [isOnline])

  useEffect(() => {
    const isMarketing = marketingPages.find((path) => path == router.pathname)
    document.body.className = isMarketing ? 'marketing' : 'app'
  }, [router.asPath, marketingPages, router.pathname])

  // Wrap component in layouts (if it has them) and inject user
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools
          initialIsOpen={false}
          position={'bottom-right'}
          styles={{ minHeight: '50%' }}
        />
        <ScreenSizeProvider>
          {buildLayout(ValidComponent.Layouts || [], ValidComponent, {
            ...pageProps,
            user,
          })}
        </ScreenSizeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default App
