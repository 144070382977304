import parse from 'html-react-parser'

import { Checkbox } from '@/components/checkbox'
import classnames from '@/util/classnames'
import { useHasBeenSeen } from '@/util/hooks/useHasBeenSeen'

export function AgreementForm({
  title,
  html,
  userAgrees,
  setUserAgrees,
  hasRead,
  setHasRead,
  flashAgreement,
  setFlashAgreement,
  scrollCTA,
  checkboxLabel,
  isLarge,
}) {
  const bottomRef = useHasBeenSeen(() => setHasRead(true))

  return (
    <div className={classnames([isLarge && 'isLarge'])}>
      <h3 className='new-member-consent-terms-title'>{title}</h3>
      <div className='terms-scroll-container'>
        <div>{parse(html)}</div>
        <div className='terms-scroll-read' ref={bottomRef} />
      </div>
      <div
        className='terms-checkbox-row'
        onClick={() => {
          !hasRead && setFlashAgreement(new Date().getTime())
        }}
      >
        {!hasRead ? (
          <p
            key={flashAgreement}
            className={classnames([
              flashAgreement > 0 ? 'flash-instructions' : null,
            ])}
          >
            {scrollCTA}
          </p>
        ) : null}
        <Checkbox
          onChange={(event) => {
            event.target.checked ? setUserAgrees(true) : setUserAgrees(false)
          }}
          style={{ marginLeft: 'auto' }}
          variant='right-checkbox'
          checked={userAgrees}
          disabled={!hasRead}
          name='acceptedTos'
          id={`${title}-checkbox`}
          label={<label htmlFor='acceptedTos'>{checkboxLabel}</label>}
        />
      </div>
    </div>
  )
}
