import { isSameDay } from 'date-fns'
import { useForm, Controller } from 'react-hook-form'
import { DateInput } from '@/components/date-input'
import { Modal } from '.'
import { useStore } from '@/util/store'
import { showNotification } from '@/components/notification'
import { Button } from '@/components/button'
import { useRoomCode } from '@/util/hooks'
import {
  getYearFromNow,
  standardDate,
  getTimeFromDate,
  filterDatesBeforeNowAfterEnd,
  filterDatesBeforeStart,
} from '@/util/dateTime'
import {
  startDayBeforeEndDay,
  startTimeBeforeEndTime,
  inThePast,
  validStartTime,
} from '@/util/validation'
import { format, subMinutes } from 'date-fns'

export function EditRoomTimeModal() {
  const closeModal = useStore((state) => state.closeModal)
  const modalState = useStore((state) => state.modalState)
  const { code, batchId } = modalState
  const { start, end, id, type } = code
  const startDate = new Date(start)
  const now = new Date()
  const allowStartDateEdit = startDate > subMinutes(now, 16)
  const { updateRoomCodeMutation } = useRoomCode({ batchId, type })
  const {
    register,
    handleSubmit,
    control,
    setError,
    getValues: getRoomValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      startDate: new Date(start),
      startTime: new Date(start),
      endDate: new Date(end),
      endTime: new Date(end),
    },
  })

  const onSubmit = async (data) => {
    try {
      const fields = {
        ...(allowStartDateEdit
          ? {
              start: new Date(
                `${standardDate(data.startDate)} ${getTimeFromDate(
                  data.startTime
                )}`
              ),
            }
          : {}),
        end: new Date(
          `${standardDate(data.endDate)} ${getTimeFromDate(data.endTime)}`
        ),
      }
      await updateRoomCodeMutation.mutateAsync({ id, fields })

      const codeType = modalState.isBatch ? 'batch' : 'room'
      showNotification({
        message: `The ${codeType} has been updated`,
        type: 'success',
      })
      closeModal()
    } catch (err) {
      console.error('err', err)
      onError(err)
    }
  }

  const onError = (errors) => {
    if (errors) {
      const errorMessages = Object.keys(errors?.data)
      const errorMsgValues = Object.values(errors?.data)
      const messages = {
        start: 'Your end date must be later than your start date',
      }
      const errorMessage = errorMessages?.length
        ? messages[errorMessages[0]]
        : 'There was an error with your selection. Please double-check your entries'
      showNotification({
        type: 'error',
        message: errorMessage,
      })

      if (errorMsgValues) {
        errorMsgValues.map((error) => {
          if (error.includes('start time')) {
            setError('startTime', {
              type: 'custom',
              message: "Start Time can't be in the past",
            })
          }
        })
      }
    }
  }

  return (
    <Modal variant='medium-large overflow'>
      <div className='modal-content'>
        <h3>Update batch time and date</h3>
        <form
          className='form-row-grid update-batch-time'
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          {allowStartDateEdit ? (
            <Controller
              control={control}
              name='startDate'
              rules={{
                required: true,
                validate: {
                  startDayBeforeEndDay: (v) =>
                    startDayBeforeEndDay(v, getRoomValues),
                },
              }}
              render={({ field }) => (
                <DateInput
                  name={field.name}
                  label='Start Date'
                  placeholderText='yyyy-mm-dd'
                  onChange={(e) => field.onChange(e)}
                  selected={field.value}
                  errors={errors}
                  dateFormat='yyyy-MM-dd'
                  variant='date'
                  maxDate={getYearFromNow()}
                  filterDate={(date) =>
                    filterDatesBeforeNowAfterEnd(date, getRoomValues)
                  }
                />
              )}
            />
          ) : (
            <div className='input-component date-input'>
              <label>Start Date</label>
              <p className='update-batch-time-start show-calendar'>
                {standardDate(start)}
              </p>
            </div>
          )}
          {allowStartDateEdit ? (
            <Controller
              control={control}
              name='startTime'
              rules={{
                required: true,
                validate: {
                  validStartTime: (v) => validStartTime(v, getRoomValues),
                  inThePast: (v) => inThePast(v, getRoomValues),
                  startTimeBeforeEndTime: (v) =>
                    startTimeBeforeEndTime(v, getRoomValues),
                },
              }}
              render={({ field }) => (
                <DateInput
                  errors={errors}
                  variant='time'
                  name={field.name}
                  selected={field.value}
                  label='Start Time'
                  placeholderText='--:-- --'
                  onChange={(e) => field.onChange(e)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  dateFormat='hh:mm aa'
                />
              )}
            />
          ) : (
            <div className='input-component date-input'>
              <label>Start Time</label>
              <p className='update-batch-time-start show-clock'>
                {format(new Date(start), 'hh:mm aa')}
              </p>
            </div>
          )}
          <Controller
            control={control}
            name='endDate'
            rules={{
              required: true,
              validate: {
                startDayBeforeEndDay: (v) =>
                  startDayBeforeEndDay(v, getRoomValues),
              },
            }}
            render={({ field }) => (
              <DateInput
                name={field.name}
                label='End Date'
                defaultValue={standardDate(end)}
                placeholderText='yyyy-mm-dd'
                onChange={(e) => field.onChange(e)}
                selected={field.value}
                errors={errors}
                dateFormat='yyyy-MM-dd'
                variant='date'
                maxDate={getYearFromNow()}
                filterDate={(date) =>
                  filterDatesBeforeStart(date, getRoomValues)
                }
              />
            )}
          />
          <Controller
            control={control}
            name='endTime'
            rules={{
              required: true,
              validate: {
                inThePast: (v) => inThePast(v, getRoomValues),
                startTimeBeforeEndTime: (v) =>
                  startTimeBeforeEndTime(v, getRoomValues),
              },
            }}
            render={({ field }) => (
              <DateInput
                errors={errors}
                variant='time'
                name={field.name}
                selected={field.value}
                label='End Time'
                placeholderText='--:-- --'
                onChange={(e) => field.onChange(e)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='Time'
                dateFormat='hh:mm aa'
                filterTime={(time) => {
                  const currentEndDate = getRoomValues().endDate
                  if (!isSameDay(currentEndDate, now)) {
                    return true
                  }
                  time.setMonth(currentEndDate.getMonth())
                  time.setDate(currentEndDate.getDate())
                  time.setFullYear(currentEndDate.getFullYear())
                  return time > now
                }}
              />
            )}
          />
        </form>
      </div>
      <div className='modal-actions space-between'>
        <button
          type='button'
          onClick={() => closeModal()}
          className='btn btn-outline'
        >
          Cancel
        </button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className='btn'
          isSubmitting={isSubmitting}
        >
          Update
        </Button>
      </div>
    </Modal>
  )
}
