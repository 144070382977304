import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { FieldError } from '@/components/field-error'
import 'react-datepicker/dist/react-datepicker.css'
import { enUS, enCA, fr } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@/components/tooltip'

const localeMap = {
  'en-US': enUS,
  'en-CA': enCA,
  fr: fr,
}

// CustomInput will not allow the user to type date manually
const CustomInput = ({ value, onClick, hasErrors }) => (
  <button
    type='button'
    className={`date-input-custom ${hasErrors}`}
    onClick={onClick}
  >
    {value ? value : <span className='placeholder'>yyyy-mm-dd</span>}
  </button>
)

export const DateInput = ({
  variant,
  label,
  name,
  errors,
  hasCustomInput,
  extraHint,
  tooltipText,
  ...props
}) => {
  const { i18n, t } = useTranslation('tests')
  const [locale, setLocale] = useState(localeMap[i18n.language] || enUS)
  const [startDate, setStartDate] = useState(new Date())
  const hasErrors =
    errors && Object.keys(errors).length && name in errors ? 'has-errors' : ''
  return (
    <div className={`input-component date-input ${variant || ''} ${hasErrors}`}>
      <div className='flex row justify-content-between'>
        {label && (
          <label htmlFor={name}>
            {label}
            {extraHint && <span className='extra-hint'>({extraHint})</span>}
          </label>
        )}
        {tooltipText && (
          <div className='inline-tooltip'>
            <Tooltip
              label={t('pages.respondentInfo.tooltips.whyAmIAsked')}
              tooltipText={tooltipText}
            />
          </div>
        )}
      </div>
      <DatePicker
        customInput={hasCustomInput && <CustomInput hasErrors={hasErrors} />}
        // Fixes popper error
        popperModifiers={[{ name: 'flip' }]}
        locale={locale}
        {...props}
      />
      <FieldError name={name} errors={errors} />
    </div>
  )
}
