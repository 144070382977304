import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Modal } from '.'
import { showNotification } from '@/components/notification'
import { Button } from '@/components/button'
import { useTranslation } from 'react-i18next'
import { useStore } from '@/util/store'
import { useProjects } from '@/util/hooks'

export function ReopenBatchModal() {
  const router = useRouter()
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const { projectId, batchId } = router.query
  const { updateBatch } = useProjects()
  const { t } = useTranslation(['tests', 'common'])
  const closeModal = useStore((state) => state.closeModal)

  const onSubmit = () => {
    const onSuccess = (returnedBatch) => {
      showNotification({
        message: `${returnedBatch.name} has been successfully reopened.`,
        type: 'success',
      })
      closeModal()
    }
    const onError = () => {
      showNotification({
        type: 'error',
        message: 'An error occurred while reopening the batch.',
      })
    }
    updateBatch({
      data: { batchId, body: { status: 'live' } },
      onSuccess,
      onError,
    })
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='modal-content'>
          <h1>Are you sure you want to reopen this batch?</h1>
          <p>
            Once you reopen this batch you will be able to run assessments and
            invite more respondents.
          </p>
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            {t('common:cancel')}
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Reopen Batch
          </Button>
        </div>
      </form>
    </Modal>
  )
}
