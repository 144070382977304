import { useSession } from '@/util/store'
import { useProperties } from './useProperties'

// For tests with versions such as the SSPO, we don't want to show the version
export const useTestDisplayName = () => {
  const { isSSPO } = useProperties()
  const activeTest = useSession((state) => state.activeTest)

  if (isSSPO) {
    return activeTest.baseName
  }

  return activeTest.name
}
