import { useEffect } from 'react'
import { useStore } from '@/util/store'

// add no-scroll class to body when
// modal is visible
const useNoScrollClass = (modal) => {
  useEffect(() => {
    if (!modal) return
    document.body.classList.add('no-scroll')
    return () => document.body.classList.remove('no-scroll')
  }, [modal])
}

export const Modals = ({ modals }) => {
  const modalName = useStore((state) => state.modal)
  useNoScrollClass(modalName)
  if (!modalName) return null
  const Match = modals[modalName]
  if (!Match) console.warn('no modal named ', modalName)
  return <Match />
}

export const Modal = ({ children, hideClose, variant }) => {
  const closeModal = useStore((state) => state.closeModal)
  return (
    <div
      className={`modal-component-container ${variant || ''}`}
      onClick={(ev) => {
        if (ev.target.classList.contains('body-no-close')) return
        if (ev.target.classList.contains('modal-component-container')) {
          closeModal()
        }
      }}
    >
      <div className='modal-component-content'>
        {!hideClose && (
          <div className='close' onClick={() => closeModal()}>
            close
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
