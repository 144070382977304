import { Modal } from '.'
import { useStore, useSession } from '@/util/store'
import { useRouter } from 'next/router'
import { useProperties } from '@/util/hooks/useProperties'
import { testIdentifiers } from '@/util/consts'
import { useTranslation } from 'react-i18next'

export function PracticeSkipModal() {
  const router = useRouter()
  const closeModal = useStore((state) => state.closeModal)
  const increaseSubTestIdx = useSession((state) => state.increaseSubTestIdx)
  const resetQuestionIdx = useSession((state) => state.resetQuestionIdx)
  const { testIdentifier } = useProperties()
  const isMAB3 = testIdentifier === testIdentifiers.MAB3
  const { t } = useTranslation(['tests'])
  const nextSubTest = () => {
    closeModal()
    increaseSubTestIdx()
    resetQuestionIdx()
    const redirect = isMAB3 ? '/tests/active' : '/tests/test/section/overview'
    router.replace(redirect)
  }

  return (
    <Modal variant='medium'>
      <div className='modal-header'>
        <h2 className='tc'>{t('modals.skipPractice.title')}</h2>
      </div>
      <div className='modal-content'>
        <p className=''>{t('modals.skipPractice.body')}</p>
      </div>
      <div className='modal-actions'>
        <button
          type='button btn-outline'
          onClick={() => {
            closeModal()
          }}
          className='btn btn-outline'
        >
          {t('modals.skipPractice.backButton')}
        </button>
        <button type='button' onClick={nextSubTest} className='btn'>
          {t('modals.skipPractice.skipButton')}
        </button>
      </div>
    </Modal>
  )
}
