import { useRouter } from 'next/router'
import { useIdleTimer } from 'react-idle-timer'
import { useSession } from '@/util/store'
import { TEST_TIMEOUT_TIME } from '../consts'

// Paths outside of the test that should not trigger the timeout
const PATHS_OUTSIDE_TEST = [
  '/tests',
  '/tests/reentry-code',
  '/tests/access-code',
  '/tests/select-language',
  '/tests/test-timeout',
]

export const useTestTimeout = () => {
  const router = useRouter()
  const testEntered = !PATHS_OUTSIDE_TEST.includes(router.pathname)
  const resetRespondentSession = useSession((s) => s.resetRespondentSession)

  const handleOnIdle = () => {
    router.replace('/tests/test-timeout').then(() => resetRespondentSession())
  }

  useIdleTimer({
    name: 'testTimeout',
    disabled: !testEntered,
    timeout: TEST_TIMEOUT_TIME * 60000,
    onIdle: handleOnIdle,
    crossTab: true,
  })

  return null
}
