import { pick, assocPath, path } from 'ramda'
import { useEffect, useMemo } from 'react'
import { useSession } from '@/util/store'
import { del, post } from '@/util/api'
import { apiUrl } from '@/util/urls'

// Used to track when to abort retrying a request.
// {<subtestQuestionId>: <currentTimestamp>}
const requests = {}

const postResponse = async ({
  subtestQuestionId,
  answerType,
  answers,
  answerText,
  timestamp,
  deviceId,
}) => {
  await post(
    apiUrl('responses'),
    {
      subtestQuestion: subtestQuestionId,
      timestamp,
      deviceId,
      ...(answerType === 'single' && { answer: answers[0] }),
      ...(answerType === 'multiple' && { answers }),
      ...(answerType === 'text' && { answerText }),
    },
    { respondentToken: useSession.getState().respondentToken }
  )
  if (requests[subtestQuestionId] != timestamp) return
  useSession.getState().markResponseSent({ subtestQuestionId, timestamp })
}

const deleteResponse = async ({ subtestQuestionId, timestamp, deviceId }) => {
  try {
    await del(`${apiUrl('responses')}/${subtestQuestionId}`, {
      params: { timestamp, device_id: deviceId },
      respondentToken: useSession.getState().respondentToken,
    })
  } catch (err) {
    if (err.code === 404) {
      console.log('attempted to delete a response that was not found')
      return
    }

    throw err
  }

  if (requests[subtestQuestionId] != timestamp) return
  useSession.getState().markResponseSent({ subtestQuestionId, timestamp })
}

const postResponseWithRetry = async (response, token) => {
  if (requests[response.subtestQuestionId] != response.timestamp) return

  if (!navigator.onLine) {
    setTimeout(() => {
      postResponseWithRetry(response, token)
    }, 1000)
    return
  }

  try {
    if (hasAnswer(response)) {
      await postResponse(response, token)
    } else {
      await deleteResponse(response, token)
    }
  } catch (err) {
    console.error('error saving response', err)
    setTimeout(() => {
      postResponseWithRetry(response, token)
    }, 1000)
  }
}

export const usePostAnswers = () => {
  const { responses, respondentToken } = useSession(
    pick(['responses', 'respondentToken'])
  )

  useEffect(async () => {
    if (!respondentToken) return

    const unsentResponses = Object.values(responses)
      .filter(
        (response) => response.sentTimestamp !== response.response.timestamp
      )
      .map((response) => response.response)
      .filter(
        (response) =>
          !requests[response.subtestQuestionId] ||
          requests[response.subtestQuestionId] !== response.timestamp
      )

    unsentResponses.forEach((response) => {
      requests[response.subtestQuestionId] = response.timestamp
      postResponseWithRetry(response, respondentToken)
    })
  }, [responses, respondentToken])
}

export const useAnswersArePosted = () => {
  const responses = useSession(path(['responses']))
  return useMemo(
    () =>
      Object.values(responses).filter(
        (response) => response.sentTimestamp !== response.response.timestamp
      ).length === 0,
    [responses]
  )
}

const hasAnswer = (response) => {
  if (response.answerType === 'single') {
    return !(response.answers[0] == null)
  }

  if (response.answerType === 'multiple') {
    return response.answers.length != 0
  }

  if (response.answerType === 'text') {
    return !!response.answerText
  }

  throw new Error('unsupported answerType')
}
