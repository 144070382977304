// Path name
export const questionPage = [
  '/tests/test/section/question/[question]',
  '/tests/active/subtest',
]
export const overviewPage = [
  '/tests/test/section/overview',
  '/tests/active/overview',
  '/tests/active/subtest-overview',
  '/tests/active/section-overview',
]
export const subtestOverviewPage = '/tests/active/subtest-overview'
export const respondentInfoPage = ['/tests/respondent-info']
export const respondentAssessmentPage = ['/tests/respondent-assessments']
export const instructionsPage = [
  '/tests/test/instructions',
  '/tests/active/instructions',
] // Left off number to match either instructions page

// Path name checks
export const isQuestionPage = (pathname) => questionPage.indexOf(pathname) > -1
export const isOverviewPage = (pathname) => overviewPage.indexOf(pathname) > -1
export const isSubtestOverviewPage = (pathname) =>
  pathname === subtestOverviewPage
export const isRespondentInfoPage = (pathname) =>
  respondentInfoPage.indexOf(pathname) > -1
export const isRespondentAssessmentPage = (pathname) =>
  respondentAssessmentPage.indexOf(pathname) > -1
export const isAnInstructionsPage = (pathname) =>
  instructionsPage.some((path) => pathname.startsWith(path))
