import { Modal } from '.'
import { useStore } from '@/util/store'
import { useKey } from '@/util/hooks'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

export function PracticeFeedbackModal() {
  const closeModal = useStore((state) => state.closeModal)
  const modalState = useStore((state) => state.modalState)
  const { t } = useTranslation(['tests', 'common'])
  useKey('Enter', close)
  useKey('Escape', close)

  function close() {
    document.activeElement.blur()
    closeModal()
  }

  return (
    <Modal variant='medium'>
      <div className='modal-header'>
        <h2 className='tc'>
          {modalState.isCorrect
            ? t('common:thatIsCorrect')
            : t('common:thatIsNotCorrect')}
        </h2>
      </div>
      <div className='modal-content'>
        <p className='tl'>{parse(modalState.feedback)}</p>
      </div>
      <div className='modal-actions'>
        <button type='button' onClick={close} className='btn'>
          {t('common:continue')}
        </button>
      </div>
    </Modal>
  )
}
