export const getTestStatus = (status) => {
  let variant = 'grey'
  let statusDisplay = 'Not Started'

  status = status.toLowerCase()

  if (status === 'inprogress' || status === 'in progress') {
    variant = 'light'
    statusDisplay = 'In Progress'
  }
  if (status === 'new' || status === 'not started' || status === 'notstarted') {
    variant = 'grey'
    statusDisplay = 'Not Started'
  }
  if (status === 'completed' || status === 'complete' || status === 'closed') {
    variant = 'green'
    statusDisplay = 'Complete'
  }

  return { variant, statusDisplay }
}
