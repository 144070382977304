import { Icon } from '@/components/icon'

import classnames from '@/util/classnames'
import styles from './warning-box.module.scss'

export const WarningBox = ({
  children,
  className,
  icon = <Icon name='orange-warning-triangle' />,
  textColor,
}) => (
  <div className={classnames([styles.container, className])}>
    <div className={classnames([styles['warning-icon']])}>{icon}</div>
    {children}
  </div>
)
