import { useQuery } from 'react-query'

import { get } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { useUser } from '@/util/hooks'

export const isLowOnCredits = (test) => test.reportsPrepaidRemaining <= 10

export const useBillingTests = ({ filter = undefined } = {}) => {
  const { token } = useUser()
  const { isLoading, error, data } = useQuery('testCredits', () =>
    get(apiUrl('billingTests'), { token })
  )

  if (error) {
    console.log(error)
  }

  if (isLoading || error) return [isLoading, []]

  let tests = data.results
  tests = filter ? tests.filter((test) => filter(test)) : tests
  tests = tests.map((test) => ({
    id: test.id,
    name: test.name,
    credits: test.reportsPrepaidRemaining,
  }))
  return [isLoading, tests]
}
