import classnames from '@/util/classnames'

import styles from './admin-maintenance-banner.module.scss'
import {
  useNotifications,
  notificationTypes,
} from '@/util/hooks/useNotifications'

export const AdminMaintenanceBanner = () => {
  const { isLoading, notification } = useNotifications(
    notificationTypes.ADMIN_BANNER
  )

  const shouldDisplay = !isLoading && notification

  return (
    <div
      style={{ display: shouldDisplay ? null : 'none' }}
      className={classnames([styles.banner])}
    >
      {shouldDisplay && (
        <p
          className={classnames([styles.content])}
          dangerouslySetInnerHTML={{
            __html: notification,
          }}
        ></p>
      )}
    </div>
  )
}
