import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-M48KV2G',
  events: {
    'nav-demo-btn': 'Demo Button - Nav',
    'hero-demo-btn': 'Demo Button - Hero',
    'about-demo-btn': 'Demo Button - About',
    'submit-demo-form': 'Book a Demo form',
    'nav-testtaker-link': 'Test Taker - Nav',
    'hero-testtaker-link': 'Test Taker - Hero',
    'nav-admin-login-btn': 'Admin Button - Nav',
    'hero-admin-login-btn': 'Admin Button - Hero',
    'accept-cookies': 'Accept Cookies',
    'save-cookie-setting': 'Save Cookie Setting',
    'allowed-analytics-location': 'Allowed Analytics Location'
  },
}

export const InitializeGTM = () => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])
}

export function sendGTMEvent(event) {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
    },
  })
}
