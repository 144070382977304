import { useSession } from '@/util/store'

const customLanguageDetector = {
  name: 'stateDetector',
  lookup: () => {
    const preferredLanguage = useSession.getState().preferredLanguage
    return preferredLanguage
  },
  cacheUserLanguage: (lng) => {
    const hasUserSetLanguage = useSession.getState().hasUserSetLanguage
    if (!hasUserSetLanguage) return
    useSession.setState({ preferredLanguage: lng })
  },
}

export default customLanguageDetector
