import Link from 'next/link'
import styles from './respondent-result.module.scss'
import classnames from '@/util/classnames'

export const RespondentNameResult = ({
  fullName,
  linkHref,
  hasReassessment,
  isFlagged,
  style,
  variant,
}) => {
  return (
    <Link href={linkHref} passHref>
      <a className={classnames([styles.link, variant])} style={{ ...style }}>
        {fullName}
        {hasReassessment && (
          <span className={classnames([styles.icon, styles.reassessment])} />
        )}
        {isFlagged && (
          <span className={classnames([styles.icon, styles.flag])} />
        )}
        <span className={classnames([styles.icon, styles['circle-arrow']])} />
      </a>
    </Link>
  )
}
