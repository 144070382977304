export const getProjectBatch = ({ projects, projectId, batchId }) => {
  const project = projects && projects.find((proj) => proj.id === projectId)

  let batch = {}
  if (batchId && project && Object.keys(project).length > 0) {
    batch = project.batches.find((batch) => batch.id === batchId)
  }

  return { project, batch }
}

export const getProjectBatches = (project) => {
  if (!project || !project.batches) return []

  return project.batches.slice().sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  )
}
