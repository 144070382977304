import { useEffect } from 'react'

import {
  useNotifications,
  notificationTypes,
} from '@/util/hooks/useNotifications'
import { useStore } from '@/util/store'

export const useRespondentMaintenanceCheck = () => {
  const MAINTENANCE_MODAL = 'RespondentMaintenanceModal'
  const activeModal = useStore((state) => state.modal)
  const openModal = useStore((state) => state.openModal)
  const closeModal = useStore((state) => state.closeModal)
  const { isLoading, notification, refetch } = useNotifications(
    notificationTypes.RESPONDENT_MODAL
  )

  useEffect(() => {
    if (isLoading) return

    if (notification && activeModal != MAINTENANCE_MODAL) {
      openModal(MAINTENANCE_MODAL, { notificationContent: notification })
    }

    if (!notification && activeModal == MAINTENANCE_MODAL) {
      closeModal()
    }
  }, [isLoading, notification, openModal, activeModal])

  return {
    refetch,
  }
}
