import { useState } from 'react'
import { FieldError } from '@/components/field-error'
import classnames from '@/util/classnames'

export const TextInput = ({
  type = 'text',
  name,
  value,
  label,
  hint,
  placeholder,
  variant,
  defaultValue,
  register,
  required,
  min,
  minLength,
  max,
  maxLength,
  pattern,
  validate,
  errors,
  highlightOnly,
  isPasswordField,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const hasRegister = register
    ? register(name, {
        required,
        min,
        max,
        minLength,
        maxLength,
        pattern,
        validate,
        errors,
      })
    : {}
  const hasErrors =
    errors && Object.keys(errors).length && name in errors ? 'has-errors' : ''

  return (
    <div
      className={classnames([
        'input-component',
        variant,
        hasErrors,
        isPasswordField && 'password',
      ])}
    >
      {label && (
        <label htmlFor={name}>
          {label}
          <br></br>
          {hint && (
            <p
              htmlFor={name}
              style={{
                opacity: '0.5',
                fontSize: '14px',
                fontWeight: '50',
                letterSpacing: '0.005em',
              }}
            >
              {hint}
            </p>
          )}
        </label>
      )}
      <div className='input-field-wrapper'>
        <input
          type={isPasswordField && showPassword ? 'text' : type}
          name={name}
          value={value}
          min={min}
          max={max}
          autoComplete='off'
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...hasRegister}
          {...props}
        />
        {isPasswordField ? (
          <button
            type='button'
            className='password-preview'
            onClick={() => setShowPassword(!showPassword)}
          >
            eye
          </button>
        ) : null}
      </div>
      {highlightOnly ? null : (
        <FieldError
          name={name}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
          errors={errors}
          pattern={pattern}
        />
      )}
    </div>
  )
}
