/**
 * Accepts an array of objects as a list of params.
 * @param {*} router
 * @param {*} paramList
 * @param {*} isShallow
 */
export function appendQueryParamsToRouter({
  router,
  paramList,
  isShallow = true,
}) {
  const queryParams = router.query
  paramList.forEach((param) => Object.assign(queryParams, param))
  router.replace(
    {
      pathname: router.pathname,
      query: queryParams,
    },
    undefined,
    /**
     * Do not refresh the page
     */
    { shallow: isShallow }
  )
}
