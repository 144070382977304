import { useEffect, useRef } from 'react'
import { useStore } from '@/util/store'

export const useConnectivityCheck = () => {
  const setIsOnline = useStore((state) => state.setIsOnline)
  const isOnline = useStore((state) => state.isOnline)

  const checkConnectivity = async () => {
    try {
      const response = await fetch('/api/check-connectivity')
      if (!response.ok) {
        throw new Error('Connectivity check failed')
      }
      setIsOnline(true)
    } catch (error) {
      setIsOnline(false)
      console.log(error)
    }
  }

  useEffect(() => {
    let desiredInterval = isOnline ? 15000 : 5000
    const interval = setInterval(checkConnectivity, desiredInterval)

    return () => {
      clearInterval(interval)
    }
  }, [isOnline])

  useEffect(() => {
    window.addEventListener('offline', () => setIsOnline(false))
    window.addEventListener('online', () => setIsOnline(true))

    return () => {
      window.removeEventListener('offline', () => setIsOnline(false))
      window.removeEventListener('online', () => setIsOnline(true))
    }
  }, [])
}
