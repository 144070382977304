import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'

import { Modal } from '.'
import { Button } from '@/components/button'
import { showNotification } from '@/components/notification'

import { useStore } from '@/util/store'
import { useProjects } from '@/util/hooks'

export function RemoveTestModal() {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const router = useRouter()
  const { projectId, batchId } = router.query
  const { testId, testName, projects, removeBatchTest, onRemove } = useStore(
    (state) => state.modalState
  )
  const closeModal = useStore((state) => state.closeModal)
  const projectIndex =
    projects && projects.findIndex((o, i) => o.id === projectId)
  const batchIndex =
    projects &&
    projects[projectIndex].batches.findIndex((o, i) => o.id === batchId)

  const onSubmit = async () => {
    const onSuccess = () => {
      closeModal()
      showNotification({
        message: `${testName} was successfully removed.`,
        type: 'success',
      })
      onRemove && onRemove()
    }
    const onError = () => {
      showNotification({
        type: 'error',
        message: 'An error occurred while removing a test.',
      })
    }

    removeBatchTest({
      data: { testId, batch: projects[projectIndex].batches[batchIndex] },
      onSuccess,
      onError,
    })
  }

  return (
    <Modal variant='styled-modal medium'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='modal-content'>
          <h1>Remove {testName}? </h1>
          <p>
            If you remove this test, any previous scoring data will be lost for
            this batch. This action cannot be undone.
          </p>
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Remove Test
          </Button>
        </div>
      </form>
    </Modal>
  )
}
