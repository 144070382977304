import { useQuery } from 'react-query'

import { get } from '@/util/api'
import { apiUrl } from '@/util/urls'

export function useOrganizationAgreements(args) {
  const {
    isLoading: isOrganizationAgreementsLoading,
    data: organizationAgreements,
  } = useQuery(
    'organizationAgreements',
    () => get(apiUrl('organizationAgreements') + `/${args.testCode}`),
    { enabled: Boolean(args.testCode) }
  )

  return {
    isOrganizationAgreementsLoading,
    organizationAgreements,
  }
}
