import { Icon } from '@/components/icon'

export const ActionableLineItem = ({
  title,
  buttonTitle,
  buttonAction,
  iconName,
  buttonVariant,
  buttonDisabled = false,
  children
}) => {
  return (
    <div className='actionable-item'>
      <div>
        <button
          className={`${buttonVariant ? buttonVariant : ''} ${
            iconName ? 'btn-icon' : ''
          } btn btn-secondary`}
          disabled={buttonDisabled}
          onClick={buttonAction}>
          {buttonTitle}
          {iconName ? <Icon name={iconName} /> : null}
        </button>
      </div>

      <div className='actionable-item-content'>
        <span className='item-title'>{title}</span>
        {children}
      </div>
    </div>
  )
}
