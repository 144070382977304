import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Modal } from '.'
import { useStore } from '@/util/store'
import { showNotification } from '@/components/notification'
import { TextInput } from '@/components/text-input'
import { Button } from '@/components/button'
import { useProjects } from '@/util/hooks'

export function AddBatchModal() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const router = useRouter()
  const closeModal = useStore((state) => state.closeModal)
  const modalState = useStore((state) => state.modalState)
  const { createBatch } = useProjects()

  const onSubmit = async (data) => {
    const body = { ...data, project: modalState.projectId }
    const onSuccess = (returnedBatch) => {
      showNotification({
        message: `Success! ${returnedBatch.name} has been created.`,
        type: 'success',
      })
      router.push(
        `/admin/project/${returnedBatch.project}/batch/${returnedBatch.id}/edit`
      )
      closeModal()
    }
    const onError = (error) => {
      if (error.code === 400) {
        showNotification({
          type: 'error',
          message: 'Batch name already exists - please use a different name.',
        })
      } else {
        showNotification({
          type: 'error',
          message: 'An error occurred while creating a batch.',
        })
      }
    }
    createBatch({ body, onSuccess, onError })
  }

  const onError = (errors) => {
    if (errors) {
      showNotification({
        message: 'Please correct the highlighted fields below.',
        type: 'error',
      })
    }
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className='modal-content'>
          <h1>Add New Batch</h1>
          <TextInput
            name='name'
            label='Name of Batch'
            placeholder='Choose a descriptive name (e.g., Q1 Hires)'
            register={register}
            errors={errors}
            minLength={3}
            required
          />
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  )
}
