import { useStore, useSession } from '@/util/store'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

export function SubmitTestModalError({
  submitError,
  submitButtonLabel,
  isSubmittingAnswers,
}) {
  const isOnline = useStore((state) => state.isOnline)
  const reentryCode = useSession((state) => state.reentryCode)
  const { t } = useTranslation(['tests', 'common'])
  if (!isOnline) {
    return (
      <>
        <p className='modal-offline-note'>
          {parse(t('modals.submitAssesmentError.offline.youAreOffline'))}
        </p>
        <p className='tl'>
          {t('modals.submitAssesmentError.offline.submitButtonDisabled', {
            submitButtonLabel: submitButtonLabel.toUpperCase(),
          })}
        </p>
        <p className='tl uppercase strong'>
          {t('modals.submitAssesmentError.offline.dataLoss', {
            submitButtonLabel: submitButtonLabel.toUpperCase(),
          })}
        </p>
        <p className='tl uppercase strong'>
          {t('modals.submitAssesmentError.offline.mainInstruction')}
        </p>
        <p className='tl'>
          <strong>
            {t('common:reentryCode')}: {reentryCode}
          </strong>
        </p>
      </>
    )
  }

  if (isSubmittingAnswers) {
    return (
      <>
        <p className='modal-offline-note'>
          {parse(t('modals.submitAssesmentError.submitting.submitting'))}
        </p>
        <p className='tl'>
          {t('modals.submitAssesmentError.submitting.dataLoss', {
            submitButtonLabel: submitButtonLabel.toUpperCase(),
          })}
        </p>
        <p className='tl'>
          <strong>
            {t('common:reentryCode')}: {reentryCode}
          </strong>
        </p>
      </>
    )
  }

  if (submitError) {
    return submitError
  }

  return null
}
