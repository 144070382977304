import SvgDefs from 'public/images/svg/symbol-defs.svg'

import { Modals } from '@/components/modals'
import { AddProjectModal } from '@/components/modals/add-project-modal'
import { AddBatchModal } from '@/components/modals/add-batch-modal'
import { ArchiveProjectModal } from '@/components/modals/archive-project-modal'
import { Notification } from '@/components/notification'
import { ArchiveBatchModal } from '@/components/modals/archive-batch-modal'
import { PublishBatchModal } from '@/components/modals/publish-batch-modal'
import { ReopenBatchModal } from '@/components/modals/reopen-batch-modal'
import { CompleteBatchModal } from '@/components/modals/complete-batch-modal'
import { AddTestModal } from '@/components/modals/add-test-modal'
import { RemoveTestModal } from '@/components/modals/remove-test-modal'
import { AddNewTeamMemberModal } from '@/components/modals/add-new-team-member-modal'
import { DeleteTeamMemberModal } from '@/components/modals/delete-team-member-modal'
import { AnonymizeRespondentModal } from '@/components/modals/anonymize-respondent-modal'
import { ChangePasswordModal } from '@/components/modals/change-password-modal'
import { PracticeFeedbackModal } from '@/components/modals/practice-feedback-modal'
import { PracticeSkipModal } from '@/components/modals/practice-skip-modal'
import { StopAssessmentModal } from '@/components/modals/stop-assessment-modal'
import { TimeLimitModal } from '@/components/modals/time-limit-modal'
import { EndOfTestTimeLimitModal } from '@/components/modals/end-of-test-time-limit-modal'
import { EndOfTestModal } from '@/components/modals/end-of-test-modal'
import { EndOfSubTestModal } from '@/components/modals/end-of-sub-test-modal'
import { TestEndModal } from '@/components/modals/test-end-modal'
import { NewMemberConsentModal } from '@/components/modals/new-member-consent-modal'
import { EditRoomTimeModal } from './modals/edit-room-time-modal'
import { PracticeEndModal } from '@/components/modals/practice-end-modal'
import { OfflineModal } from '@/components/modals/offline-modal'
import { ViewFullImageModal } from '@/components/modals/view-full-image-modal'
import { DeleteAssessmentModal } from '@/components/modals/delete-assessment-modal'
import { DeleteRespondentModal } from '@/components/modals/delete-respondent-modal'
import { RespondentMaintenanceModal } from '@/components/modals/respondent-maintenance-modal'
import { AdminMaintenanceModal } from '@/components/modals/admin-maintenance-modal'

export const LayoutBase = ({ children }) => {
  return (
    <>
      <Modals
        modals={{
          AddProjectModal,
          AddBatchModal,
          ArchiveProjectModal,
          ArchiveBatchModal,
          PublishBatchModal,
          ReopenBatchModal,
          CompleteBatchModal,
          AddTestModal,
          RemoveTestModal,
          AddNewTeamMemberModal,
          DeleteTeamMemberModal,
          ChangePasswordModal,
          AnonymizeRespondentModal,
          PracticeFeedbackModal,
          StopAssessmentModal,
          PracticeSkipModal,
          TimeLimitModal,
          EndOfTestTimeLimitModal,
          EndOfTestModal,
          EndOfSubTestModal,
          NewMemberConsentModal,
          OfflineModal,
          EditRoomTimeModal,
          TestEndModal,
          PracticeEndModal,
          ViewFullImageModal,
          DeleteAssessmentModal,
          DeleteRespondentModal,
          RespondentMaintenanceModal,
          AdminMaintenanceModal,
        }}
      />
      <SvgDefs />
      <div className='main-app-container'>
        <Notification />
        {children}
      </div>
    </>
  )
}
