import { FieldError } from '@/components/field-error'
import { Tag } from '@/components/tag'
import classnames from '@/util/classnames'

export const Checkbox = ({
  id,
  name,
  label,
  hint,
  className,
  tagName,
  tagVariant,
  count,
  defaultChecked,
  variant,
  children,
  required,
  register,
  errors,
  style,
  ...props
}) => {
  const hasRegister = register ? register(name, { required, errors }) : {}
  const hasErrors =
    errors && Object.keys(errors).length && name in errors ? 'has-errors' : ''
  return (
    <div
      className={classnames([className, `checkbox-component ${variant || ''}`])}
      style={style}
    >
      <input
        type='checkbox'
        name={name}
        id={id}
        {...hasRegister}
        {...props}
        defaultChecked={defaultChecked}
      />
      {tagName ? (
        <label htmlFor={id}>
          <Tag variant={tagVariant}>
            {tagName}
            <span>{' (' + count + ')'}</span>
          </Tag>
        </label>
      ) : (
        <label htmlFor={id} style={{ fontSize: '0.85rem' }}>
          {label}
        </label>
      )}

      <span htmlFor={id} style={{ fontStyle: 'italic', fontSize: '14px' }}>
        {' '}
        {hint}
      </span>
      <FieldError name={name} errors={errors} />
    </div>
  )
}
