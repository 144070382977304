export const getOverallTestStatus = (assessments) => {
  const statuses = assessments.map((assessment) => assessment.status)
  if (statuses.length === 0) return ''

  return statuses.reduce((prevStatus, currStatus) => {
    // only returns 'new' or 'complete' if all statuses are of that type
    if (prevStatus !== currStatus || prevStatus === 'inprogress')
      return 'inprogress'
    else return prevStatus
  })
}
