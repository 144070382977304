import { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useProperties } from './useProperties'
import { showNotification } from '@/components/notification'

/**
 *
 * @param {object} keyHandlers mapping between keys and their handlers (eg. {'n': onNext, 'p': onPrev})
 * Hook used to route keyboard events to handlers
 */
export function useKeys(keyHandlers) {
  const { isAutoAdvancing } = useProperties()
  const { t } = useTranslation(['common'])
  const [lastHandledKeypress, setLastHandledKeypress] = useState(null)
  const [isDown, dispatch] = useReducer(isDownReducer, keyHandlers, (keys) =>
    Object.keys(keys).reduce((initialState, key) => {
      initialState[key] = false
      return initialState
    }, {})
  )

  useEffect(() => {
    function keyDownHandler(event) {
      const capsLock = event.getModifierState('CapsLock')
      if (capsLock) {
        showNotification({
          message: t('notifications.disableCaps'),
          type: 'error',
        })
      }

      if (isAutoAdvancing) {
        // debounce to prevent spamming
        const now = Date.now()
        const millisecondsSinceLastKeystroke = lastHandledKeypress
          ? now - lastHandledKeypress
          : Infinity

        if (millisecondsSinceLastKeystroke < 300) return
        setLastHandledKeypress(now)
      }

      if (event.key in keyHandlers && !isDown[event.key]) {
        keyHandlers[event.key](event)
        dispatch({ type: KEY_DOWN, key: event.key })
      }
    }

    function keyUpHandler(event) {
      if (event.key in keyHandlers && isDown[event.key]) {
        dispatch({ type: KEY_UP, key: event.key })
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('keyup', keyUpHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('keyup', keyUpHandler)
    }
  }, [
    keyHandlers,
    isDown,
    isAutoAdvancing,
    setLastHandledKeypress,
    lastHandledKeypress,
  ])
}

// actions
const KEY_DOWN = 'keyDown'
const KEY_UP = 'keyUp'

const isDownReducer = (state, action) => {
  const { type, key } = action
  const isKeyDown = type == KEY_DOWN ? true : false

  return {
    ...state,
    [key]: isKeyDown,
  }
}
