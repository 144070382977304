import { useEffect, useState } from 'react'

import { subtestTypes } from '@/util/consts'

/**
 * A hook for preloading images - used to preemptively request images so that the browser will cache them
 * @param {object} subtest The subtest for which to load images
 * @returns An object containing:
 *   - `loadImages` function to retry image loading if it happens to fail
 *   - `isError` true if an error was encountered on preload
 *   - `isLoading` true if preloading is in progress
 *   - `loadCount` number of images which have finished loading
 *   - `totalCount` total number of images to preload
 */
export const useImagePreload = (subtest) => {
  const images = new Set(getImageSources(subtest))
  const [loadCount, setLoadCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const loadImages = async () => {
    setIsLoading(true)
    setLoadCount(0)
    const onLoad = () => setLoadCount((count) => count + 1)

    try {
      const p = await Promise.all(
        Array.from(images).map((src) => loadImage(src).then(onLoad))
      )
      setIsError(false)
      return p
    } catch (err) {
      const imageError = new Error(`image failed to load: ${err.target?.src}`)
      console.log(imageError)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadImages()
  }, [subtest])

  return {
    loadImages,
    isError,
    isLoading,
    loadCount,
    totalCount: images.size,
  }
}

// Helpers

const loadImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(src)
    img.onerror = (err) => reject(err)
    img.src = src
  })

const getImageSources = (subtest) => {
  if (!subtest || !subtest.subtestQuestions) return []

  const images = []
  subtest.subtestQuestions.forEach((q) => {
    const question = q.question

    if (question.type == subtestTypes.symbol_search) {
      const characters = [...question.bodyText.replace(/\s/g, '')]
      question.answers
        .filter((answer) => answer.text != 'None')
        .forEach((answer) => characters.push(answer.text))
      characters.forEach((char) =>
        images.push(`/images/tests/symbols/${char}.png`)
      )
    }

    if (question.type == subtestTypes.digit_symbol_coding) {
      const characters = [...question.bodyText.replace(/\s/g, '')]
      characters.forEach((char) =>
        images.push(`/images/tests/symbols/${char}.png`)
      )
    }

    if (question.type == subtestTypes.cancellation) {
      const imgCodes = question.bodyText.split(',')
      question.answers.forEach((answer) => imgCodes.push(answer.text))
      imgCodes.forEach((code) =>
        images.push(`/images/tests/cancellation/${code}.png`)
      )
    }

    question.questionLegendImages.forEach((img) => {
      images.push(img.imageData.image)
    })

    question.questionBodyImages.forEach((img) => {
      images.push(img.imageData.image)
    })

    question.answers.forEach((a) => {
      a.answerImages.forEach((img) => {
        images.push(img.imageData.image)
      })
    })
  })

  return images
}
