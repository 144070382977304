import { assocPath } from 'ramda'
import { v4 as uuid4 } from 'uuid'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import produce from 'immer'

// items in here don't expire on hard refresh
export const [useSession, api] = create(
  devtools(
    persist(
      (set, get) => ({
        // DEV NOTE: if any state is added here it will also need to be restored on re-entry!

        preferredLanguage: 'en',
        setPreferredLanguage: (preferredLanguage) => set({ preferredLanguage }),
        hasUserSetLanguage: false,
        setHasUserSetLanguage: (hasUserSetLanguage) =>
          set({ hasUserSetLanguage }),

        setRehydratedState: (state) => set(state),
        testStarted: false,
        setTestStarted: (testStarted) => set((state) => ({ testStarted })),

        respondentToken: null,
        setRespondentToken: (respondentToken) => set({ respondentToken }),

        reentryCode: '',
        setReentryCode: (reentryCode) => set({ reentryCode }),

        activeRespondent: {},
        setActiveRespondent: (activeRespondent) => set({ activeRespondent }),

        activeTest: {},
        setActiveTest: (activeTest) => set({ activeTest }),
        getTimeLimit: () => get().activeSubTest.subtest.timeLimit,

        getTestProperty: (accessor) => {
          return accessor(get().activeTest)
        },

        activeSubTest: {},
        setActiveSubTest: (activeSubTest) =>
          set((state) => ({ activeSubTest })),

        activeSection: {},
        setActiveSection: (activeSection) =>
          set((state) => ({ activeSection })),
        increaseActiveSection: () =>
          set((state) => {
            const activeSection = get().activeSection
            const activeTest = get().activeTest
            const canIncreaseSection =
              activeTest?.sections?.length - 1 > activeSection?.order
            const sectionIndex = activeTest?.sections.findIndex(
              (section) => section.id === activeSection.id
            )
            const newIndex = canIncreaseSection
              ? sectionIndex + 1
              : sectionIndex

            const newObject = {
              id: activeTest?.sections[newIndex]?.id,
              order: newIndex,
              name: activeTest?.sections[newIndex]?.name,
              description: activeTest?.sections[newIndex]?.description,
            }
            return { activeSection: newObject }
          }),
        decreaseActiveSection: () =>
          set((state) => {
            const activeSection = get().activeSection
            const activeTest = get().activeTest

            const canDecreaseSection = activeSection?.order > 0

            const sectionIndex = activeTest?.sections.findIndex(
              (section) => section.id === activeSection.id
            )

            const newIndex = canDecreaseSection
              ? sectionIndex - 1
              : sectionIndex

            const newObject = {
              id: activeTest?.sections[newIndex]?.id,
              order: newIndex,
              name: activeTest?.sections[newIndex]?.name,
              description: activeTest?.sections[newIndex]?.description,
            }

            return { activeSection: newObject }
          }),

        getSubtestProperty: (accessor) => {
          return accessor(get().activeSubTest)
        },

        updateSubtest: (updateFn) => {
          set({
            activeSubTest: produce(get().activeSubTest, updateFn),
          })
        },

        responses: {},
        resetResponses: () => set({ responses: {} }),
        addResponse: (response) =>
          set(
            assocPath(['responses', response.subtestQuestionId, 'response'], {
              ...response,
              timestamp: new Date().toISOString(),
              deviceId: get().deviceId,
            })
          ),
        markResponseSent: ({ subtestQuestionId, timestamp }) => {
          set(
            assocPath(
              ['responses', subtestQuestionId, 'sentTimestamp'],
              timestamp
            )
          )
        },
        addRestoredResponse: (response) => {
          const timestamp = new Date().toISOString()
          set(
            assocPath(['responses', response.subtestQuestionId], {
              sentTimestamp: timestamp,
              response: {
                ...response,
                timestamp,
              },
            })
          )
        },
        hasResponse: (subtestQuestionId) => {
          return get().responses.hasOwnProperty(subtestQuestionId)
        },

        failedResponses: {},
        addFailedResponse: ({ reqTimestamp, response }) =>
          set((state) => {
            const questionId =
              response.subtest_question_id || response.subtestQuestion
            const containsResponse = state.failedResponses.hasOwnProperty(
              questionId
            )
            const isLaterResponse =
              containsResponse &&
              state.failedResponses[questionId].reqTimestamp < reqTimestamp

            if (!containsResponse || isLaterResponse) {
              return {
                failedResponses: {
                  ...state.failedResponses,
                  [questionId]: {
                    reqTimestamp,
                    response,
                  },
                },
              }
            }
          }),
        removeFailedResponse: (subtestQuestionID, timestamp) =>
          set((state) => {
            const failedResponses = get().failedResponses
            if (
              failedResponses.hasOwnProperty(subtestQuestionID) &&
              failedResponses[subtestQuestionID].response.timestamp ===
                timestamp
            ) {
              const updatedFailedResponses = Object.fromEntries(
                Object.entries(failedResponses).filter(
                  ([key]) => key !== subtestQuestionID
                )
              )
              return { failedResponses: updatedFailedResponses }
            }
          }),
        setFailedResponses: (failedResponses) => set({ failedResponses }),

        // latest timestamp at which the respondents 'last_active' time has been updated on the backend
        // should be updated whenever a response is posted or the 'start-timer' endpoint is hit
        lastActiveTime: null,
        setLastActiveTime: (lastActiveTime) => set({ lastActiveTime }),

        // latest timestamp at which the timer was started
        startTime: null,

        // cumulative time (in seconds) that the timer has run before the current startTime
        timeUsed: 0,
        setTimeUsed: (timeUsed) => set({ timeUsed }),
        isTimerStarted: () => get().startTime !== null,
        getTimeUsed: () => {
          const now = new Date()
          const start = new Date(get().startTime)
          const secondsElapsed = get().startTime
            ? Math.floor((now - start) / 1000)
            : 0
          return get().timeUsed + secondsElapsed
        },
        startTimer: () =>
          set(() => {
            const now = new Date()
            return { startTime: now, lastActiveTime: now }
          }),
        stopTimer: () =>
          set((state) => {
            const now = new Date()
            const start = new Date(state.startTime)
            const secondsElapsed = state.startTime
              ? Math.floor((now - start) / 1000)
              : 0

            return {
              startTime: null,
              lastActiveTime: null,
              timeUsed: state.timeUsed + secondsElapsed,
            }
          }),
        resetTimer: () =>
          set({
            startTime: null,
            lastActiveTime: null,
            timeUsed: 0,
          }),

        questionIdx: 0,
        increaseQuestionIdx: () =>
          set((state) => ({ questionIdx: state.questionIdx + 1 })),
        decreaseQuestionIdx: () =>
          set((state) => ({ questionIdx: state.questionIdx - 1 })),
        resetQuestionIdx: () => set((state) => ({ questionIdx: 0 })),
        setQuestionIdx: (questionIdx) => set({ questionIdx }),

        subTestIdx: 0,
        increaseSubTestIdx: () =>
          set((state) => ({ subTestIdx: state.subTestIdx + 1 })),
        decreaseSubTestIdx: () =>
          set((state) => ({ subTestIdx: state.subTestIdx - 1 })),
        resetSubTestIdx: () => set((state) => ({ subTestIdx: 0 })),
        setSubTestIdx: (subTestIdx) => set({ subTestIdx }),

        isPracticeTest: false,
        setIsPracticeTest: (isPracticeTest) => set({ isPracticeTest }),

        agreePrivacy: false,
        setAgreePrivacy: (agreePrivacy) => set({ agreePrivacy }),

        agreeTerms: false,
        setAgreeTerms: (agreeTerms) => set({ agreeTerms }),

        agreeAdditionalAgreement: false,
        setAgreeAdditionalAgreement: (agreeAdditionalAgreement) =>
          set({ agreeAdditionalAgreement }),

        surveyFlags: null,
        setSurveyFlags: (flags) =>
          set({ surveyFlags: { ...get().surveyFlags, ...flags } }),

        testBookmark: null,
        setTestBookmark: (testBookmark) => set({ testBookmark }),

        deviceId: uuid4(),

        resetTest: () =>
          set({
            activeTest: {},
            activeSubTest: {},
            failedResponses: {},
            questionIdx: 0,
            subTestIdx: 0,
            isPracticeTest: false,
            timeUsed: 0,
          }),

        resetRespondentSession: () =>
          set({
            testStarted: false,
            respondentToken: null,
            reentryCode: null,
            activeRespondent: {},
            activeTest: {},
            activeSubTest: {},
            activeSection: {},
            responses: {},
            failedResponses: {},
            lastActiveTime: null,
            startTime: null,
            timeUsed: 0,
            questionIdx: 0,
            subTestIdx: 0,
            isPracticeTest: false,
            agreePrivacy: false,
            agreeTerms: false,
            surveyFlags: null,
            testBookmark: null,
            deviceId: uuid4(),
          }),
      }),
      {
        name: 'respondent-session', // unique name
        getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
      }
    )
  )
)
