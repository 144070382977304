export const resources = [
  {
    title: 'OACP Certificate Process',
    url:
      'https://cdn.sigmaassessments.com/sigmaassessstatic/TNT-pdfs/oacp-certificate-process.pdf',
  },
  {
    title: 'SSPO: Report Interpretation Guide',
    url:
      'https://cdn.sigmaassessments.com/sigmaassessstatic/TNT-pdfs/sspo-interpretation-guide.pdf',
  },
  {
    title: 'PRF-R for Policing: Report Interpretation Guide',
    url:
      'https://cdn.sigmaassessments.com/sigmaassessstatic/TNT-pdfs/prfr-policing-interpretation-guide.pdf',
  },
  {
    title: 'PRF-R for Policing: Report Interpretation Infographic',
    url:
      'https://cdn.sigmaassessments.com/sigmaassessstatic/TNT-pdfs/prfr-interpretation-infographic.pdf',
  },
  {
    title: 'PRF-R for Policing: Interview Preparation Form',
    url:
      'https://cdn.sigmaassessments.com/sigmaassessstatic/TNT-pdfs/prfr-policing-interview-1.pdf',
  },
  {
    title: 'PRF-R for Policing: Recruiter FAQ',
    url:
      'https://cdn.sigmaassessments.com/sigmaassessstatic/TNT-pdfs/prfr-recruiter-faq.pdf',
  },
]
