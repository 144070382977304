import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { showNotification } from '@/components/notification'
import { useTestDisplayName } from '@/util/hooks'
import { useSession } from '@/util/store'

export const useTestComplete = () => {
  const router = useRouter()
  const { t } = useTranslation(['tests'])
  const resetTimer = useSession((s) => s.resetTimer)
  const testDisplayName = useTestDisplayName()

  const onTestComplete = () => {
    showNotification({
      type: 'success',
      message: t('modals.endOfTest.successMessage', {
        testName: testDisplayName,
      }),
    })
    resetTimer()

    router.push('/tests/respondent-assessments')
  }

  return {
    onTestComplete,
  }
}
