import { add, isSameDay } from 'date-fns'

// Whitespace
export const emptyString = (value) => !!value.trim()

export const beginsOrEndsWithSpace = (value) =>
  value[0] !== ' ' && value[value.length - 1] !== ' '

// Password Matching
export const passwordMatch = (value, getValues) => {
  const { newPassword, confirmPassword } = getValues()

  return newPassword === confirmPassword
}

// Input validations

export const minLength = (value) => {
  return value.length <= minLength
}

export const containsAlphanumericSpacesAndDashes = (value) => {
  return /^([-A-Za-z0-9 ]){1,20}$/.test(value)
}

export const containsAlphanumericCharacters = (value) => {
  return /^[a-zA-Z0-9]*$/.test(value)
}

export const containsUpperAndLowerCases = (value) => {
  return /(?=.*?[A-Z])(?=.*?[a-z])/.test(value)
}

export const containsNumber = (value) => {
  return /(?=.*?[0-9])/.test(value)
}

export const invalidDateOfBirth = (value) => {
  const dateValue = new Date(value).getFullYear()
  const minDate = new Date().setFullYear(new Date().getFullYear() - 99)
  const maxDate = new Date().setFullYear(new Date().getFullYear() - 18)

  if (dateValue < minDate || dateValue > maxDate) {
    return true
  }
}

// Test Code Date Validation
export const isTimeInPast = (dateTime) => {
  const currentTime = new Date().getTime()
  const leewayTime = currentTime - 16 * 60 * 1000 // Add leeway time
  return new Date(dateTime).getTime() < leewayTime
}

export const inThePast = (value, getValues) => {
  const { startDate, endDate, startTime, endTime } = getValues()
  return (
    !isTimeInPast(startDate + ' ' + startTime) &&
    !isTimeInPast(endDate + ' ' + endTime)
  )
}

export const startTimeBeforeEndTime = (value, getValues) => {
  const { startDate, endDate, startTime, endTime } = getValues()
  if (isSameDay(startDate, endDate)) {
    return startTime < endTime
  }

  return true
}

export const validStartTime = (v, getValues) => {
  const { startDate, startTime } = getValues()

  if (isSameDay(startDate, new Date())) {
    const now = new Date().getTime()
    const leewayTime = now - 16 * 60 * 1000 // Add leeway time
    const start = new Date(startTime).getTime()
    return start > leewayTime
  } else {
    return true
  }
}

export const startDayBeforeEndDay = (value, getValues) => {
  const { startDate, endDate, startTime, endTime } = getValues()

  if (
    typeof startDate === 'undefined' ||
    typeof endDate === 'undefined' ||
    typeof startTime === 'undefined' ||
    typeof endTime === 'undefined'
  )
    return
  const startHours = startTime.getHours()
  const startMinutes = startTime.getMinutes()
  const endHours = endTime.getHours()
  const endMinutes = endTime.getMinutes()
  const actualStartTime = add(startDate, {
    hours: startHours,
    minutes: startMinutes,
  })
  const actualEndTime = add(endDate, { hours: endHours, minutes: endMinutes })
  return actualStartTime <= actualEndTime
}

export const selectedDayAfterStartDay = (value, getValues) => {
  const { startDate, endDate, startTime, endTime } = getValues()
  if (typeof startDate === 'undefined' || typeof endDate === 'undefined') return
  return startDate >= value
}

// Checkout
export const allZeroQuantity = (value, getValues) => {
  const quantities = getValues()
  let totalQuantity = 0

  for (const item in quantities) {
    totalQuantity += quantities[item]
  }

  return totalQuantity > 0
}
