export const apiUrl = process.env.NEXT_PUBLIC_API_URL
export const baseUrl = process.env.NEXT_PUBLIC_URL
export const defaultProfilerCode =
  process.env.NEXT_PUBLIC_DEFAULT_PROFILER_TEST_CODE
export const cdnUrl = process.env.NEXT_PUBLIC_CDN_URL

const settings = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  baseUrl: process.env.NEXT_PUBLIC_URL,
  defaultProfilerCode: process.env.NEXT_PUBLIC_DEFAULT_PROFILER_TEST_CODE,
  cdnUrl: process.env.NEXT_PUBLIC_CDN_URL,
}

export default settings
