import Link from 'next/link'
import Head from 'next/head'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useRouter } from 'next/router'
import { showNotification } from '@/components/notification'
import { TextInput } from '@/components/text-input'
import { Button } from '@/components/button'

import { useStore } from '@/util/store'
import { url, apiUrl } from '@/util/urls'
import { get, post } from '@/util/api'

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const router = useRouter()
  const { unauthorized } = router.query
  const setUser = useStore((state) => state.setUser)

  useEffect(() => {
    const handleUnauthorized = async () => {
      showNotification({
        type: 'warning',
        message:
          'You are not authorized to access this page. If your session expired, please login again.',
      })
      await fetch('/api/logout')
      setUser({})
      router.replace('/login')
    }
    if (unauthorized) {
      handleUnauthorized()
    }
  }, [router, setUser, unauthorized])

  const onSubmit = async (data) => {
    try {
      const returnedUser = await post(url('login'), data)
      setUser({ ...returnedUser, tokenTimeGenerated: Date.now() })
      router.push('/admin')
    } catch (error) {
      if (error.code === 400) {
        showNotification({
          message: 'Incorrect email or password, please try again',
          type: 'error',
        })
      }

      if (error.code === 403) {
        showNotification({
          message: (
            <>
              Your account has been disabled - please contact customer support
              <a className='link' href='mailto:support@sigmaassessments.com'>
                support@sigmaassessments.com
              </a>
            </>
          ),
          type: 'error',
          length: 15000,
        })
      }

      console.error('ERROR: ', error)
    }
  }

  const onError = (errors) => {
    if (errors) {
      showNotification({
        message: 'Please correct the highlighted fields below.',
      })
    }
  }

  return (
    <>
      <Head>
        <title>Login</title>
        <meta name='robots' content='noindex' />
      </Head>
      <div className='content-wrap move-up'>
        <div className='content'>
          <div className='intro tc'>
            <h1>Login</h1>
          </div>
          <div className='is-slim'>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <TextInput
                name='email'
                type='email'
                label='Email Address'
                placeholder='Email Address'
                register={register}
                pattern={{ value: /\S+@\S+\S+/ }}
                required
                errors={errors}
              />
              <TextInput
                name='password'
                type='password'
                label='Password'
                placeholder='Password'
                register={register}
                required
                errors={errors}
              />
              <div className='actions is-column'>
                <Button
                  isSubmitting={isSubmitting}
                  className='btn btn-fullwidth'
                >
                  Sign In
                </Button>
                <Link href='/forgot-password'>
                  <a className='btn btn-fullwidth btn-text'>
                    I forgot my password
                  </a>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

Login.Layouts = ['LayoutBase', 'LayoutSimpleCard']
export default Login
