import { useEffect } from "react"
import { isPast, addDays, getUnixTime, fromUnixTime } from "date-fns"
import { useStore } from "../store"

export const useCookieAcceptance = () => {
    const setAcceptedCookies = useStore((state) => state.setAcceptedCookies)
    const COOKIE_NAME = 'sigma_cookie_consent'

    const checkAcceptance = () => {
        const existingCookieAceptance = localStorage.getItem(COOKIE_NAME)
        if (!existingCookieAceptance) return false
        const cookieData = JSON.parse(existingCookieAceptance)
        const { accepted, expiration} = cookieData
        if( !accepted || !expiration ) return false
        const expirationDate = fromUnixTime(expiration)
        const acceptanceExpired = isPast(expirationDate)
        if(acceptanceExpired) return false
        return true
    }

    const saveAcceptance = (days = 1) => {
        const expirationDate = addDays(new Date(), days)
        const timeStamp = getUnixTime(expirationDate)
        const cookieData = {
            accepted: true,
            expiration: timeStamp
        }
        localStorage.setItem(COOKIE_NAME, JSON.stringify(cookieData))
        setAcceptedCookies(true)
    }

    const removeAcceptance = () => {
        const existingCookieAceptance = localStorage.getItem(COOKIE_NAME)
        if( existingCookieAceptance ) {
            localStorage.removeItem(COOKIE_NAME)
        }
        const cookieData = {
            accepted: false,
            expiration: null
        }
        localStorage.setItem(COOKIE_NAME, JSON.stringify(cookieData))
        setAcceptedCookies(false)
    }

    useEffect(() => {
        const accepted = checkAcceptance()
        accepted && setAcceptedCookies(true)
    }, [])

    return { saveAcceptance, removeAcceptance, checkAcceptance }
}