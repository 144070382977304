import { Modal } from '@/components/modals'
import { useStore } from '@/util/store'

export const RespondentMaintenanceModal = () => {
  const { notificationContent } = useStore((state) => state.modalState)

  return (
    <Modal hideClose={true} variant='medium body-no-close'>
      <div className='modal-content'>
        <h1>Platform Maintenance In Progress</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: notificationContent,
          }}
        ></div>
      </div>
    </Modal>
  )
}
