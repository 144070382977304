import { useState } from 'react'
import { useRouter } from 'next/router'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { del, get, patch } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { useUser } from '@/util/hooks'
import { getOverallTestStatus } from '@/util/functions/getOverallTestStatus'
import { showNotification } from '@/components/notification'

export function useRespondents(
  { searchTerm, respondentId, getAssessment, getReport, testType },
  options = {}
) {
  const { token } = useUser()
  const router = useRouter()
  const { batchId } = router.query
  const queryClient = useQueryClient()
  const [searchOrder, setSearchOrder] = useState({})

  const { data: respondents, refetch: refetchRespondents } = useQuery(
    ['respondents', batchId],
    async () => {
      // TODO: this should be updated use normal pagination practices.
      // NOTE: The backend endpoint will need to be updated for this as well.
      const baseRespondents = await get(apiUrl('respondentsImproved'), {
        token,
        params: { batch: batchId, limit: 1000000 }, // Set above upper limit to retrieve all
      })
      const { results: pdfReportStatuses } = await get(apiUrl('pdfReports'), {
        token,
        params: { respondent__batch_id: batchId, limit: 1000000 },
      })
      const mergedArray =
        pdfReportStatuses?.length > 0
          ? baseRespondents?.map((respondentData) => {
              const matchingReport = pdfReportStatuses.find(
                (reportStatus) =>
                  reportStatus.respondent === respondentData.respondent_Id &&
                  reportStatus.testInternalIdentifier ===
                    respondentData.batchTest_Test_InternalTestIdentifier
              )

              if (matchingReport) {
                return {
                  ...respondentData,
                  is_downloaded: matchingReport.isDownloaded,
                  test_id: matchingReport.test,
                }
              }

              return respondentData
            })
          : baseRespondents

      return mergedArray
    },
    {
      enabled: !!batchId,
      ...options,
    }
  )

  const { data: respondent } = useQuery(
    ['respondents', respondentId],
    () =>
      get(apiUrl('respondents') + '/' + respondentId, {
        token,
      }),
    {
      enabled: !!respondentId,
    }
  )

  const updateRespondentMutation = useMutation(
    (data) =>
      patch(`${apiUrl('respondents')}/${respondentId}`, data, { token }),
    {
      onMutate: async (updatedRespondentData) => {
        await queryClient.cancelQueries(['respondents', respondentId])
        const previousRespondent = queryClient.getQueryData([
          'respondents',
          respondentId,
        ])
        queryClient.setQueryData(['respondents', respondentId], {
          ...previousRespondent,
          ...updatedRespondentData,
        })
        return { previousRespondent }
      },

      onError: (err, updatedRespondentData, context) => {
        queryClient.setQueryData(
          ['respondents', respondentId],
          context.previousRespondent
        )
        showNotification({
          message: "Couldn't save respondent details, please try again.",
          type: 'error',
        })
      },

      onSettled: () => {
        queryClient.invalidateQueries(['respondents', respondentId])
      },
    }
  )

  const deleteRespondentMutation = useMutation(
    (id) => del(`${apiUrl('respondents')}/${id}`, { token }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['respondents', { exact: true }])
      },
    }
  )

  const deleteRespondentAssessmentMutation = useMutation(
    (id) => del(`${apiUrl('assessments')}/${id}`, { token }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['respondentAssessments', respondentId])
      },
    }
  )

  const { data: assessments } = useQuery(
    ['respondentAssessments', respondentId],
    () =>
      get(apiUrl('respondents') + `/${respondentId}/assessments/`, {
        token,
      }),
    {
      enabled: !!getAssessment && !!respondentId,
    }
  )
  const { data: reports, error: reportError } = useQuery(
    ['respondentReports', respondentId, testType],
    () =>
      get(
        `${apiUrl(
          'report'
        )}/${batchId}?respondent_id=${respondentId}&test=${testType}`,

        {
          token,
        }
      ),
    {
      enabled: !!getReport && !!respondentId && !!batchId,
    }
  )

  const { data: searchResults } = useQuery(
    ['respondentSearch', searchTerm, searchOrder],
    () =>
      get(apiUrl('respondentSearch'), {
        token,
        params: {
          query: searchTerm,
          limit: 100,
          o: orderToParamString(searchOrder),
        },
      }),
    {
      enabled: !!searchTerm,
    }
  )

  const setRespondents = (newRespondents) => {
    queryClient.setQueryData(['respondents', batchId], newRespondents)
  }

  const refreshRespondents = () => {
    queryClient.invalidateQueries(['respondents', batchId])
  }

  function formatAssessments(assessments) {
    /**
     * Restructures a list of objects containing information about a respondent
     * and an assessment by grouping together identical respondents into a
     * single object and collecting their assessment info in a nested array.
     *
     * input : [
     *   {
     *      ...respondentInfo1,
     *      ...assessmentInfo1
     *   },
     *   {
     *      ...respondentInfo1,
     *      ...assessmentInfo2
     *   },
     *   ...
     * ]
     *
     * output : [
     *   {
     *     ...respondentInfo1,
     *     assessmentSet: [
     *       {...assessmentInfo1},
     *       {...assessmentInfo2},
     *     ]
     *   }
     * ]
     */
    if (!assessments) return []

    function extractRespondentInfo(assessment) {
      return {
        id: assessment['respondent_Id'],
        firstName: assessment['respondent_FirstName'],
        lastName: assessment['respondent_LastName'],
        flaggedAt: assessment['respondent_FlaggedAt'],
        retestedAt: assessment['respondent_RetestedAt'],
        resumeCodes: {
          resumeCode: assessment['respondent_Bookmark_ResumeCode'],
        },
        reentryCount: assessment['respondent_Bookmark_ReentryCount'],
      }
    }

    function extractAssessmentInfo(assessment) {
      return {
        id: assessment.id,
        status: assessment.status,
        completeDate: assessment.completeDate,
        test: {
          id: assessment['test_id'],
          name: assessment['batchTest_Test_Name'],
          internalTestIdentifier:
            assessment['batchTest_Test_InternalTestIdentifier'],
        },
        reportGeneratedStatus: assessment.reportGeneratedStatus,
        isCreditRedeemed: assessment.isCreditRedeemed,
        isDownloaded: assessment.is_downloaded || false,
        isScored: assessment.isScored,
      }
    }

    // combine assessments by respondent id
    let respondents = {}
    for (let assessment of assessments) {
      const respondentId = assessment['respondent_Id']

      if (respondents.hasOwnProperty(respondentId)) {
        const assessmentInfo = extractAssessmentInfo(assessment)
        respondents[respondentId].assessmentSet.push(assessmentInfo)
      } else {
        const respondent = {
          ...extractRespondentInfo(assessment),
          assessmentSet: [extractAssessmentInfo(assessment)],
        }
        respondents[respondentId] = respondent
      }
    }

    // set overall status on respondent
    respondents = Object.values(respondents).map((respondent) => ({
      ...respondent,
      status: getOverallTestStatus(respondent.assessmentSet),
      testStatuses: respondent.assessmentSet.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.test.internalTestIdentifier]: cur.status,
        }),
        {}
      ),
    }))
    return respondents
  }

  return {
    respondents: formatAssessments(respondents),
    respondent,
    updateRespondentMutation,
    deleteRespondentMutation,
    reports,
    reportError,
    searchResults: searchResults && searchResults.results,
    setSearchOrder,
    assessments,
    deleteRespondentAssessmentMutation,
    refreshRespondents,
    setRespondents,
    refetchRespondents,
  }
}

const orderToParamString = (orderDict) => {
  return Object.entries(orderDict)
    .map(([order, isAscending]) => (isAscending ? order : `-${order}`))
    .join(',')
}
