import { useRouter } from 'next/router'
import { useState } from 'react'

import { Modal } from '.'
import { showNotification } from '@/components/notification'
import { Button } from '@/components/button'
import { WarningBox } from '@/components/basic/warning-box'
import { Icon } from '@/components/icon'

import { useStore } from '@/util/store'
import { post } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { useUser } from '@/util/hooks'
import { appendQueryParamsToRouter } from '@/util/functions'

export function AnonymizeRespondentModal() {
  const router = useRouter()
  const { token } = useUser()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const closeModal = useStore((state) => state.closeModal)
  const { selectedRespIds } = useStore((state) => state.modalState)

  const anonymizeRespondent = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      appendQueryParamsToRouter({
        router,
        paramList: [{ anonymizingRespondents: true }],
      })
      closeModal()

      await requestAnonymization()
      handleSuccess()
    } catch (error) {
      console.error('ERROR: ', error)
      showNotification({
        type: 'error',
        message: 'An error occurred. Please try again later.',
      })
    }

    setIsSubmitting(false)
    closeModal()
  }

  async function requestAnonymization() {
    await post(
      apiUrl('anonymizeRespondent'),
      { respondents: selectedRespIds },
      { token }
    )
  }

  function handleSuccess() {
    showNotification({
      type: 'success',
      message: 'Successfully anonymized respondent!',
    })

    appendQueryParamsToRouter({
      router,
      paramList: [{ shouldRefreshRespondents: true }],
    })
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={anonymizeRespondent}>
        <div className='modal-content'>
          <h1>Are you sure you want to anonymize this respondent?</h1>
          <WarningBox
            className='modal-warning mb-2'
            icon={<Icon name='circle-exclaim' />}
          >
            <strong>This action cannot be reversed.</strong>
          </WarningBox>
          <p>
            Anonymyzing a respondent will remove any personal information
            associated with their test and any generated reports. This operation
            cannot be reversed. The respondent&apos;s test data will not be
            deleted and their report can still be generated and viewed without
            any personal information associated with it.
          </p>
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Anonymize
          </Button>
        </div>
      </form>
    </Modal>
  )
}
