import { useEffect, useRef } from 'react'

export function useHasBeenSeen(callback) {
  const elementRef = useRef(null)

  useEffect(() => {
    let observer = null

    if (!elementRef.current) {
      return
    }

    observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries
        if (entry.isIntersecting) {
          callback()
        }
      },
      {
        root: null,
        threshold: 1.0,
      }
    )

    observer?.observe(elementRef.current)

    return () => {
      if (elementRef?.current) observer?.unobserve(elementRef.current)
    }
  }, [callback, elementRef])

  return elementRef
}
