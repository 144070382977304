import { LoadingIndicator } from '@/components/loading-indicator'
import classnames from '@/util/classnames'

export const Button = ({
  children,
  isSubmitting,
  role,
  onPress,
  type,
  className,
  ...props
}) => {
  return (
    <button
      className={classnames([`btn ${props.variant}`, className])}
      disabled={isSubmitting || props.disabled}
      type={type || 'submit'}
      role={role}
      {...props}
    >
      {isSubmitting ? <LoadingIndicator className='btn-type' /> : children}
    </button>
  )
}
