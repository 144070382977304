import { useState, useEffect, useCallback } from 'react'
import { Button } from '@/components/button'
import { sendGTMEvent } from '@/util/gtm'
import { useStore } from '@/util/store'
import { useCookieAcceptance } from '@/util/hooks/useCookieAcceptance'
import { useWindowFocus } from '@/util/hooks/useWindowFocus'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

export const CookiePopup = ({ days = 1 }) => {
  const [hide, setHide] = useState(true)
  const acceptedCookies = useStore((state) => state.acceptedCookies)
  const {
    saveAcceptance,
    removeAcceptance,
    checkAcceptance,
  } = useCookieAcceptance()
  const { t, i18n } = useTranslation('common')
  const checkCookies = useCallback(() => {
    setHide(acceptedCookies !== null || checkAcceptance())
  }, [acceptedCookies])

  useWindowFocus(checkCookies)

  useEffect(() => {
    setHide(acceptedCookies !== null)
  }, [acceptedCookies])

  const acceptCookies = () => {
    sendGTMEvent('accept-cookies')
    saveAcceptance(days)
  }

  return hide ? null : (
    <div className={`popup-card-container`}>
      <p className='popup-text mb-2'>
        {parse(
          t('cookies.mainNotification', {
            manageLink: `${i18n.language}/manage-cookies`,
          })
        )}
      </p>
      <div className='marketing popup-actions justify-end'>
        <Button className='btn' onClick={removeAcceptance}>
          {t('cookies.declineAll')}
        </Button>
        <Button className='btn btn-outline-primary' onClick={acceptCookies}>
          {t('cookies.acceptAll')}
        </Button>
      </div>
    </div>
  )
}
