import { useRouter } from 'next/router'
import { useState } from 'react'
import parse from 'html-react-parser'
import { Modal } from '.'
import { ActionableLineItem } from '@/components/actionable-line-item'
import { showNotification } from '@/components/notification'
import { LoadingWithMessage } from '@/components/loading-with-message'

import { useStore } from '@/util/store'
import { useTests, useProjects } from '@/util/hooks'

export function AddTestModal() {
  const [testsSubmitting, setTestsSubmitting] = useState([])
  const router = useRouter()
  const { projectId, batchId } = router.query
  const { tests } = useTests()
  const closeModal = useStore((state) => state.closeModal)
  const { projects, addBatchTest } = useStore((state) => state.modalState)

  const projectIndex =
    projects && projects.findIndex((o, i) => o.id === projectId)
  const batchIndex =
    projects &&
    projects[projectIndex].batches.findIndex((o, i) => o.id === batchId)
  const testsInBatch =
    projects && projects[projectIndex].batches[batchIndex].batchTests

  function maxOrder(tests) {
    return Math.max(...tests.map((test) => test.order))
  }

  const onAddTest = async (test_id) => {
    setTestsSubmitting((prevState) => [...prevState, test_id])
    const order = testsInBatch.length ? maxOrder(testsInBatch) + 1 : 0
    const body = { test_id, batch_id: batchId, order }

    const onSuccess = (data) => {
      showNotification({
        type: 'success',
        message: `${data.test.name} successfully added`,
      })
      closeModal()
      setTestsSubmitting((prevState) =>
        prevState.filter((id) => id !== test_id)
      )
    }
    const onError = () => {
      showNotification({
        type: 'error',
        message: 'An error occurred while adding a test.',
      })
      setTestsSubmitting((prevState) =>
        prevState.filter((id) => id !== test_id)
      )
      console.error(err)
    }

    addBatchTest({
      data: { body, batch: projects[projectIndex].batches[batchIndex] },
      onSuccess,
      onError,
    })
  }

  return (
    <Modal variant='styled-modal medium'>
      <div className='modal-content wide'>
        <h1>Add Test</h1>
        <div className='mt-2 modal-scrollable-area'>
          {projects && tests ? (
            tests
              .sort((a, b) =>
                a.internalTestIdentifier < b.internalTestIdentifier ? -1 : 1
              )
              .map((test) => {
                const testAdded = testsInBatch.find(
                  ({ test: bt }) => bt.id === test.id
                )
                return (
                  <ActionableLineItem
                    title={test.name}
                    key={test.id}
                    buttonTitle={
                      testAdded
                        ? 'Added'
                        : testsSubmitting.includes(test.id)
                        ? 'Adding...'
                        : 'Add'
                    }
                    buttonVariant={testAdded ? 'btn-text disabled-navy' : ''}
                    buttonDisabled={
                      testAdded || testsSubmitting.includes(test.id)
                        ? true
                        : false
                    }
                    iconName={testAdded ? 'circle-check' : ''}
                    buttonAction={() => onAddTest(test.id)}
                  >
                    <div className='test-description'>
                      {test.description && <p>{parse(test.description)}</p>}
                      {/* <p>
                      <strong>${test.costPerReportCents}</strong> per report
                    </p> */}
                    </div>
                  </ActionableLineItem>
                )
              })
          ) : (
            <LoadingWithMessage
              containerClassName='mt-1'
              className='middle'
              message='Loading Tests'
            />
          )}
        </div>
      </div>
      <div className='modal-actions'>
        <button onClick={() => closeModal()} className='btn btn-outline'>
          Cancel
        </button>
      </div>
    </Modal>
  )
}
