export function LoadingWithMessage({ containerClassName, className, message }) {
  return (
    <div
      className={`loading-message-container ${
        containerClassName ? containerClassName : ''
      }`}
    >
      <span className={`loading-ellipsis ${className ? className : ''}`}>
        <span>&bull;</span>
        <span>&bull;</span>
        <span>&bull;</span>
      </span>
      <p className={`loading-ellipsis ${className ? className : ''}`}>
        {' '}
        {message}
      </p>
    </div>
  )
}

export default LoadingWithMessage
