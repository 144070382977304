import classnames from "@/util/classnames";
import Link from "next/link";

/**
 * Button
 *
 * `type` can be "primary", "secondary", or "text"
 *
 * When a `href` prop is used we wrap the component in a Next Link
 * (https://nextjs.org/docs/api-reference/next/link)
 *
 * For "primary" and "secondary" types use the boolean props:
 * `outlined` to show the button as outlined
 * `disabled` to either enable or disable the button
 *
 * For "text" use props:
 * `iconSrc` to show an icon next to the text
 */

export default function Button(props) {
  const {
    id,
    type,
    text,
    outlined,
    disabled,
    iconSrc,
    onClick,
    className,
    target,
    // Passed to button element
    ...otherProps
  } = props;

  const btnJsx = () => {
    const disabledClass = disabled ? "disabled" : "";

    switch (type) {
      case "text":
        return (
          <a 
            id={id ? id : ""} 
            className={classnames(['btn-text', disabledClass, className])}
            target={(target ? target : "_self")}
            onClick={onClick}
          >
            <span>{text}</span>
            {iconSrc && <img className="icon" src={iconSrc} />}
          </a>
        );
      case "primary":
        return (
          <a
            id={id ? id : ""}
            className={classnames([
              'btn',
              (outlined ? "btn-outline-primary" : ""), 
              disabledClass, 
              className
            ])}
            target={(target ? target : "_self")}
            onClick={onClick}
          >
            {text}
          </a>
        );
      case "secondary":
        return (
          <a
            id={id ? id : ""}
            className={classnames([
              'btn', 
              'btn-secondary', 
              (outlined ? "btn-outline-secondary" : ""), 
              disabledClass, 
              className
            ])}
            target={(target ? target : "_self")}
            onClick={onClick}
          >
            {text}
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {props.href ? (
        <Link {...otherProps}>{btnJsx()}</Link>
      ) : (
        <div {...otherProps}>{btnJsx()}</div>
      )}
    </>
  );
}
