import Link from 'next/link'
import { useRouter } from 'next/router'

import { Avatar } from '@/components/avatar'
import { Dropdown, DropdownTrigger } from '@/components/dropdown'
import { Icon } from '@/components/icon'
import { AdminSearch } from '@/components/admin-search'

import { useStore } from '@/util/store'
import { useUser } from '@/util/hooks'

export const AdminHeader = ({ children }) => {
  const router = useRouter()
  const isBillingEnabled = useStore((state) => state.isBillingEnabled)
  const user = useUser()
  const { firstName } = useUser()

  const onClickLogout = async (ev) => {
    ev.preventDefault()
    try {
      const loggedOut = await fetch('/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      router.push('/')
      router.reload() // reset the state
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <header className='admin-header'>
      <div className='container'>
        <div className='col'>{children}</div>
        <div className='col'>
          <AdminSearch />
          <div className='admin-dropdown'>
            <DropdownTrigger className='admin-trigger' uid='admin-menu'>
              <Avatar size='80' firstName={firstName} />
            </DropdownTrigger>
            <Dropdown uid='admin-menu'>
              <ul>
                {/* <li className='heading'>Account Settings</li> */}
                <li>
                  <Link href={'/admin/org/profile'}>
                    <a>
                      <Icon name='user' label='My Profile' />
                    </a>
                  </Link>
                </li>
                <hr />
                {user.isAdmin && (
                  <>
                    <li>
                      <Link href={'/admin/org'}>
                        <a>
                          <Icon name='users' label='Organization' />
                        </a>
                      </Link>
                    </li>
                    {isBillingEnabled && (
                      <li>
                        <Link href={'/admin/org/billing'}>
                          <a>
                            <Icon name='credit' label='Billing & Usage' />
                          </a>
                        </Link>
                      </li>
                    )}
                  </>
                )}
                <li>
                  <Link href={'/admin/technical-support'}>
                    <a>
                      <Icon name='circle-question' label='Technical Support' />
                    </a>
                  </Link>
                </li>
                <hr />
                <li>
                  <a onClick={onClickLogout}>
                    <Icon name='logout' label='Logout' />
                  </a>
                </li>
              </ul>
            </Dropdown>
          </div>
        </div>
      </div>
    </header>
  )
}
