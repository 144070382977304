import Link from 'next/link'

export const Logo = ({ size = '100', variant }) => (
  <div
    style={{ fontSize: `${size}%` }}
    className={`logo-wrap ${variant ? variant : ''}`}
  >
    <img src='/images/marketing/sigma-logo.svg' alt='Sigma' />
  </div>
)
