import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Modal } from '.'
import { showNotification } from '@/components/notification'
import { Button } from '@/components/button'
import { HelpText } from '@/components/help-text'

import { respondentStatuses } from '@/util/consts'
import { useStore } from '@/util/store'
import { useProjects } from '@/util/hooks'

export function CompleteBatchModal() {
  const router = useRouter()
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const { projectId, batchId } = router.query
  const { updateBatch } = useProjects()
  const closeModal = useStore((state) => state.closeModal)
  const { respondents } = useStore((state) => state.modalState)

  const remainingRespondents = respondents.filter(
    (respondent) => respondent.status !== respondentStatuses.complete
  ).length

  const onSubmit = () => {
    const onSuccess = (returnedBatch) => {
      showNotification({
        message: `${returnedBatch.name} has been successfully completed.`,
        type: 'success',
      })
      closeModal()
    }
    const onError = () => {
      showNotification({
        type: 'error',
        message: 'An error occurred while completing the batch.',
      })
    }
    updateBatch({
      data: { batchId, body: { status: 'complete' } },
      onSuccess,
      onError,
    })
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='modal-content'>
          <h1 className='tc'>Are you sure you want to close this batch?</h1>

          <HelpText variant={'complete-batch-modal'}>
            <p>
              <strong>
                {remainingRespondents} respondent(s) still in progress.
              </strong>{' '}
              Once you close the batch, any respondent(s) in progress will not
              be included in the current CSV report.
            </p>
          </HelpText>

          <p>
            Closing a batch will allow you to generate a CSV report on completed
            respondent assessments.
          </p>
          <p>
            Once you close this batch, new respondents will no longer be able to
            take assessments within this batch. In progress respondents can
            still complete their assessment, but the batch will have to be
            re-opened and closed to update the CSV report.
          </p>
          <p>
            You can reopen this batch later if you decide to run more
            assessments.
          </p>
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Complete Batch
          </Button>
        </div>
      </form>
    </Modal>
  )
}
