import { Icon } from '@/components/icon'

export const EmptyStatusBox = ({ title, infoText, iconName, marginTop }) => {
  const mt = marginTop ? `mt-${marginTop}` : ''
  return (
    <div className={`text-box__wrapper ${mt}`}>
      <div className='text-box no-data-display'>
        <h4 className='heading'>
          <Icon name={iconName} />
          {title}
        </h4>
        <p className='info'>{infoText}</p>
      </div>
    </div>
  )
}
