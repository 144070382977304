import { useContext } from 'react'
import Link from 'next/link'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { AdminHeader } from '@/components/admin-header'
import { ReportViewOnlyDashboard } from '@/components/report-view-only/report-view-only-dashboard'
import { Icon } from '@/components/icon'
import { Tag } from '@/components/tag'
import { LoadingWithMessage } from '@/components/loading-with-message'
import { EmptyStatusBox } from '@/components/empty-status-box'
import { AdminTranslationContext } from '@/contexts/AdminTranslationContext'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { useStore } from '@/util/store'
import { useProjects, useUser } from '@/util/hooks'
import { getProjectBatches } from '@/util/functions'

const CardAction = ({ icon, onClick, children }) => (
  <div onClick={onClick} className='card-action' role='button'>
    <div className='content'>
      {icon && (
        <div className='card-icon'>
          <Icon name={icon} />
        </div>
      )}
      <div className='text'>{children}</div>
    </div>
  </div>
)

const AdminDashboard = () => {
  const router = useRouter()
  const user = useUser()
  const openModal = useStore((state) => state.openModal)
  const canEditProjects = useStore((state) => state.canEditProjects)
  const isBillingEnabled = useStore((state) => state.isBillingEnabled)
  const { projects } = useProjects()
  const { t } = useContext(AdminTranslationContext)

  const openAddProjectModal = (ev) => {
    ev.preventDefault()
    openModal('AddProjectModal', { t })
  }

  const openAddBatchModal = (ev, projectId) => {
    ev.preventDefault()
    openModal('AddBatchModal', { projectId: projectId })
  }

  if (!user.isAdmin) return <ReportViewOnlyDashboard />

  return (
    <>
      <Head>
        <title>Dashboard</title>
      </Head>
      <AdminHeader>
        <div className='page-head'>
          <div className='title-row'>
            <h2>Dashboard</h2>
          </div>
        </div>
      </AdminHeader>
      <div className='admin-content'>
        {canEditProjects && (
          <div className='dashboard-actions'>
            <CardAction onClick={openAddProjectModal} icon='circle-plus'>
              {t('createProject')}
            </CardAction>
            {isBillingEnabled && (
              <CardAction
                onClick={() => router.push('/admin/org/billing')}
                icon='credit'
              >
                Billing & Usage
              </CardAction>
            )}
            <CardAction
              onClick={() => router.push('/admin/org')}
              icon='organization'
            >
              Organization & Team
            </CardAction>
          </div>
        )}

        {!projects ? (
          <LoadingWithMessage
            containerClassName='mt-5'
            className='middle'
            message='Loading'
          />
        ) : projects.length > 0 ? (
          <div className={`section ${canEditProjects && 'mt-2'}`}>
            <h3>
              {t('allProjects')}{' '}
              <span className='project-count'>
                {t('projectWithCount', { count: projects.length })}
              </span>
            </h3>
            <Accordion
              className='accordion-default accordion-project'
              allowZeroExpanded
            >
              {projects &&
                projects.map((project) => (
                  <AccordionItem key={project.id}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className='heading'>{project.name}</div>
                        <div className='actions'>
                          {canEditProjects && (
                            <div className='action'>
                              <Link href={`/admin/project/${project.id}`}>
                                <a className='btn btn-outline btn-small'>
                                  View
                                </a>
                              </Link>
                              <Link href={`/admin/project/${project.id}/edit`}>
                                <a className='btn btn-outline btn-small'>
                                  Edit
                                </a>
                              </Link>
                            </div>
                          )}
                          <div className='icon' />
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      {project.batches.length ? (
                        <>
                          <ul className='batch-list'>
                            {getProjectBatches(project).map((b) => (
                              <li key={b.id}>
                                <Link
                                  href={`/admin/project/${project.id}/batch/${b.id}`}
                                >
                                  <a>
                                    <span className='title'>{b.name}</span>
                                    {canEditProjects && (
                                      <Tag variant={b.status}>
                                        {b.statusLabel}
                                      </Tag>
                                    )}
                                  </a>
                                </Link>
                              </li>
                            ))}
                          </ul>
                          {canEditProjects && (
                            <a
                              className='new-batch'
                              onClick={(e) => openAddBatchModal(e, project.id)}
                            >
                              <Icon name='circle-plus' /> <span>New Batch</span>
                            </a>
                          )}
                        </>
                      ) : (
                        <>
                          <div className='empty-batches'>No batches yet.</div>
                          {canEditProjects && (
                            <a
                              className='new-batch'
                              onClick={(e) => openAddBatchModal(e, project.id)}
                            >
                              <Icon name='circle-plus' /> <span>New Batch</span>
                            </a>
                          )}
                        </>
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          </div>
        ) : (
          <EmptyStatusBox
            title={t('noProjects.title')}
            infoText={t('noProjects.detail')}
            iconName='file'
            marginTop='2'
          />
        )}
      </div>
    </>
  )
}

AdminDashboard.Layouts = ['LayoutBase', 'LayoutAdmin']
export default AdminDashboard
