import { Modal } from '.'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/button'
import parse from 'html-react-parser'

import { useStore, useSession } from '@/util/store'
import { useKey, useSubmitTestData } from '@/util/hooks'
import { isObjectEmpty } from '@/util/functions'

import { SubmitTestModalError } from './submit-test-modal-error'

export function EndOfSubTestModal() {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [noClose, setNoClose] = useState(false)
  const { t } = useTranslation(['tests', 'common'])
  const [submitTestData, submitError] = useSubmitTestData()

  const closeModal = useStore((s) => s.closeModal)
  const isOnline = useStore((state) => state.isOnline)

  const activeTest = useSession((state) => state.activeTest)
  const activeSubTest = useSession((s) => s.activeSubTest)
  const subTestIdx = useSession((state) => state.subTestIdx)
  const increaseSubTestIdx = useSession((s) => s.increaseSubTestIdx)
  const resetQuestionIdx = useSession((s) => s.resetQuestionIdx)
  const respondentToken = useSession((s) => s.respondentToken)
  const getTimeUsed = useSession((s) => s.getTimeUsed)
  const failedResponses = useSession((state) => state.failedResponses)
  const removeFailedResponse = useSession((state) => state.removeFailedResponse)
  const resetTimer = useSession((state) => state.resetTimer)

  const currentSection =
    !isObjectEmpty(activeSubTest) && activeSubTest.subtest.section.name
  const nextSection =
    !isObjectEmpty(activeTest) &&
    activeTest.subtests[subTestIdx + 1] &&
    activeTest.subtests[subTestIdx + 1].section.name
  const endOfSection = nextSection !== currentSection

  useKey('Escape', closeModal)
  useKey('Enter', closeModal)

  async function nextTest() {
    // Remove focus for keyboard controls
    document.activeElement.blur()
    if (isSubmitting) return

    setNoClose(true)
    setIsSubmitting(true)

    try {
      const failedResponseArray = Object.keys(failedResponses)
      const failedResponsesSnapshot = { ...failedResponses }
      await submitTestData({
        activeSubTest,
        respondentToken,
        timeUsed: getTimeUsed(),
        unpostedResponses: failedResponses,
        onComplete: () => {
          failedResponseArray.map((response) => {
            removeFailedResponse(
              failedResponsesSnapshot[response].response.subtest_question_id,
              failedResponsesSnapshot[response].response.timestamp
            )
          })
          routeToNextSubTest()
        },
      })
    } catch (err) {
      setTimeout(() => setIsSubmitting(false), 2000)
    }
  }

  async function routeToNextSubTest() {
    increaseSubTestIdx()
    resetQuestionIdx()
    resetTimer()

    await router.replace(`/tests/test/section/overview`)
    closeModal()
  }

  return (
    <Modal
      variant={`medium ${noClose ? 'body-no-close' : ''}`}
      hideClose={noClose}
    >
      <div className='modal-header'>
        <h2 className='tc'>{t('modals.endOfSection.heading')}</h2>
      </div>
      <div className='modal-content'>
        {endOfSection ? (
          <p className='tl'>{parse(t('modals.endOfSection.instructions'))}</p>
        ) : (
          <p className='tl'>
            {parse(t('modals.endOfSection.endOfTestInstructions'))}
          </p>
        )}
        <SubmitTestModalError
          submitError={submitError}
          submitButtonLabel={t('common:next').toUpperCase()}
        />
      </div>
      <div className='modal-actions'>
        <button onClick={() => closeModal()} className='btn btn-outline'>
          {t('common:continueEditing')}
        </button>

        <Button
          onClick={nextTest}
          isSubmitting={isSubmitting}
          className='btn'
          disabled={!isOnline || isSubmitting}
        >
          {t('common:next')}
        </Button>
      </div>
    </Modal>
  )
}
