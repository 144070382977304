import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSession, useStore } from '@/util/store'
import { useBookmarking, useRespondentAssessment } from '@/util/hooks'
import { testIdentifiers } from '@/util/consts'
import { useProperties } from '@/util/hooks/useProperties'
import classnames from '@/util/classnames'

export const Countdown = () => {
  const { updateLastActive } = useBookmarking()
  const { isTestIndicatorPage } = useRespondentAssessment()
  const {
    isTimedTestWide,
    testWideTimeLimit,
    isSSPO,
    hasOneMinuteTimerWarning,
  } = useProperties()
  const activeTest = useSession((s) => s.activeTest)
  const subTestIdx = useSession((s) => s.subTestIdx)

  const isOnline = useStore((state) => state.isOnline)
  const isTimerStarted = useSession((s) => s.isTimerStarted)
  const lastActiveTime = useSession((s) => s.lastActiveTime)
  const activeSubTest = useSession((state) => state.activeSubTest)
  const activeSection = useSession((state) => state.activeSection)

  const getTimeUsed = useSession((s) => s.getTimeUsed)
  const stopTimer = useSession((s) => s.stopTimer)
  const subtestTimeLimit = isTimedTestWide
    ? testWideTimeLimit
    : activeSubTest.subtest?.timeLimit || 0
  const openModal = useStore((s) => s.openModal)
  const closeModal = useStore((s) => s.closeModal)

  const [timeRemaining, setTimeRemaining] = useState(
    subtestTimeLimit - getTimeUsed()
  )
  const [timeoutHandled, setTimeoutHandled] = useState(false)
  const [updatingLastActive, setUpdatingLastActive] = useState(false)
  const percentLeft = (timeRemaining / subtestTimeLimit) * 100
  const hasOneMinuteRemaining = timeRemaining <= 60
  const isMAB3 = activeTest.internalTestIdentifier === testIdentifiers.MAB3

  const isEndOfTest = useMemo(() => {
    if (isSSPO) {
      return true
    }

    if (isMAB3) {
      return (
        activeSection.order === activeTest.sections.length - 1 &&
        activeTest.sections[activeSection.order].subtests.length - 1 ===
          subTestIdx
      )
    }

    return activeTest.subtests.length - 1 === subTestIdx
  }, [isSSPO, isMAB3, activeSection.order, activeTest, subTestIdx])

  const { t } = useTranslation(['tests', 'common'])
  // Timer
  useEffect(() => {
    const ticktock = setInterval(() => {
      if (timeRemaining <= 0) {
        clearInterval(ticktock)
        stopTimer()
        setTimeRemaining(0)
      }
      setTimeRemaining(subtestTimeLimit - getTimeUsed())
    }, 250)
    return () => clearInterval(ticktock)
  }, [
    setTimeRemaining,
    getTimeUsed,
    subtestTimeLimit,
    timeRemaining,
    stopTimer,
  ])

  // Show Time Limit Modal once time is up
  useEffect(() => {
    let timeoutID = null
    if (timeRemaining <= 0 && !timeoutHandled) {
      setTimeoutHandled(true) // prevent displaying the modal more than once
      try {
        updateLastActive(activeSubTest.subtest.id)
        setUpdatingLastActive(false)
      } catch (error) {
        console.log(error)
        if (isTimerStarted()) {
          timeoutID = setTimeout(
            () => updateLastActive(activeSubTest.subtest.id),
            5000
          ) // retry in 5 seconds
        }
      }

      if (isMAB3) {
        openModal('TestEndModal', {
          isTimeExpired: true,
          isDismissible: false,
        })
        return
      }

      if (isTimedTestWide) {
        openModal('TestEndModal', {
          isTimeExpired: true,
          isTestEnd: true,
          isDismissible: false,
        })
        return
      }

      isEndOfTest
        ? openModal('EndOfTestTimeLimitModal')
        : openModal('TimeLimitModal')
    }

    return () => {
      if (timeoutID) clearTimeout(timeoutID)
    }
  }, [
    updateLastActive,
    activeSubTest?.subtest?.id,
    activeTest?.subtests?.length,
    activeTest?.sections?.length,
    timeRemaining,
    openModal,
    subTestIdx,
    closeModal,
    timeoutHandled,
  ])

  // update last active time
  useEffect(() => {
    const IDLE_TIME_LIMIT_MILLISECONDS = 15 * 1000
    let timeoutID = null
    const setLastActive = async () => {
      setUpdatingLastActive(true)
      try {
        await updateLastActive(activeSubTest.subtest.id)
        setUpdatingLastActive(false)
      } catch (error) {
        console.log(error)
        if (isTimerStarted()) {
          timeoutID = setTimeout(setLastActive, 5000) // retry in 5 seconds
        }
      }
    }

    if (timeRemaining < 0 || !isOnline) {
      return
    }

    if (
      lastActiveTime &&
      Date.now() - lastActiveTime >= IDLE_TIME_LIMIT_MILLISECONDS &&
      !updatingLastActive
    ) {
      setLastActive()
    }

    return () => {
      if (timeoutID) clearTimeout(timeoutID)
    }
  }, [
    updateLastActive,
    isOnline,
    timeRemaining,
    activeSubTest?.subtest?.id,
    lastActiveTime,
    updatingLastActive,
  ])

  function getRemainingMinutes(timeInSeconds) {
    if (timeInSeconds <= 0) {
      return 0
    }
    return Math.floor(timeInSeconds / 60)
  }

  function getRemainingSeconds(timeInSeconds) {
    if (timeInSeconds <= 0) {
      return 0
    }
    return Math.floor(timeInSeconds % 60)
  }

  return (
    <div
      className={classnames([
        'countdown',
        !isTestIndicatorPage || (subtestTimeLimit == 0 && 'hidden'),
        hasOneMinuteRemaining && hasOneMinuteTimerWarning && 'warning',
      ])}
    >
      <span>
        <span>
          {' '}
          {t('pages.countdown.temaningTime', {
            m: getRemainingMinutes(timeRemaining),
            s: getRemainingSeconds(timeRemaining),
          })}
        </span>
      </span>
      <div
        style={{
          backgroundImage: `linear-gradient(90deg, #438fff ${percentLeft}%, #eef7fe 0)`,
        }}
        className='time-bar'
      />
    </div>
  )
}
