import { useRouter } from 'next/router'
import { useState, useRef } from 'react'
import { useQuery } from 'react-query'
import Link from 'next/link'

import LoadingIndicator from '@/components/loading-indicator'
import { get } from '@/util/api'
import { useDocumentClick } from '@/util/use-document-click'
import { apiUrl } from '@/util/urls'
import { useUser } from '@/util/hooks/useUser'
import { useDebounce } from 'usehooks-ts'
import { RespondentNameResult } from './respondent-result/respondent-name-result'

export const AdminSearch = () => {
  const router = useRouter()

  const inputRef = useRef(null)
  const searchRef = useRef(null)

  const [searchTerm, setSearchTerm] = useState('')
  const query = useDebounce(searchTerm, 300)
  const { token } = useUser()
  const { isLoading, error, data } = useQuery(
    ['searchShortlist', query],
    () => {
      if (query.length <= 2) return { results: [], count: 0 }

      return get(apiUrl('searchShortlist'), {
        token,
        params: [['query', query]],
      })
    }
  )

  useDocumentClick(
    searchRef,
    (isOutsideRef) => isOutsideRef && handleCloseSearch()
  )

  const handleCloseSearch = () => {
    setSearchTerm('')
    document.activeElement.blur()
  }

  const handleSearchChange = (ev) => {
    setSearchTerm(ev.target.value)
  }

  const handleKeyDown = (ev) => {
    if (ev.key === 'Escape') {
      handleCloseSearch()
    }
    if (ev.key === 'Enter') {
      if (searchTerm) {
        seeAllResults()
      }
    }
  }

  const seeAllResults = () => {
    router.push(`/admin/search/${searchTerm}`)
  }

  return (
    <div ref={searchRef} className='search-module'>
      <div className='search-box'>
        <div className='search-input'>
          <input
            autoComplete='off'
            ref={inputRef}
            type='text'
            name='search'
            placeholder='Search Respondents'
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            value={searchTerm}
          />
        </div>

        {query && query.length > 2 && (
          <SearchResultList
            isLoading={isLoading}
            error={error}
            results={data && data.results}
            count={data && data.count}
            seeAllResults={seeAllResults}
          />
        )}
      </div>
    </div>
  )
}

const SearchResultList = ({
  isLoading,
  error,
  results,
  count,
  seeAllResults,
}) => {
  if (isLoading)
    return (
      <div className='search-results'>
        <div className='loading'>
          <LoadingIndicator />
        </div>
      </div>
    )

  if (error) {
    console.log(error)
    return null
  }

  if (results.length == 0)
    return (
      <div className='search-results'>
        <div className='text-item'>
          <span>No Results</span>
        </div>
      </div>
    )

  return (
    <div className='search-results'>
      {results.slice(0, 3).map((result) => (
        <div key={result.id} className='line person'>
          <RespondentNameResult
            fullName={`${result.firstName} ${result.lastName}`}
            linkHref={respondentURL({
              projectId: result.projectId,
              batchId: result.batchId,
              respondentId: result.id,
            })}
            hasReassessment={Boolean(result.retestedAt)}
            isFlagged={Boolean(result.flaggedAt)}
          />
        </div>
      ))}
      <div onClick={seeAllResults} className='line link see-all'>
        See All ({count}) results
        <span className='icon circle-arrow' />
      </div>
    </div>
  )
}

const respondentURL = ({ projectId, batchId, respondentId }) => {
  return `/admin/project/${projectId}/batch/${batchId}/respondent/${respondentId}`
}
