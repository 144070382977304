import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Modal } from '.'
import { useStore } from '@/util/store'
import { useProjects } from '@/util/hooks'
import { showNotification } from '@/components/notification'
import { Button } from '@/components/button'

export function ArchiveProjectModal() {
  const router = useRouter()
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const { projectId } = router.query
  const closeModal = useStore((state) => state.closeModal)
  const { archiveProject } = useProjects()

  const onSubmit = async () => {
    const onSuccess = (data) => {
      showNotification({
        message: `${data.name} has been successfully archived.`,
        type: 'success',
      })
      closeModal()
    }
    const onError = () => {
      showNotification({
        type: 'error',
        message: 'An error occurred while archiving the project.',
      })
    }
    archiveProject({ projectId, onSuccess, onError })
  }

  const onError = (errors) => {
    if (errors) {
      showNotification({
        message: 'Please correct the highlighted fields below.',
        type: 'error',
      })
    }
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className='modal-content'>
          <h1>Are you sure?</h1>
          <p>Are you sure you want to archive this project?</p>
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Archive
          </Button>
        </div>
      </form>
    </Modal>
  )
}
