import { Modal } from '@/components/modals'
import { useStore } from '@/util/store'

export const AdminMaintenanceModal = () => {
  const closeModal = useStore((state) => state.closeModal)
  const { notificationContent } = useStore((state) => state.modalState)

  return (
    <Modal variant='medium body-no-close'>
      <div className='modal-content'>
        <h1>Platform Maintenance In Progress</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: notificationContent,
          }}
        ></div>
      </div>
      <div className='modal-actions'>
        <button type='button' onClick={() => closeModal()} className='btn'>
          Close Notification
        </button>
      </div>
    </Modal>
  )
}
