import { useCallback, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import clone from 'just-clone'
import { useStore, useSession } from '@/util/store'
import { usePostAnswers } from 'util/hooks/usePostAnswers'
import {
  isOverviewPage as overviewPageCheck,
  isQuestionPage as questionPageCheck,
  isRespondentInfoPage as respondentInfoPageCheck,
  isRespondentAssessmentPage as respondentAssessmentPageCheck,
  isAnInstructionsPage as instructionsPageCheck,
} from '@/util/pathnameCheck'
import LoadingWithMessage from './loading-with-message'
import { isObjectEmpty } from '@/util/functions'
import { useTranslation } from 'react-i18next'
import { useTestTimeout } from '@/util/hooks'

export const LayoutActiveTest = ({ children }) => {
  useTestTimeout()
  const activeTest = useSession((state) => state.activeTest)
  const activeSubTest = useSession((state) => state.activeSubTest)
  const setActiveSubTest = useSession((state) => state.setActiveSubTest)
  const activeSection = useSession((state) => state.activeSection)
  const setActiveSection = useSession((state) => state.setActiveSection)
  const setSubtestTimeLimit = useStore((state) => state.setSubtestTimeLimit)
  const setIsPracticeTest = useSession((state) => state.setIsPracticeTest)
  const isOnline = useStore((state) => state.isOnline)
  const subTestIdx = useSession((state) => state.subTestIdx)
  const closeModal = useStore((state) => state.closeModal)
  const { t } = useTranslation(['tests'])
  const [loading, setLoading] = useState(true)

  const activeRespondent = useSession((s) => s.activeRespondent)
  const marketingPages = useStore((state) => state.marketingPages)
  const router = useRouter()
  const pathname = router.pathname
  const isOverviewPage = overviewPageCheck(pathname)
  const isQuestionPage = questionPageCheck(pathname)
  const isRespondentInfoPage = respondentInfoPageCheck(pathname)
  const isRespondentAssessmentPage = respondentAssessmentPageCheck(pathname)
  const isAnInstructionsPage = instructionsPageCheck(pathname)

  usePostAnswers()

  router.beforePopState(({ url }) => {
    if (isOverviewOrQuestionPage(pathname)) {
      const confirmation = window.confirm(t('warnings.backButtonWarning'))
      if (confirmation) {
        exitTest()
      } else {
        stayOnCurrentPage()
      }
    } else if (isMarketingPage(url)) {
      router.push(url)
    } else {
      router.push(url)
    }
  })

  function stayOnCurrentPage() {
    // Mock staying on the current page
    router.replace(router.pathname)
  }

  function exitTest() {
    closeModal()
    router.replace('/tests')
  }

  const pathNeedsActiveTest = useCallback(() => {
    return (
      isOverviewPage ||
      isQuestionPage ||
      isRespondentInfoPage ||
      isAnInstructionsPage
    )
  }, [
    isAnInstructionsPage,
    isOverviewPage,
    isQuestionPage,
    isRespondentInfoPage,
  ])

  const pathNeedsActiveRespondent = useCallback(() => {
    return isRespondentAssessmentPage
  }, [isRespondentAssessmentPage])

  const pathRequiredVariablesNotSet = useCallback(() => {
    return (
      (pathNeedsActiveTest() && isUndefinedOrEmpty(activeTest)) ||
      (pathNeedsActiveRespondent() && isUndefinedOrEmpty(activeRespondent))
    )
  }, [
    activeRespondent,
    activeTest,
    pathNeedsActiveRespondent,
    pathNeedsActiveTest,
  ])

  const pathNeedsRerouting = useCallback(() => {
    if (pathRequiredVariablesNotSet()) {
      return true
    }
    return false
  }, [pathRequiredVariablesNotSet])

  useEffect(() => {
    if (pathNeedsRerouting()) {
      router.replace('/tests')
      return
    }
  }, [pathNeedsRerouting, router])

  function isUndefinedOrEmpty(obj) {
    return typeof obj === 'undefined' || Object.keys(obj).length === 0
  }

  function isOverviewOrQuestionPage(url) {
    return questionPageCheck(url) || overviewPageCheck(url)
  }

  function isMarketingPage(url) {
    return marketingPages.find((path) => path == url)
  }

  useEffect(() => {
    if (!activeTest || isObjectEmpty(activeTest)) return
    const initialize = async () => {
      setLoading(true)
      // setFailedResponses({})
      getAndSetActiveSubTest()
      setLoading(false)
    }

    initialize()
  }, [isOnline, activeTest])

  const getAndSetActiveSubTest = useCallback(() => {
    if (!activeTest?.sections?.length) return
    if (!activeSection?.id) {
      let newActiveSection = {
        id: activeTest?.sections[0]?.id,
        order: 0,
        name: activeTest?.sections[0]?.name,
        description: activeTest?.sections[0]?.description,
      }
      setActiveSection(newActiveSection)
      return
    }
    if (
      activeTest?.sections[activeSection?.order]?.subtests[subTestIdx]?.id ===
      activeSubTest?.subtest?.id
    ) {
      return activeSubTest
    }

    const newActiveSubtest = clone(
      activeTest.sections[activeSection?.order]?.subtests[subTestIdx]
    )

    if (!!newActiveSubtest.isPractice) {
      setIsPracticeTest(true)
    } else {
      setIsPracticeTest(false)
    }

    setActiveSubTest({
      subtest: newActiveSubtest,
    })

    setSubtestTimeLimit(newActiveSubtest.timeLimit)
    return newActiveSubtest
  }, [
    activeTest.subtests,
    subTestIdx,
    setActiveSubTest,
    setSubtestTimeLimit,
    activeSection,
  ])

  return loading ? (
    <LoadingWithMessage className='middle' message='Loading layout' />
  ) : (
    <div className='layout-active-test'>{children}</div>
  )
}
