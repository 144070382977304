import { useCallback, useMemo } from 'react'
import { useSession } from '@/util/store'
import { useRouter } from 'next/router'

import { ProgressBarWithQuestionCount } from '@/components/respondent/progress-bar-with-question-count'
import { ProgressBar } from '@/components/respondent/progress-bar'

import {
  isQuestionPage as questionPageCheck,
  isSubtestOverviewPage as subtestOverviewPageCheck,
  isRespondentAssessmentPage as respondentAssessmentPageCheck,
  isOverviewPage as overviewPageCheck,
} from '@/util/pathnameCheck'
import { testIdentifiers } from '../consts'
import { useProperties } from './useProperties'

export const useRespondentAssessment = () => {
  const router = useRouter()
  const isQuestionPage = questionPageCheck(router.pathname)
  const isSubtestOverviewPage = subtestOverviewPageCheck(router.pathname)
  const isOverviewPageCheck = overviewPageCheck(router.pathname)
  const activeTest = useSession((s) => s.activeTest)
  const activeSubTest = useSession((s) => s.activeSubTest)
  const questionIdx = useSession((s) => s.questionIdx)
  const isPracticeTest = useSession((state) => state.isPracticeTest)
  const progressIndicator = activeTest.progressIndicator
  const {
    totalSubtestQuestions,
    totalTestQuestions,
    currentTestQuestionIdx,
    isTimedTestWide,
  } = useProperties()

  const getCurrentQuestionNumber = () => {
    return questionIdx + 1
  }

  const getSubtestName = () => {
    return activeSubTest.hasOwnProperty('subtest') &&
      activeTest.subtests.length > 1
      ? activeSubTest.subtest.name + ' '
      : ''
  }

  const isTestIndicatorPage = useMemo(() => {
    if (isTimedTestWide) {
      return isQuestionPage || isOverviewPageCheck
    }

    return isQuestionPage
  }, [isQuestionPage, isOverviewPageCheck, isTimedTestWide])

  const progressBarPercentage = useMemo(() => {
    if (isTimedTestWide) {
      return ((currentTestQuestionIdx + 1) / totalTestQuestions) * 100
    }

    return (questionIdx / totalSubtestQuestions) * 100
  }, [
    isTimedTestWide,
    currentTestQuestionIdx,
    totalTestQuestions,
    questionIdx,
    totalSubtestQuestions,
  ])

  const renderProgressBar = () => {
    if (isPracticeTest || progressIndicator === 'NP' || !isTestIndicatorPage) {
      return null
    }
    if (progressIndicator === 'PB') {
      return <ProgressBar percentage={progressBarPercentage} />
    }
  }

  const renderProgressBarWithQuestionCount = () => {
    if (isPracticeTest || progressIndicator === 'NP' || !isQuestionPage) {
      return null
    }

    if (progressIndicator === 'PC') {
      return (
        <ProgressBarWithQuestionCount
          totalQuestions={totalSubtestQuestions}
          testName={activeTest.name}
          question={getCurrentQuestionNumber()}
          subtestName={getSubtestName()}
        />
      )
    }
  }

  return {
    getCurrentQuestionNumber,
    getSubtestName,
    renderProgressBar,
    progressBarPercentage,
    renderProgressBarWithQuestionCount,
    isTestIndicatorPage,
  }
}
