import React, { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'

import { CardHeader } from '@/components/card-header'
import { LoadingWithMessage } from '@/components/loading-with-message'
import { Copyright } from '@/components/basic/copyright'

import { useSession, useStore } from '@/util/store'
import {
  isOverviewPage as overviewPageCheck,
  isQuestionPage as questionPageCheck,
  isRespondentInfoPage as respondentInfoPageCheck,
  isRespondentAssessmentPage as respondentAssessmentPageCheck,
  isAnInstructionsPage as instructionsPageCheck,
} from '@/util/pathnameCheck'
import { useTranslation } from 'react-i18next'
import { useTestTimeout } from '@/util/hooks'

export const LayoutRespondent = ({ children }) => {
  useTestTimeout()
  const activeTest = useSession((s) => s.activeTest)
  const activeRespondent = useSession((s) => s.activeRespondent)
  const marketingPages = useStore((state) => state.marketingPages)
  const { t } = useTranslation(['tests'])
  const router = useRouter()
  const pathname = router.pathname
  const isOverviewPage = overviewPageCheck(pathname)
  const isQuestionPage = questionPageCheck(pathname)
  const isRespondentInfoPage = respondentInfoPageCheck(pathname)
  const isRespondentAssessmentPage = respondentAssessmentPageCheck(pathname)
  const isAnInstructionsPage = instructionsPageCheck(pathname)
  router.beforePopState(({ url }) => {
    if (isOverviewOrQuestionPage(url)) {
      window.alert(t('warnings.backButtonWarning'))
      router.push('/tests')
    } else if (isMarketingPage(url)) {
      router.push(url)
    } else {
      router.push(url)
    }
  })

  const pathNeedsActiveTest = useCallback(() => {
    return (
      isOverviewPage ||
      isQuestionPage ||
      isRespondentInfoPage ||
      isAnInstructionsPage
    )
  }, [
    isAnInstructionsPage,
    isOverviewPage,
    isQuestionPage,
    isRespondentInfoPage,
  ])

  const pathNeedsActiveRespondent = useCallback(() => {
    return isRespondentAssessmentPage
  }, [isRespondentAssessmentPage])

  const pathRequiredVariablesNotSet = useCallback(() => {
    return (
      (pathNeedsActiveTest() && isUndefinedOrEmpty(activeTest)) ||
      (pathNeedsActiveRespondent() && isUndefinedOrEmpty(activeRespondent))
    )
  }, [
    activeRespondent,
    activeTest,
    pathNeedsActiveRespondent,
    pathNeedsActiveTest,
  ])

  const pathNeedsRerouting = useCallback(() => {
    if (pathRequiredVariablesNotSet()) {
      return true
    }
    return false
  }, [pathRequiredVariablesNotSet])

  useEffect(() => {
    if (pathNeedsRerouting()) {
      router.replace('/tests')
      return
    }
  }, [pathNeedsRerouting, router])

  function isUndefinedOrEmpty(obj) {
    return typeof obj === 'undefined' || Object.keys(obj).length === 0
  }

  function isOverviewOrQuestionPage(url) {
    return questionPageCheck(url) || overviewPageCheck(url)
  }

  function isMarketingPage(url) {
    return marketingPages.find((path) => path == url)
  }

  // const [statusBar, setStatusBar] = useState({
  //   visible: false,
  //   isPractice: false,
  //   message: ''
  // })

  if (pathNeedsRerouting())
    return <LoadingWithMessage className='center' message='Loading' />

  return (
    <div className='card-wrapper'>
      <div className='card'>
        <CardHeader />
        {/* <CardHeader statusBar={practiceStatus} /> */}
        <div className='content-wrap'>
          <div className='content'>
            {/* {React.cloneElement(children, { setStatusBar })} */}
            {children}
          </div>
        </div>
        <Copyright />
      </div>
    </div>
  )
}
