import { useQuery } from 'react-query'

import { get } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { useUser } from '@/util/hooks'

export const notificationTypes = {
  ADMIN_BANNER: 'adminBanner',
  ADMIN_MODAL: 'adminModal',
  RESPONDENT_MODAL: 'respondentModal',
}

// Back end notification types
const MAINTENANCE = 'Maintenance'
const FEATURE = 'Feature'

export const useNotifications = (notificationType) => {
  const { token } = useUser()

  const { isLoading, data: notification, refetch } = useQuery(
    'notifications',
    () => get(apiUrl('notifications'), { token }),
    {
      staleTime: 15000,
      select: (notifications) => {
        if (notificationType === notificationTypes.ADMIN_BANNER) {
          const adminBanners = notifications.results.filter(
            (n) =>
              n.notificationType === MAINTENANCE && n.adminBannerContent.trim()
          )

          return adminBanners.length ? adminBanners[0].adminBannerContent : null
        }

        if (notificationType === notificationTypes.ADMIN_MODAL) {
          const adminModals = notifications.results.filter(
            (n) =>
              n.notificationType === MAINTENANCE && n.adminModalContent.trim()
          )

          return adminModals.length ? adminModals[0].adminModalContent : null
        }

        if (notificationType === notificationTypes.RESPONDENT_MODAL) {
          const respondentModals = notifications.results.filter(
            (n) =>
              n.notificationType === MAINTENANCE &&
              n.respondentModalContent.trim()
          )

          return respondentModals.length
            ? respondentModals[0].respondentModalContent
            : null
        }
      },
    }
  )

  return {
    isLoading,
    notification,
    refetch,
  }
}
