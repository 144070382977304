import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Modal } from '.'
import { showNotification } from '@/components/notification'
import { Button } from '@/components/button'

import { useStore } from '@/util/store'
import { useProjects } from '@/util/hooks'

export function PublishBatchModal() {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()
  const router = useRouter()
  const { batchId } = router.query
  const closeModal = useStore((state) => state.closeModal)
  const { updateBatch } = useProjects()

  const onSubmit = async () => {
    const onSuccess = (returnedBatch) => {
      showNotification({
        message: `${returnedBatch.name} has been successfully published.`,
        type: 'success',
      })
      closeModal()
    }
    const onError = () => {
      showNotification({
        type: 'error',
        message: 'An error occurred while publishing the batch.',
      })
    }
    updateBatch({
      data: { batchId, body: { status: 'live' } },
      onSuccess,
      onError,
    })
  }

  const onError = (error) => {
    console.log('err', error)
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className='modal-content'>
          <h1>Are you ready to publish this batch?</h1>
          <p>
            Once you publish this batch you will be able to invite respondents.
            You can do this by generating a Room Code to distribute to a group,
            or by generating Test Codes for individual respondents.
          </p>
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button className='btn' isSubmitting={isSubmitting}>
            Publish Batch
          </Button>
        </div>
      </form>
    </Modal>
  )
}
