import React, { useState, useRef, useEffect } from 'react'
import styles from './tooltip.module.scss'

/**
 * @description A tooltip component that displays a message when it's contents are clicked
 * @param {ReactNode} children - Custom children that will be wrapped in the tooltip
 * @param {string} label - The text that will be clicked to open the tooltip if no children are provided
 * @param {string} tooltipText - The text that will be displayed when the tooltip is open
 *
 */
export const Tooltip = ({ children, label, tooltipText }) => {
  const [isOpen, setIsOpen] = useState(false)
  const tooltipRef = useRef(null)

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={styles.tooltipWrapper} ref={tooltipRef}>
      <div role='button' tabIndex={0} onClick={() => setIsOpen(!isOpen)}>
        {children ? (
          children
        ) : (
          <a>
            <p className={styles.label}>{label}</p>
          </a>
        )}
      </div>
      {isOpen && (
        <div className={styles.tooltip}>
          <p className={styles.tooltipText}>{tooltipText}</p>
          <span
            role='button'
            tabIndex={0}
            className={styles.closeIcon}
            onClick={() => setIsOpen(false)}
          >
            ✖
          </span>
          <div className={styles.arrowDown} />
        </div>
      )}
    </div>
  )
}
