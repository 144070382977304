import { useState } from 'react'
import { useRouter } from 'next/router'

import { Modal } from '.'
import { Button } from '@/components/button'
import { showNotification } from '@/components/notification'
import { useTranslation } from 'react-i18next'
import { useSession, useStore } from '@/util/store'

export function StopAssessmentModal() {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const closeModal = useStore((state) => state.closeModal)
  const reentryCode = useSession((state) => state.reentryCode)
  const { t } = useTranslation(['tests', 'common'])
  const endSession = async () => {
    try {
      setIsSubmitting(true)
      router.replace(`/tests`)
      closeModal()
    } catch (error) {
      if (error.code === 500) {
        showNotification({
          type: 'error',
          message: t('errors.nonSpecificTryAgain'),
        })
      }
      setIsSubmitting(false)
    }
  }

  return (
    <Modal variant='medium'>
      <div className='modal-header'>
        <h2 className='tc'>{t('modals.stopAssement.title')}</h2>
      </div>
      <div className='modal-content'>
        <p className='tl'>{t('modals.stopAssement.stopInstructions')}</p>
        <p className='tl'>{t('modals.stopAssement.resumeInstructions')}</p>
        <p className='tl'>
          <strong>
            {t('common:reentryCode')}: {reentryCode}
          </strong>
        </p>
      </div>
      <div className='modal-actions'>
        <button
          type='button btn-outline'
          onClick={() => {
            closeModal()
          }}
          className='btn btn-outline'
        >
          {t('common:backToAssesment')}
        </button>
        <Button
          onClick={endSession}
          isSubmitting={isSubmitting}
          className='btn'
        >
          {t('common:endSession')}
        </Button>
      </div>
    </Modal>
  )
}
