import { useContext, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { Logo } from '@/components/logo'
import { Icon } from '@/components/icon'
import { LoadingWithMessage } from '@/components/loading-with-message'
import { AdminTranslationContext } from '@/contexts/AdminTranslationContext'
import { Copyright } from '@/components/basic/copyright'
import { useStore } from '@/util/store'
import { getProjectBatches } from '@/util/functions'

export const AdminAside = ({ projects, isViewOnly = false }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const openModal = useStore((state) => state.openModal)
  const router = useRouter()
  const { t } = useContext(AdminTranslationContext)
  const archivePaths = [
    '/admin/archive/projects',
    '/admin/archive/batches',
    '/admin/archive/respondents',
  ]

  const openAddProjectModal = (ev) => {
    ev.preventDefault()
    openModal('AddProjectModal', { t })
  }

  const openAddBatchModal = (ev, projectId) => {
    ev.preventDefault()
    openModal('AddBatchModal', { projectId: projectId })
  }

  const toggleAside = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <aside className={`admin-aside ${isExpanded ? '' : 'is-hidden'}`}>
      <header className='aside-header'>
        <Logo size='75' />
        <div onClick={toggleAside} className='toggle' />
      </header>
      <div className='aside-content'>
        <div className='main-actions'>
          <h6 className='aside-heading'>
            <span className='text'>{t('project', { count: 0 })}</span>
            {!isViewOnly && (
              <button onClick={openAddProjectModal} className='aside-add-new'>
                {t('addProject')}
              </button>
            )}
          </h6>
          {!projects && (
            <LoadingWithMessage
              className='middle'
              containerClassName='mt-1'
              message={t('loadingProject', { count: 0 })}
            />
          )}
          {projects && projects.length === 0 ? (
            <p className='center mt-2 alt-color'>{t('noProjects.title')}</p>
          ) : (
            <Accordion className='admin-accordion-nav' allowZeroExpanded>
              {projects &&
                projects.map((project) => (
                  <AccordionItem key={project.id} uuid={project.id}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{project.name}</AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <ul>
                        <li>
                          <Link href={`/admin/project/${project.id}`}>
                            <a>{t('projectOverview')}</a>
                          </Link>
                        </li>
                      </ul>
                      <h6 className='aside-heading'>
                        <span className='text'>Batches</span>
                        {!isViewOnly && (
                          <a
                            onClick={(e) => openAddBatchModal(e, project.id)}
                            className='aside-add-new'
                          >
                            Add a New Batch
                          </a>
                        )}
                      </h6>
                      <ul>
                        {getProjectBatches(project).map((b) => (
                          <li key={b.id}>
                            <Link
                              href={`/admin/project/${project.id}/batch/${b.id}`}
                            >
                              <a>
                                <span className='text batch-name'>
                                  {b.name}
                                </span>
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          )}
        </div>
        {!isViewOnly && (
          <div className='aux-actions'>
            <ul>
              {/* <li>
              <Link href='#'>
                <a>
                  <Icon name='folder-dark' />
                  Library
                </a>
              </Link>
            </li> */}
              <li>
                <Link href={archivePaths[0]}>
                  <a
                    className={
                      archivePaths.includes(router.pathname) ? 'active' : ''
                    }
                  >
                    <Icon name='clipboard' />
                    Archive
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
      <footer>
        <Copyright />
      </footer>
    </aside>
  )
}
